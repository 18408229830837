
  import { defineComponent, onMounted, ref, computed } from "vue"
  import { useStore } from "vuex"

  import { BasePagination, BaseSpinner } from "@/components/Base"
  import DataTable from "@/components/DataTable/index.vue"
  import LendersFilter from "@/components/Lenders/LendersFilter.vue"
  import { ModalLender, ModalOriginator } from "@/components/Modals"

  import { MatchingGridConfig } from "@/core/config"
  import { convertToShortCurrency, convertToCurrency } from "@/core/helpers/convertToCurrency.ts"
  
  import getTags from "@/core/helpers/getTags"

  import { Actions } from "@/store/enums/StoreEnums"

  import { useDealData, useModals } from "@/composables"

  import { StarIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "LendersMatching",

    components: {
      BasePagination,
      DataTable,
      LendersFilter,
      StarIcon
    },

    setup () {
      const store = useStore()

      const { showModal } = useModals()

      const paging = computed(() => {
        return store.getters.matchingPaging
      })

      const loading = ref(true)

      const { deal, om } = useDealData()

      const matching = computed(() => {
        return store.getters.matching
      })

      const distributions = computed(() => {
        return store.getters.distributions.filter(item => {
          return !item.deleted
        }).map(item => {
          return item.originator.id
        })
      })

      onMounted(async () => {
        await store.dispatch(Actions.GET_MATCHING_FILTER_METADATA, om.value.id)
        await store.dispatch(Actions.GET_PAGED_MATCHING)

        loading.value = false
      })

      const matchingData = computed(() => {
        console.log(matching.value)
        return matching.value.map((item: any) => {
          const lender = item.lender
          
          lender.originators = item.originators
          
          return lender
        })
      })

      const updateOriginatorsList = async (id) => {
        const originatorIds = new Set(distributions.value)

        if (originatorIds.has(id)) originatorIds.delete(id)
        else originatorIds.add(id)

        try {
          if (!distributions.value.length) {
            await store.dispatch(Actions.CREATE_DISTRIBUTIONS, {
              offeringMemorandaId: om.value.id,
              originatorIds: Array.from(originatorIds)
            })
          } else {
            await store.dispatch(Actions.UPDATE_DISTRIBUTIONS, {
              offeringMemorandaId: om.value.id,
              originatorIds: Array.from(originatorIds)
            })
          }
        } catch (error) {
          console.log(error)
        }
      }

      const onPageChange = async (pageNumber) => {
        await store.dispatch(Actions.GET_PAGED_MATCHING, pageNumber)
      }

      return {
        loading,
        paging,
        tableHeader: MatchingGridConfig,
        matching: matchingData,
        convertToShortCurrency,
        getTags,
        om,
        updateOriginatorsList,
        distributions,
        showModal,
        onPageChange
      }
    }
  })

import ApiResponse from "@/core/services/helpers/ApiResponse";
import axios from "axios";

class ContactInfoApi {
  public static async getAdvisorsInfo(): Promise<ApiResponse<any>> {
    return await axios.get<any>(process.env.VUE_APP_GET_CONTACT_INFO)
  }
}

export default ContactInfoApi;

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  "aria-live": "assertive",
  class: "pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6 z-50"
}
const _hoisted_2 = { class: "flex w-full flex-col items-center space-y-4 sm:items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, ({ text, type }, index) => {
        return (_openBlock(), _createBlock(_component_Notification, {
          key: index,
          text: text,
          type: type
        }, null, 8, ["text", "type"]))
      }), 128))
    ])
  ]))
}
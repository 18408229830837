import ApiClient from "@/core/services/ApiClient"
import ApiResponse from "../helpers/ApiResponse"

const basePath = "types"

class TypesApi {
  public static async getTypes(): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}`);
  }
}

export default TypesApi


  import { defineComponent, onMounted } from "vue"
  import { PostHogTracker } from "@/core/services/trackers"
  
  export default defineComponent({
    name: "EmptyAccount",

    setup () {
      onMounted(() => {
        PostHogTracker.capture('Awaiting Approval message',{})
      })
    }
  })

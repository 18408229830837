
  import { defineComponent } from "vue"

  export default defineComponent({
    name: "BaseSpinner",

    props: {
      transparent: {
        type: Boolean,
        default: false
      }
    }
  })

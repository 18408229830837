
  import { defineComponent, toRefs, computed, ref, watch, onMounted } from "vue"
  import { useStore } from "vuex"

  import { BaseMenuToggle, Multiselect } from "@/components/Base"

  import {
    SelectorIcon, FilterIcon, XIcon,
    SortDescendingIcon, ArrowNarrowUpIcon,
    ArrowNarrowDownIcon, SearchIcon
  } from "@heroicons/vue/solid"

  import { Actions } from "@/store/enums/StoreEnums"

  import { StatesMap, PropertyTypesMap, ExecutionTypesMap, FirmTypesMap } from "@/core/constants"

  import { convertToShortCurrency, convertToCurrency, converToNumber } from "@/core/helpers/convertToCurrency"

  import { useDealData, useModals, useTypes } from "@/composables"

  import slider from "vue3-slider"
  import axios from "axios"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: 'LendersFilters',
    
    components: {
      BaseMenuToggle,
      FilterIcon,
      XIcon,
      Multiselect,
      SearchIcon,
      RangeSlider: slider
    },

    props: {
      scope: {
        type: String,
        default () {
          return 'lenders'
        }
      }
    },

    setup (props) {
      const store = useStore()

      const debounce = ref<any>(null)

      const { scope } = toRefs<any>(props)

      const { om } = useDealData()

      const { showNotification } = useModals()

      const loanAmountRange = ref({
        minimumAmount: '',
        maximumAmount: ''
      })

      const propertyLocation = ref<any>({
        longitude: 0,
        latitude: 0
      })

      const filterMetadata: any = computed(() => {
        const getter = `${scope.value}FilterMetadata`

        return Object.fromEntries(
          Object.entries(store.getters[getter]).filter(item => {
            return !['sortFields', 'name', 'isVerified', 'defaultSearchParams', 'defaultSortField', 'cities', 'regions', 'statesProvinces', 'postalCodes'].includes(item[0])
          })
        )
      })

      const statesConfig = computed(() => {
        const getter = `${scope.value}FilterMetadata`

        return store.getters[getter]['statesProvinces']
      })

      const displayedStatesMap = computed(() => {
        const map = {}

        statesConfig.value.forEach(item => {
          map[item] = StatesMap[item] || item
        })

        return map
      })

      const paging = computed(() => {
        const getter = `${scope.value}Paging`

        return store.getters[getter]
      })

      const sortMetadata = computed(() => {
        const getter = `${scope.value}FilterMetadata`

        return store.getters[getter].sortFields
      })

      const filtersData = computed(() => {
        const getter = `${scope.value}Filter`

        return store.getters[getter]
      })

      const onFilterChange = async (filter, event) => {
        const actions = {
          'lenders': Actions.UPDATE_LENDERS_FILTER,
          'originators': Actions.UPDATE_ORIGINATOR_FILTER,
          'matching': Actions.UPDATE_MATCHING_FILTER
        }

        let filterValue: any = event

        if (['query', 'name'].includes(filter) && !event) searchQuery.value = ''
        
        if (Array.isArray(event)) {
          // if (filter == 'lenders') {
          //   filterValue = new Set()
          //   for (let value of event) filterValue.add(lenderOptions.value[value])
          // } else {
            filterValue = new Set(event)
          // }
        } else if (event?.target?.value) {
          filterValue = event.target.value
        }

        await store.dispatch(actions[scope.value], {
          [filter]: filterValue
        })
      }

      const removeFilterValue = async (key, value) => {
        const actions = {
          'lenders': Actions.UPDATE_LENDERS_FILTER,
          'originators': Actions.UPDATE_ORIGINATOR_FILTER,
          'matching': Actions.UPDATE_MATCHING_FILTER
        }

        let filter = new Set(filtersData.value[key])

        filter.delete(value)

        await store.dispatch(actions[scope.value], {
          [key]: filter
        })
      }

      const setSortDirection = async (event) => {
        const actions = {
          'lenders': Actions.UPDATE_LENDERS_SORTING,
          'originators': Actions.UPDATE_ORIGINATOR_SORTING,
          'matching': Actions.UPDATE_MATCHING_SORTING
        }
        
        await store.dispatch(actions[scope.value], {
          sortDirection: event.target.value
        })
      }

      const setSortField = async (event) => {
        const actions = {
          'lenders': Actions.UPDATE_LENDERS_SORTING,
          'originators': Actions.UPDATE_ORIGINATOR_SORTING,
          'matching': Actions.UPDATE_MATCHING_SORTING
        }
        
        await store.dispatch(actions[scope.value], {
          sortField: event.target.value
        })
      }

      const appliedFilters: any = computed(() => {
        const getter = `${scope.value}Filter`
        
        return Object.entries(store.getters[getter]).filter((item: any) => {
          if (item[0] === 'loanAmountRange') return !!item[1]['minimumAmount'] || !!item[1]['maximumAmount']
          if (item[1] instanceof Set) return !!item[1].size
          return !!item[1]
        }).map(item => {
          return {
            key: item[0],
            value: item[1]
          }
        })
      })

      const lendersList = computed(() => {
        return store.getters.lendersList
      })

      const lenderOptions = computed(() => {
        if (!lendersList.value) return {}

        let obj = {}

        lendersList.value.forEach(({ name }) => {
          obj[name] = name
        })
        
        return obj
      })

      // temp stuff
      // 
      onMounted(async () => {
        loanAmountRange.value.minimumAmount = filtersData.value?.loanAmountRange?.minimumAmount || ""
        loanAmountRange.value.maximumAmount = filtersData.value?.loanAmountRange?.maximumAmount || ""

        if (scope.value == 'matching' && !lendersList.value) {
          await store.dispatch('getLendersList')
        }

        if (scope.value == 'matching' && Object.values(om.value).length && om.value?.dataPoints.length) {
          for (let index in om.value.dataPoints) {
            const item = om.value.dataPoints[index]
            if (item.name === 'Property address') {
              try {
                const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${item.value}&key=${process.env.VUE_APP_GOOGLE_KEY}`)
                if (data.status == "OK" && data?.results?.[0]?.geometry?.location) {
                  const { lat, lng } = data?.results?.[0]?.geometry?.location
                  propertyLocation.value.longitude = lng
                  propertyLocation.value.latitude = lat
                }
              } catch (error: any) {
                console.log(error)
                showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
              }
            }
          }
        }
      })

      watch(filtersData.value, (newValue) => {
        if (newValue.value?.loanAmountRange?.minimumAmount !== loanAmountRange.value.minimumAmount) {
          loanAmountRange.value.minimumAmount = convertToCurrency(filtersData.value?.loanAmountRange?.minimumAmount) || ""
        }
        if (newValue.value?.loanAmountRange?.maximumAmount !== loanAmountRange.value.maximumAmount) {
          loanAmountRange.value.maximumAmount = convertToCurrency(filtersData.value?.loanAmountRange?.maximumAmount) || ""
        }
      }, { deep: true })
      
      watch(loanAmountRange.value, () => {
        clearTimeout(debounce.value)

        if (loanAmountRange.value.minimumAmount) {
          loanAmountRange.value.minimumAmount = convertToCurrency(loanAmountRange.value.minimumAmount)
        }

        if (loanAmountRange.value.maximumAmount) {
          loanAmountRange.value.maximumAmount = convertToCurrency(loanAmountRange.value.maximumAmount)
        }

        let { minimumAmount, maximumAmount } = loanAmountRange.value

        if (converToNumber(minimumAmount) !== filtersData.value.loanAmountRange?.minimumAmount ||
          converToNumber(maximumAmount) !== filtersData.value.loanAmountRange?.minimumAmount) {
          
          if (!invalidLoanAmountRange.value) {
            debounce.value = setTimeout(() => {
              onFilterChange('loanAmountRange', {
                minimumAmount: converToNumber(minimumAmount),
                maximumAmount: converToNumber(maximumAmount)
              })
            }, 1000)
          }
        }
      }, { deep: true })

      const filtersMap = {
        'executionTypes': 'Execution',
        'propertyTypes': 'Property',
        'firmTypes': 'Firm',
        'footPrints': 'Footprint',
        'footprints': 'Footprint',
        'statesProvinces': 'State or province',
        'isVerified': 'Verified'
      }

      const { typeNamesMap, propertyTypes, executionTypes, firmTypes, footprints, footprintIds } = useTypes()

      const displayedValuesMap = {
        'isVerified': {
          'Yes': true,
          'No': false
        },
        'executionTypes': ExecutionTypesMap,
        'propertyTypes': PropertyTypesMap,
        'firmTypes': FirmTypesMap,
        'footPrints': StatesMap,
        'footprints': footprintIds,
        'statesProvinces': StatesMap
      }

      const invalidLoanAmountRange = computed(() => {
        if (loanAmountRange.value.minimumAmount && loanAmountRange.value.maximumAmount) {
          return converToNumber(loanAmountRange.value.minimumAmount) > converToNumber(loanAmountRange.value.maximumAmount)
        } else {
          return false
        }
      })

      const searchQuery = ref('')

      const searchActions = {
        'lenders': Actions.UPDATE_LENDERS_FILTER,
        'originators': Actions.UPDATE_ORIGINATOR_FILTER,
        'matching': Actions.UPDATE_MATCHING_FILTER
      }

      const searchParameters = {
        'lenders': 'name',
        'originators': 'name',
        'matching': 'query'
      }

      watch(searchQuery, () => {
        clearTimeout(debounce.value)

        debounce.value = setTimeout(async () => {
          if (!searchQuery.value.length || searchQuery.value.length > 2) {
            await store.dispatch(searchActions[scope.value], {
              [searchParameters[scope.value]]: searchQuery.value
            })
          }
        }, 1000)
      })

      const clearFilters = async () => {
        const actions = {
          'lenders': Actions.CLEAR_LENDERS_FILTER,
          'originators': Actions.CLEAR_ORIGINATOR_FILTER,
          'matching': Actions.CLEAR_MATCHING_FILTER
        }

        searchQuery.value = ''
        loanAmountRange.value.minimumAmount = ''
        loanAmountRange.value.maximumAmount = ''

        await store.dispatch(actions[scope.value])
      }

      const showDistanceFilter = computed(() => {
        return scope.value == 'matching' && om.value?.dataPoints?.filter((item: any) => {
          return item.name == 'Property address'
        }).length > 0
      })

      const distance = ref<number>(0)
      const distanceDebounce = ref<any>(null)

      const handleDistanceChange = (distance) => {
        clearTimeout(distanceDebounce.value)
        
        distanceDebounce.value = setTimeout(async () => { 
          if (distance > 0) {
            const { longitude, latitude } = propertyLocation.value

            onFilterChange('nearby', {
              distance,
              latitude,
              longitude
            })
          } else {
            onFilterChange('nearby', {})
          }
        }, 2000)
      }

      return {
        filterMetadata,
        filtersData,
        appliedFilters,
        filtersMap,
        displayedValuesMap,
        convertToShortCurrency,
        onFilterChange,
        removeFilterValue,
        loanAmountRange,
        invalidLoanAmountRange,
        sortMetadata,
        paging,
        setSortDirection,
        setSortField,
        searchQuery,
        clearFilters,
        om,
        showDistanceFilter,
        distance,
        handleDistanceChange,
        statesConfig,
        displayedStatesMap,
        typeNamesMap,
        types: {
          propertyTypes: propertyTypes.value,
          executionTypes: executionTypes.value,
          firmTypes: firmTypes.value,
          footprints: footprints.value
        },
        lendersList,
        lenderOptions,
        userHasRole,
        RoleType
      }
    }
  })


  import { defineComponent, ref, onMounted, onBeforeUnmount, computed } from "vue"

  import { SvgCounties, SvgCountiesBorder } from "@/core/config"

  import ListItem from "./ListItem.vue"

  import { useTypes } from "@/composables"

  export default defineComponent({
    name: 'CountiesPreview',

    props: ['footprints'],

    components: {
      ListItem
    },

    setup (props) {
      const isVisible = ref<boolean>(false)
      const isMounted = ref(false)
      const containerWidth = ref(989.98)
      const container = ref<any>(null)

      const scale = computed(() => {
        if (!isMounted.value) return 0
        else return containerWidth.value / 989.98
      })

      const updateWidth = () => {
        containerWidth.value = container.value?.offsetWidth
      }

      onMounted(() => {
        containerWidth.value = container.value?.offsetWidth

        isMounted.value = true

        window.addEventListener('resize', updateWidth)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('resize', updateWidth)
      })

      const { footprintIds, typeNamesMap } = useTypes()
      
      return {
        isMounted,
        containerWidth,
        container,
        scale,
        isVisible,
        countiesConfig: SvgCounties,
        countiesBorder: SvgCountiesBorder,
        typeNamesMap
      }
    }
  })

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_2 = { class: "text-gray-500 block" }
const _hoisted_3 = {
  key: 1,
  class: "text-gray-500 block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StarIcon = _resolveComponent("StarIcon")!

  return (_ctx.rating)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.rating), 1),
        _createVNode(_component_StarIcon, { class: "w-4 h-4 text-yellow-500" })
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_3, "N/a"))
}

import { defineComponent, computed, ref } from "vue"

export default defineComponent({
  name: "DealLocation",

  props: {
    map: null
  },

  setup (props) {
    const center = computed(() => {
      return props.map.results[0].geometry.location
    })

    return {
      center
    }
  }
})

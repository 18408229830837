import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeComponent), {
    data: _ctx.data || {},
    name: _ctx.name || '',
    "can-delete": false,
    "can-cancel": !!_ctx.name,
    key: _ctx.isEditor ? 'editor' : 'preview',
    originator: _ctx.originator || null,
    onShowEditor: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEditor = true)),
    onSubmit: _ctx.handleSubmit
  }, null, 40, ["data", "name", "can-cancel", "originator", "onSubmit"]))
}
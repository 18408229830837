
  import { defineComponent } from "vue"
  export default defineComponent({
    name: 'BaseTag',
    
    props: {
      muted: {
        type: Boolean,
        default () {
          return false
        }
      }
    }
  })

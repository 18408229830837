import ApiClient from "@/core/services/ApiClient";
import ApiResponse from "../helpers/ApiResponse";

const basePath = "originator";

class OriginatorsApi {
  public static async getOriginator(
    id: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/${id}`);
  }

  public static async getOriginatorFilterMetadata(): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/filter-metadata`);
  }

  public static async getPagedOriginators(
    count: number,
    page: number,
    sortField: string,
    sortDirection: string,
    filters: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getPaged<any>(
      `${basePath}/paged`,
      true,
      count,
      page,
      sortField,
      sortDirection,
      OriginatorsApi.buildQuerySlug(filters)
    );
  }

  private static buildQuerySlug(filters: any): string[] {
    if (filters == null) return []

    const slugParts: string[] = []

    if (Object.entries(filters).length) {
      Object.entries(filters).forEach((item: any) => {
        if (item[0] === 'loanAmountRange') {
          if (item[1]?.minimumAmount) slugParts.push(`LoanAmountRange.MinimumAmount=${item[1]?.minimumAmount}`)
          if (item[1]?.maximumAmount) slugParts.push(`LoanAmountRange.MaximumAmount=${item[1]?.maximumAmount}`)
        } else if (Array.isArray(item[1])) {
          slugParts.push(`${item[0]}=${item[1].join(`&${item[0]}=`)}`)
        } else if (item[1] instanceof Set) {
          if (Array.from(item[1]).length) slugParts.push(`${item[0]}=${Array.from(item[1]).join(`&${item[0]}=`)}`)
        } else if (item[1]) slugParts.push(`${item[0]}=${item[1]}`)
      })
    }

    return slugParts
  }

  public static async createOriginator(payload): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}`, payload);
  }

  public static async updateOriginator(payload): Promise<ApiResponse<any>> {
    return await ApiClient.put<any>(`${basePath}`, payload);
  }

  public static async setRating (id: string, rating: number): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}/${id}/review`, { rating })
  }

  public static async updateRating (id: string, rating: number): Promise<ApiResponse<any>> {
    return await ApiClient.put<any>(`${basePath}/${id}/review`, { rating })
  }
}

export default OriginatorsApi;

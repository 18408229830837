import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center border-b border-gray-200" }
const _hoisted_2 = {
  class: "-mb-px flex space-x-8 px-6",
  "aria-label": "Tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab = _resolveComponent("tab")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (name, index) => {
        return (_openBlock(), _createBlock(_component_tab, {
          key: index,
          "is-active": _ctx.modelValue == index,
          href: `#product-${index+1}`,
          onClick: _withModifiers(($event: any) => (_ctx.setActive(index)), ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(name), 1)
          ]),
          _: 2
        }, 1032, ["is-active", "href", "onClick"]))
      }), 128)),
      (!_ctx.userHasRole(_ctx.RoleType.BROKER))
        ? (_openBlock(), _createBlock(_component_tab, {
            href: "#new-credit-box",
            key: _ctx.tabs.length,
            "is-active": _ctx.modelValue == _ctx.tabs?.length,
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.setActive(_ctx.tabs.length)), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PlusIcon, { class: "w-4 h-4 mr-1" }),
              _createTextVNode(" New product ")
            ]),
            _: 1
          }, 8, ["is-active"]))
        : _createCommentVNode("", true)
    ])
  ]))
}

  import { defineComponent, ref, toRefs, computed, watch, onMounted } from "vue"

  import { BaseAvatar } from "@/components/Base"

  import { PhoneIcon, MailIcon } from "@heroicons/vue/solid"
  import QuoteForm from "./form.vue"
  import QuoteReject from "./reject.vue"
  import QuoteMoreInfo from "./more-info.vue"

  import store from "@/store"
  import { useRoute, useRouter } from "vue-router"

  import SoftQuote from "@/components/Deal/SoftQuote.vue"
  import { useModals } from "@/composables"

  import { PostHogTracker } from "@/core/services/trackers"

  export default defineComponent({
    name: "DealQuote",

    components: {
      PhoneIcon,
      MailIcon,
      QuoteForm,
      QuoteReject,
      QuoteMoreInfo,
      SoftQuote
    },

    props: ['om'],

    watch: {
      state(newState) {
        if (newState === 'quote') {
          PostHogTracker.capture('OM – Quote Started', {})
        }
        if (newState === 'reject') {
          PostHogTracker.capture('OM – Isn\'t a fit', {})
        }
      }
    },

    setup (props) {
      const route = useRoute()
      const router = useRouter()

      const { om } = toRefs(props)
      
      const state = ref('default') // default, quote, reject, advisor

      const distribution = computed(() => {
        return store.getters.originatorUserOmDistributions.filter((item:any) => {
          return item.id == route.params.distributionId
        })[0]
      })

      onMounted(() => {
        if (distribution.value.softQuote || distribution.value.status == 'NotInterested') state.value = 'advisor'
      })

      const photo = computed(() => {
        const map = {
          'david@janover.ventures': '/media/advisors/david.jpeg',
          'blake@janover.ventures': '/media/advisors/blake.png',
          'joe.nolan@janover.ventures': '/media/advisors/joe.jpeg',
          'tyler@janover.ventures': '/media/advisors/tyler.jpeg',
          'brian@janover.ventures': '/media/advisors/brian.jpeg',
          'bryan@janover.ventures': '/media/advisors/bryan.jpeg',
          'brandon@janover.ventures': '/media/advisors/brandon.jpeg',
          'brad.beattie@janover.ventures': '/media/advisors/brad.jpeg',
          'jalexander@janover.ventures': '/media/advisors/joey.jpeg',
          'lenders@janover.co': '/media/advisors/joey.jpeg'
        }

        return map[om.value?.advisor.email] || '/media/advisors/joey.jpeg'
      })

      const { showModal } = useModals()

      const handleReject = () => {
        state.value = 'advisor'
        showModal('ModalUpdateCreditBox', {})
      }

      return {
        state,
        photo,
        distribution,
        showModal,
        handleReject
      }
    }
  })

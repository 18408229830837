<template>
  <div class="text-base leading-7 text-gray-600">
    <h3 class="text-2xl font-bold tracking-tight text-gray-900 flex items-center gap-1">
      <span>About Janover</span>
    </h3>
    
    <p class="my-3 text-base leading-7 text-gray-600">
      Janover is a commercial real estate financing marketplace that connects 
      borrowers and lenders. We are not a lender, broker, or bank.
    </p>
    
    <a href="https://janover.co/" target="_blank">
      <button
        class="inline-flex border border-sky text-sky items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-secondary text-secondary-foreground hover:bg-secondary/80 h-11 px-8 rounded-md mt-1 hover:bg-sky hover:text-white"
      >
        Learn More &nbsp;&nbsp;→
      </button>
    </a>
  </div>
</template>
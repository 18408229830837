export default [
  {
    key: "name",
    name: "Lender",
    sortable: false,
  },
  {
    key: "rating",
    name: "Rating",
    sortable: false,
  },
  {
    key: "min",
    name: "Min loan",
    sortable: true
  },
  {
    key: "max",
    name: "Max loan",
    sortable: true
  },
  {
    key: "property-type",
    name: "Property types",
    sortable: false
  },
  {
    key: "firm-type",
    name: "Firm types",
    sortable: false
  },
  {
    key: "execution-type",
    name: "Execution types",
    sortable: false
  },
  {
    key: "working-area",
    name: "Footprint",
    sortable: false
  }
]

  import { defineComponent, ref, toRefs, computed, watch } from "vue"
  import { useStore } from "vuex"

  import { XIcon } from "@heroicons/vue/solid"

  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals } from "@/composables"

  import Wysiwig from "@/components/Wysiwig/index.vue"
  import { marked } from "marked"
  import { NodeHtmlMarkdown, NodeHtmlMarkdownOptions } from 'node-html-markdown'
  import * as DOMPurify from 'dompurify'

  export default defineComponent({
    name: 'ModalNewOriginator',

    emits: [
      'closed',
      'created',
      'edited'
    ],

    props: {
      lender: {
        type: Object,
        default () {
          return {}
        }
      },

      originator: {
        type: Object,
        default () {
          return {}
        }
      },

      editor: {
        type: Boolean,
        default () {
          return false
        }
      }
    },

    components: {
      XIcon,
      Wysiwig
    },

    setup(props, { emit }) {
      const store = useStore()

      const { lender, originator, editor } = toRefs<any>(props)

      const { closeModal, showNotification } = useModals()

      const collectedData = ref<any>({
        userAccountId: null,
        lenderId: editor.value ? originator.value.lenderId : lender.value.id,
        name: null,
        phone: null,
        email: null,
        preferredCommunicationType: 'email',
        isVerified: false,
        description: null
      })

      const isEmailValid = computed(() => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(collectedData.value.email);
      })

      const isValid = computed(() => {
        if (!collectedData.value.name) return false
        
        if (!editor.value) {
          if (!collectedData.value.email || !isEmailValid.value) return false
        }

        return true
      })

      const showAlerts = ref(false)
      const loading = ref(false)

      if (editor.value) {
        for (let key in collectedData.value) {
          if (originator.value[key]) collectedData.value[key] = originator.value[key]
        }
      }

      const handleFormSubmit = async () => {
        if (!loading.value) {
          loading.value = true
          showAlerts.value = false
          
          if (isValid.value) {
            const payload: any = {
              creditBoxes: editor.value ? originator.value.creditBoxes : {}
            }

            if (editor.value) {
              payload.id = originator.value.id
              payload.rating = originator.value.rating
            }

            for (let key in collectedData.value) {
              payload[key] = collectedData.value[key]
            }

            try {
              if (!editor.value) {
                const result = await store.dispatch(Actions.CREATE_ORIGINATOR, payload)

                emit('created')
              } else {
                const result = await store.dispatch(Actions.UPDATE_ORIGINATOR, payload)

                emit('edited', result)
              }

              showNotification('Success!')
              closeModal('ModalNewOriginator')
              loading.value = false
            } catch(error: any) {
              console.log(error)
              loading.value = false
              showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
            }
          } else {
            showAlerts.value = true
            loading.value = false
          }
        }
      }

      return {
        closeModal,
        handleFormSubmit,
        collectedData,
        showAlerts,
        loading,
        isEmailValid
      }
    }
  })


  import { defineComponent, computed, toRefs } from "vue"

  import { useStore } from "vuex"

  import { convertToCurrency } from "@/core/helpers/convertToCurrency.ts"

  export default defineComponent({
    name: "DealAnswers",

    props: ['answers'],

    methods: {
      convertToCurrency
    },

    setup (props) {
      const store = useStore()

      const { answers } = toRefs(props)

      const questions = computed(() => {
        return store.getters.questions || []
      })

      const questionsAsObject = computed(() => {
        const obj = {}

        if (questions.value && questions.value.length) {
          store.getters.questions.forEach(item => {
            if (!item.isDuplicate && !['start', 'end'].includes(item.type)) {
              obj[item.name] = item
            }
          })
        }

        return obj
      })

      const displayedData = computed(() => {
        return answers.value.filter(({ isVisible }) => isVisible).map((item: any) => {
          return {
            label: item.name,
            value: item.value,
            appearance: questionsAsObject.value[item.name]?.appearance || 'default'
          }
        })
      })

      const isAuth = computed(() => {
        return !!store.getters.getCurrentUserProfile?.email
      })

      const getLabel = (label) => {
        const map = {
          'Loan amount': 'Requested Loan Amount'
        }

        return map[label] || label
      }

      return {
        displayedData,
        isAuth,
        getLabel
      }
    }
  })

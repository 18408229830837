import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, ({ key, label, path }) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: key,
        to: path,
        class: "px-3 py-2 rounded-md text-sm font-medium text-white mx-2",
        "active-class": "bg-sky-300"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(label), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}

    import { defineComponent, ref, onMounted, computed } from "vue"
    
    import { useModals, useDealData, useShortLists } from "@/composables"
  
    import { XIcon } from "@heroicons/vue/solid"
  
    export default defineComponent({
      name: 'ModalImportList',
  
      emits: ['closed'],
  
      components: {
        XIcon
      },
  
      setup() {
        const { closeModal, showModal } = useModals()
  
        const loading = ref<boolean>(false)
  
        const { bulkUpdateOriginatorsList } = useDealData()
  
        const { templates, getTemplates } = useShortLists()
  
        onMounted(async () => await getTemplates())
  
        return {
          closeModal,
          loading,
          templates,
          bulkUpdateOriginatorsList,
          showModal
        }
      }
    })
  
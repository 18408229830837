
  import { defineComponent, ref, computed, onMounted, watch } from "vue"
  import { useRoute } from "vue-router"
  import { useStore } from "vuex"

  import { Actions } from "@/store/enums/StoreEnums"

  import {
    PencilIcon,
    UsersIcon,
    ShieldCheckIcon,
    CheckIcon,
    XIcon
  } from "@heroicons/vue/solid"
  import { MailIcon } from "@heroicons/vue/outline"

  import { useDealData, useModals } from "@/composables"

  import moment from "moment"

  export default defineComponent({
    name: "DealTracker",

    components: {
      UsersIcon,
      PencilIcon,
      ShieldCheckIcon,
      MailIcon,
      CheckIcon,
      XIcon
    },

    methods: {
      moment (date) {
        return moment(date).fromNow()
      }
    },

    setup () {
      const store = useStore()
      const route = useRoute()
      
      const id = ref(route.params.id)
      const { deal, distributions } = useDealData()
      const { showModal, showNotification } = useModals()

      const sectionNamesMap = {
        'Added': 'Not sent',
        'Previewed': 'Previewed',
        'Removed': 'Removed',
        'SentMessage': 'Sent',
        'ReceivedMessage': 'Received',
        'OpenedMessage': 'Opened',
        'Viewed': 'Viewed',
        'Interested': 'Interested',
        'Quoted': 'Provided quote',
        'NotInterested': 'Not interested',
        'UndeliverableMessage': 'Undeliverable'
      }

      const statusMap = {
        'sent': 'OM Sent',
        'opened': 'OM Opened',
        'quoted': 'Indicated interest 🚀',
        'rejected': 'Not interested'
      }

      const distributionsByStatus = computed(() => {
        const obj: any = {}

        distributions.value.forEach((item: any) => {
          if (!obj[item.status]) obj[item.status] = []
          obj[item.status].push(item)
        })

        return obj
      })

      const showProfile = async (originatorId: string) => {
        const originator = await store.dispatch(Actions.GET_ORIGINATOR, { id: originatorId })
        showModal('ModalOriginator', { originator })
      }

      const bulkSendList = ref<any>(new Set([]))

      watch(distributions, () => {
        bulkSendList.value = new Set()
      }, {
        deep: true
      })

      const updateAll = () => {
        if (bulkSendList.value.size < distributionsByStatus.value['Added'].length) {
          bulkSendList.value = new Set(distributionsByStatus.value['Added'].map((item: any) => item.id))
        } else {
          bulkSendList.value = new Set([])
        }
      }

      const updateBulkSendList = (distributionID: string) => {
        if (bulkSendList.value.has(distributionID)) bulkSendList.value.delete(distributionID)
        else bulkSendList.value.add(distributionID)
      }

      const updateOriginatorsList = async (id: string) => {
        const originatorIds = new Set(
          distributions.value.filter(item => {
            if (item.originator.id == id) {
              bulkSendList.value.delete(item.id)
            }
            return !item.deleted && item.originator.id !== id
          }).map(item => {
            return item.originator.id
          })
        )

        try {
          await store.dispatch(Actions.UPDATE_DISTRIBUTIONS, {
            offeringMemorandaId: deal.value.offeringMemorandumId,
            originatorIds: Array.from(originatorIds)
          })
        } catch (error: any) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
        }
      }

      return {
        deal,
        id,
        statusMap,
        sectionNamesMap,
        distributionsByStatus,
        showModal,
        showProfile,
        distributions,
        bulkSendList,
        updateBulkSendList,
        updateAll,
        updateOriginatorsList
      }
    }
  })


  import { defineComponent, ref, onMounted, computed } from "vue"

  import axios from "axios"

  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  import { useRoute, useRouter } from "vue-router"

  import { getAdvisorImage } from "@/core/helpers/getAdvisorImage"

  import { CheckIcon, MailIcon, PhoneIcon } from "@heroicons/vue/solid"
  import { OmNavigation, OmPictures, OmAdvisorBox, OmCtaBox } from "@/components/OM"
  import { DealAnswers, DealDocuments, DealLocation, DealQuote, Notice } from "@/components/Deal"

  import { marked } from 'marked'
  import * as DOMPurify from 'dompurify'

  export default defineComponent({
    name: "OfferingMemorandumPublic",

    components: {
      MailIcon,
      PhoneIcon,
      CheckIcon,
      OmNavigation,
      OmAdvisorBox,
      OmCtaBox,
      OmPictures,
      DealLocation,
      DealAnswers,
      DealDocuments
    },

    setup () {
      const route = useRoute()
      const router = useRouter()

      const loading = ref<boolean>(true)

      const { omId, shareCode } = route.params

      if (!omId || !shareCode) router.push('/')

      const om = ref<any>(null)

      const map = ref<any>(null)

      const type = computed(() => {
        if (om.value.dataPoints.length) {
          return om.value.dataPoints.filter((item: any) => item.name == 'Property type')[0]?.value || ''
        }

        return 'SBA'
      })

      onMounted(async () => {
        try {
          om.value = await store.dispatch(Actions.PREVIEW_OM, {
            offeringMemorandumId: omId,
            shareCode
          })

          console.log(om.value)

          await store.dispatch(Actions.GET_FIELDS)

          loading.value = false
        } catch (error) {
          console.log(error)
          loading.value = false
        }
      })

      const parseMarkdown = (value) => {
        return DOMPurify.sanitize(marked.parse(value))
      }

      const highlights = computed(() => {
        return om.value.investmentHighlights.split('\n')
      })

      const advisorsInfo = computed(() => store.getters.advisorsInfo)

      const contactInfo: any = computed(() => {
        return Object.values(advisorsInfo.value).filter(
          (item: any) => item.email == om.value?.advisor.email || item.alternativeEmail == om.value?.advisor.email
        )[0] || {}
      })

      return {
        loading,
        om,
        getAdvisorImage,
        type,
        parseMarkdown,
        map,
        highlights,
        contactInfo
      }
    }
  })

import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "md:absolute right-0 bottom-full mb-4 md:mb-10"
}
const _hoisted_3 = { class: "bg-white flex flex-col shadow rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lenders_list = _resolveComponent("lenders-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.userHasRole(_ctx.RoleType.BROKER))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "inline-flex items-center justify-center rounded-md bg-white text-gray-600 px-4 py-2 text-sm font-medium shadow-sm focus:outline-none hover:bg-gray-100",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showModal('ModalNewLender')), ["prevent"]))
          }, " Create Lender ")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_lenders_list)
    ])
  ]))
}

    import { defineComponent, ref, onMounted, computed, watch } from "vue"
    
    import store from "@/store"
  
    import { Actions } from "@/store/enums/StoreEnums"
  
    import { useModals, useDealData, useUserProfile, useShortLists } from "@/composables"
  
    import { XIcon } from "@heroicons/vue/solid"
    import { Select } from "@/components/Base"
  
    export default defineComponent({
      name: 'ModalExportList',
  
      emits: ['closed'],
  
      components: {
        XIcon,
        BaseSelect: Select
      },
  
      setup() {
        const { closeModal, showNotification } = useModals()
  
        const loading = ref<boolean>(false)
  
        const { distributions, om } = useDealData()
        const { userProfile } = useUserProfile()
        const { templates, getTemplates } = useShortLists()
    
        onMounted(async () => await getTemplates())

        const isNewTemplate = ref<boolean>(true)

        const showAlerts = ref<boolean>(false)

        const collectedData = ref<any>({
          name: '',
          description: '',
          id: ''
        })

        const clearForm = () => {
            for (let key in collectedData.value) {
                collectedData.value[key] = ''
            }
        }

        const selectTemplate = (name: string) => {
          const { id, description } = templates.value.filter((item: any) => item.name === name)[0]

          collectedData.value.id = id
          collectedData.value.description = description
          collectedData.value.name = name
        }

        const handleFormSubmit = async () => {
          console.log('handleFormSubmit')

          if (!loading.value) {
            if (!collectedData.value.name) {
              showAlerts.value = true
            } else {
              showAlerts.value = false
              loading.value = true

              try {
                const payload: any = {
                  advisorId: userProfile.value.advisorId,
                  name: collectedData.value.name,
                  description: collectedData.value.description,
                  originators: distributions.value.map((item:any) => {
                    const { id, name, email } = item.originator
                    return { id, name, email }
                  })
                }

                if (!isNewTemplate.value) {
                  payload.id = collectedData.value.id
                  
                  await store.dispatch(Actions.UPDATE_SHORTLIST, payload)
                } else {
                  await store.dispatch(Actions.CREATE_SHORTLIST, payload)
                }

                loading.value = false
                showNotification(`${collectedData.value.name} was exported`)
                closeModal('ModalExportList')
              } catch (error: any) {
                console.log(error)
                showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
                loading.value = false
              }
            }
          }
        }

        const templateOptions = computed(() => {
          return templates.value.map((item: any) => {
            return item.name
          })
        })

        return {
          closeModal,
          loading,
          distributions,
          templates,
          isNewTemplate,
          handleFormSubmit,
          collectedData,
          showAlerts,
          selectTemplate,
          templateOptions,
          clearForm
        }
      }
    })
  
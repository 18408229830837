import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { OriginatorsApi } from "@/core/services/apis"


export default {
  state: {
    originator: {},
    originators: [],
    originatorFilterMetadata: {},
    filterMetadata: {},
    filter: {},
    paging: {
      currentPage: 1,
      pageSize: 25,
      pageCount: 1,
      sortField: "Rating",
      sortDirection: "DESC",
      totalResults: 0
    }
  },

  getters: {
    originator (state) {
      return state.originator
    },
    
    originators (state) {
      return state.originators
    },

    originatorsFilterMetadata (state) {
      return state.filterMetadata
    },

    originatorsPaging (state) {
      return state.paging
    },

    originatorsFilter (state) {
      return state.filter
    }
  },

  mutations: {
    [Mutations.SET_ORIGINATOR_MUTATION] (state, originator: []) {
      state.originator = originator
    },

    [Mutations.SET_ORIGINATORS_MUTATION] (state, originators: []) {
      state.originators = originators
    },

    [Mutations.SET_ORIGINATOR_FILTER_METADATA] (state, filterMetadata) {
      state.filterMetadata = filterMetadata
    },

    [Mutations.SET_ORIGINATOR_PAGING] (state, paging) {
      for (const key in paging) {
        state.paging[key] = paging[key]
      }
    },

    [Mutations.SET_ORIGINATOR_FILTER_VALUE] (state, payload) {
      if (!Object.entries(payload).length) {
        state.filter = {}
      } else {
        for (const key in payload) {
          state.filter[key] = payload[key]
        }
      }
    }
  },

  actions: {
    async [Actions.GET_ORIGINATOR] ({ commit }, payload) {
      const { id } = payload
      const { data } = await OriginatorsApi.getOriginator(id)

      commit(Mutations.SET_ORIGINATOR_MUTATION, data)
      
      return data
    },

    async [Actions.GET_PAGED_ORIGINATORS] ({ commit, getters }, page = 1) {
      const { pageSize, currentPage, sortField, sortDirection } = getters.originatorsPaging
      const filters = getters.originatorsFilter

      const { data } = await OriginatorsApi.getPagedOriginators(
        pageSize,
        page,
        sortField,
        sortDirection,
        filters
      )

      commit(Mutations.SET_ORIGINATORS_MUTATION, data.data)
      commit(Mutations.SET_ORIGINATOR_PAGING, {
        currentPage: data.page,
        pageCount: data.pageCount,
        totalResults: data.totalCount
      })

      return data.data
    },

    async [Actions.GET_ORIGINATORS] ({ commit }, payload) {
      const { count, page, sortField, sortDirection, filters } = payload
      
      const { data } = await OriginatorsApi.getPagedOriginators(
        count,
        page,
        sortField,
        sortDirection,
        filters
      )

      commit(Mutations.SET_ORIGINATORS_MUTATION, data)
      
      return data
    },

    async [Actions.GET_ORIGINATOR_FILTER] ({ commit }, payload) {
      const { data } = await OriginatorsApi.getOriginatorFilterMetadata()

      commit(Mutations.SET_ORIGINATOR_FILTER_METADATA, data)
      
      return data
    },

    async [Actions.CREATE_ORIGINATOR] ({ commit, dispatch }, payload) {
      const { data } = await OriginatorsApi.createOriginator(payload)

      commit(Mutations.SET_ORIGINATOR_MUTATION, data)

      await dispatch(Actions.GET_LENDER, { id: data.lenderId })
      
      return data
    },

    async [Actions.UPDATE_ORIGINATOR] ({ dispatch, getters, commit }, payload) {
      const { data } = await OriginatorsApi.updateOriginator(payload)

      await dispatch(Actions.GET_PAGED_ORIGINATORS, getters.originatorsPaging.currentPage)

      commit(Mutations.SET_ORIGINATOR_MUTATION, data)

      if (getters.matching && getters.matching.length) {
        await dispatch(Actions.GET_MATCHING)
      }
      
      return data
    },

    async [Actions.UPDATE_ORIGINATOR_FILTER] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_ORIGINATOR_FILTER_VALUE, payload)
      await dispatch(Actions.GET_PAGED_ORIGINATORS)
    },

    async [Actions.CLEAR_ORIGINATOR_FILTER] ({ commit, dispatch }) {
      commit(Mutations.SET_ORIGINATOR_FILTER_VALUE, {})
      await dispatch(Actions.GET_PAGED_ORIGINATORS)
    },

    async [Actions.UPDATE_ORIGINATOR_SORTING] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_ORIGINATOR_PAGING, payload)
      await dispatch(Actions.GET_PAGED_ORIGINATORS)
    },

    async [Actions.SET_RATING] ({ dispatch, getters }, { id, rating }) {
      const { data } = await OriginatorsApi.setRating(id, rating)

      await dispatch(Actions.GET_PAGED_ORIGINATORS, getters.originatorsPaging.currentPage)

      if (getters.matching && getters.matching.length) {
        await dispatch(Actions.GET_MATCHING)
      }

      return data
    },

    async [Actions.UPDATE_RATING] ({ dispatch, getters }, { id, rating }) {
      const { data } = await OriginatorsApi.updateRating(id, rating)

      await dispatch(Actions.GET_PAGED_ORIGINATORS, getters.originatorsPaging.currentPage)

      if (getters.matching && getters.matching.length) {
        await dispatch(Actions.GET_MATCHING)
      }

      return data
    }
  }
}

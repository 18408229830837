
  import { SvgMapStates, SvgMapBorders } from "@/core/config"
  import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from "vue"

  import { useTypes } from "@/composables"

  import store from "@/store"
  
  export default defineComponent({
    name: 'StatesControl',

    emits: ['updated'],

    props: ['data'],

    setup (props, { emit }) {
      const states = ref(SvgMapStates)
      const borders = ref(SvgMapBorders)
      const isMounted = ref(false)
      const selected = ref<any[]>([])
      const containerWidth = ref(959)
      const container = ref<any>(null)

      const { footprintIds } = useTypes()

      const reversedFootprintIds = computed(() => {
        const obj = {}

        Object.entries(footprintIds.value).forEach((item: any) => {
          if (item[0].toString().length < 3) obj[item[1]] = item[0]
        })

        return obj
      })

      const scale = computed(() => {
        if (!isMounted.value) return 0
        else return containerWidth.value / 959
      })

      const updateWidth = () => {
        containerWidth.value = container.value.offsetWidth
      }

      onMounted(() => {
        selected.value = props.data

        isMounted.value = true
        containerWidth.value = container.value.offsetWidth

        window.addEventListener('resize', updateWidth)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('resize', updateWidth)
      })

      watch(selected, (newValue, oldValue) => {
        emit('updated', selected.value)
      }, { deep: true })

      const select = (state) => {
        if (selected.value.includes(state)) {
          selected.value = selected.value.filter(option => option !== state)
        } else {
          selected.value.push(state)
        }
      }

      const selectAll = () => {
        if (selected.value.length < states.value.length) {
          selected.value = states.value.map(state => state[0].toUpperCase())
        } else {
          selected.value = []
        }
      }

      return {
        states,
        borders,
        isMounted,
        selected,
        containerWidth,
        scale,
        select,
        selectAll,
        footprintIds,
        reversedFootprintIds,
        container
      }
    }
  })

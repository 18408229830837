
  import { useAuth0 } from '@auth0/auth0-vue';
  export default {
    name: "LogoutButton",
    setup(): { signout: () => void } {
      const { logout } = useAuth0();

      return {
        signout: () => {
          logout({ returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URL });
        }
      };
    },
  };


  import { defineComponent } from 'vue';

  import { NewspaperIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    name: 'OriginatorCtaWithOptions',

    components: {
      NewspaperIcon
    }
  })

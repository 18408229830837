import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { LendersApi } from "@/core/services/apis"

export default {
  state: {
    lender: {},
    lenders: [],
    filterMetadata: {},
    filter: {},
    paging: {
      currentPage: 1,
      pageSize: 25,
      pageCount: 1,
      sortField: "createdDate",
      sortDirection: "DESC",
      totalResults: 0
    }
  },

  getters: {
    lender (state) {
      return state.lender
    },

    lenders (state) {
      return state.lenders
    },

    lendersFilterMetadata (state) {
      return state.filterMetadata
    },

    lendersPaging (state) {
      return state.paging
    },

    lendersFilter (state) {
      return state.filter
    }
  },

  mutations: {
    [Mutations.SET_LENDER_MUTATION] (state, lender) {
      state.lender = lender
    },

    [Mutations.SET_LENDERS_MUTATION] (state, lenders: []) {
      state.lenders = lenders
    },

    [Mutations.SET_LENDER_FILTER_METADATA] (state, filterMetadata) {
      state.filterMetadata = filterMetadata

      for (const key in filterMetadata) {
        if (key !== 'sortFields') state.filter[key] = ''
      }
    },

    [Mutations.SET_LENDER_PAGING] (state, paging) {
      for (const key in paging) {
        state.paging[key] = paging[key]
      }
    },

    [Mutations.SET_LENDER_FILTER_VALUE] (state, payload) {
      if (!Object.entries(payload).length) {
        state.filter = {}
      } else {
        for (const key in payload) {
          state.filter[key] = payload[key]
        }
      }
    }
  },

  actions: {
    async [Actions.GET_LENDERS] ({ commit, getters }) {
      const { pageSize, currentPage, sortField, sortDirection } = getters.lendersPaging
      const filters = getters.lendersFilter

      const { data } = await LendersApi.getPagedLenders(
        pageSize,
        currentPage,
        sortField,
        sortDirection,
        filters
      )

      commit(Mutations.SET_LENDERS_MUTATION, data.data)
      commit(Mutations.SET_LENDER_PAGING, {
        currentPage: data.page,
        pageCount: data.pageCount,
        pageSize: data.pageSize,
        totalResults: data.totalCount
      })

      return data.data
    },

    async [Actions.GET_PAGED_LENDERS] ({ commit, dispatch }, page = 1) {
      commit(Mutations.SET_LENDER_PAGING, {
        currentPage: page
      })

      await dispatch(Actions.GET_LENDERS)
    },

    async [Actions.GET_LENDERS_FILTER] ({ commit }) {
      const { data } = await LendersApi.getLenderFilterMetadata()

      commit(Mutations.SET_LENDER_FILTER_METADATA, data)

      return data
    },

    async [Actions.UPDATE_LENDERS_FILTER] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_LENDER_PAGING, { pageSize: 25 })
      commit(Mutations.SET_LENDER_FILTER_VALUE, payload)
      await dispatch(Actions.GET_PAGED_LENDERS)
    },

    async [Actions.CLEAR_LENDERS_FILTER] ({ commit, dispatch }) {
      commit(Mutations.SET_LENDER_PAGING, { pageSize: 25 })
      commit(Mutations.SET_LENDER_FILTER_VALUE, {})
      await dispatch(Actions.GET_PAGED_LENDERS)
    },

    async [Actions.UPDATE_LENDERS_SORTING] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_LENDER_PAGING, { pageSize: 25 })
      commit(Mutations.SET_LENDER_PAGING, payload)
      await dispatch(Actions.GET_PAGED_LENDERS)
    },

    async [Actions.GET_LENDER] ({ commit }, payload) {
      const { id } = payload
      
      const { data } = await LendersApi.getLender(id)

      commit(Mutations.SET_LENDER_MUTATION, data)
      
      return data
    },

    async [Actions.CREATE_LENDER] ({ commit, dispatch }, payload) {
      const { data } = await LendersApi.createLender(payload)

      commit(Mutations.SET_LENDER_MUTATION, data)

      await dispatch(Actions.GET_LENDERS)

      return data
    },

    async [Actions.UPDATE_LENDER] ({ commit, dispatch, getters }, payload) {
      const { data } = await LendersApi.updateLender(payload)

      commit(Mutations.SET_LENDER_MUTATION, data)

      await dispatch(Actions.GET_LENDERS)

      if (getters.matching && getters.matching.length) {
        await dispatch(Actions.GET_MATCHING)
      }

      return data
    }
  }
}

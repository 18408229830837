export default [
  {
    key: "name",
    name: "Name",
    sortable: false,
  },
  {
    key: "overview",
    name: "Overview",
    sortable: false
  },
  // {
  //   key: "activity",
  //   name: "Last login",
  //   sortable: false
  // },
  {
    key: "lender",
    name: "Lender",
    sortable: false
  },
  {
    key: "location",
    name: "Location",
    sortable: false
  },
  {
    key: "products",
    name: "Products",
    sortable: false
  }
]
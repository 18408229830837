
  import { defineComponent, ref, toRefs, computed } from 'vue'

  import { SelectorIcon } from "@heroicons/vue/solid"

  import { onClickOutside } from '@vueuse/core'

  export default defineComponent({
    name: 'Select',

    props: {
      modelValue: {
        type: String,
        default () {
          return ''
        }
      },

      options: {
        type: Object,
        default () {
          return {}
        }
      },

      key: {
        type: String,
        default () {
          return ''
        }
      },

      clearPlaceholder: {
        type: Boolean,
        default () {
          return true
        }
      },

      placeholder: {
        type: String,
        default () {
          return 'Search...'
        }
      },

      wide: {
        type: Boolean,
        default () {
          return false
        }
      }
    },

    emits: ['update:modelValue'],

    components: {
      SelectorIcon
    },

    setup (props, { emit }) {
      const isOpened = ref<boolean>(false)
      const target = ref<any>(null)
      const search = ref<string>('')
      
      const {
        modelValue,
        options,
        key,
        clearPlaceholder
      } = toRefs<any>(props)

      onClickOutside(target, () => {
        isOpened.value = false
        if (clearPlaceholder.value) search.value = ''
      })

      const updateValue = (value) => {
        emit('update:modelValue', value)
        if (!clearPlaceholder.value) search.value = value
      }

      const filteredOptions = computed(() => {
        let query = JSON.parse(JSON.stringify(search.value)).toLowerCase()
        
        if (Array.isArray(options.value)) {
          return options.value.filter((item: any) => {
            return item.toString().toLowerCase().includes(query)
          })
        }

        return Object.fromEntries(
          Object.entries(options.value).filter((item: any) => {
            return item[1].toString().toLowerCase().includes(query)
          })
        )
      })

      const toggle = () => {
        if (isOpened.value) {
          isOpened.value = false
          if (clearPlaceholder.value) search.value = ''
        } else {
          isOpened.value = true
        }
      }

      return {
        isOpened,
        search,
        target,
        filteredOptions: clearPlaceholder.value ? filteredOptions : options,
        updateValue,
        toggle
      }
    }
  })

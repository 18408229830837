import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.href,
    class: _normalizeClass(["border-transparent text-gray-500 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium flex items-center", _ctx.isActive ? 'border-sky text-sky' : 'hover:border-gray-300 hover:text-gray-700'])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-span-8 md:col-span-3 lg:col-span-2 text-gray-500 text-sm" }
const _hoisted_2 = { class: "col-span-8 md:col-span-5 lg:col-span-4 text-sm text-gray-900" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedData, ({ label, value, appearance }, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["grid grid-cols-8 lg:grid-cols-6 px-4 md:px-6", {
      'border-b pb-4 mb-4': index < _ctx.answers.length - 1,
      'border-t pt-4': index == 0
    }]),
      key: 'answer'+index
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getLabel(label)), 1),
      _createElementVNode("div", _hoisted_2, [
        (!value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.isAuth ? 'Skipped' : 'Protected'), 1)
            ], 64))
          : (appearance == 'currency')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" $ " + _toDisplayString(_ctx.convertToCurrency(value)), 1)
              ], 64))
            : (value.includes('http'))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  href: value,
                  target: "_blank",
                  class: "text-sky"
                }, _toDisplayString(value), 9, _hoisted_3))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createTextVNode(_toDisplayString(value), 1)
                ], 64))
      ])
    ], 2))
  }), 128))
}

  import { defineComponent, ref, toRefs, computed, watch } from "vue"

  import { EyeOffIcon, EyeIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'DealDocumentUpload',

    components: {
      EyeOffIcon,
      EyeIcon
    },

    props: [
      "label",
      "visibilityControl",
      "isVisible",
      "key",
      "name",
      "value"
    ],

    emits: ['updated'],

    setup (props, { emit }) {      
      const isVisibleModel = ref(!!props.isVisible)

      const { label, name, visibilityControl, value } = toRefs(props)

      const isEditor = ref<boolean>(false)
      const display = ref<boolean>(true)

      const file = ref('')

      const handleFile = async (e) => {
        if (e.target?.files.length) {
          file.value = e.target.files[0]

          commitChange()
        }
      }

      const changeVisibility = (isVisible) => {
        isVisibleModel.value = isVisible

        if (file.value) {
          commitChange()
        } else if (value.value) {
          emit('updated', {
            name: name.value,
            $type: 'document',
            isVisible,
            value: value.value
          })
        }
      }

      const commitChange = () => {
        emit('updated', {
          name: name.value,
          $type: 'document',
          file: file.value,
          isVisible: isVisibleModel.value
        })

        if (value.value && file.value) {
          display.value = false
          isEditor.value = false
        }
      }

      const removeFile = () => {
        file.value = ""
      }

      return {
        isVisibleModel,
        changeVisibility,
        handleFile,
        file,
        removeFile,
        isEditor,
        display
      }
    }
  })

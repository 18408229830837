import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-4 py-4 md:py-3 flex flex-col md:flex-row items-center justify-center md:justify-between border-t border-gray-200 sm:px-6" }
const _hoisted_2 = { class: "mb-4 md:mb-0" }
const _hoisted_3 = { class: "text-sm text-gray-700" }
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = { class: "font-medium" }
const _hoisted_7 = {
  class: "relative z-0 inline-flex rounded-md shadow-sm -space-x-px",
  "aria-label": "Pagination"
}
const _hoisted_8 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createTextVNode(" Showing "),
        _createElementVNode("span", _hoisted_4, _toDisplayString((_ctx.pagingData.currentPage - 1) * _ctx.pagingData.pageSize + 1), 1),
        _createTextVNode(" to "),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.pagingData.currentPage * _ctx.pagingData.pageSize), 1),
        _createTextVNode(" of "),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.pagingData.totalResults), 1),
        _createTextVNode(" results ")
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("nav", _hoisted_7, [
        _createElementVNode("a", {
          href: "#prev",
          class: _normalizeClass(["relative inline-flex items-center py-1 px-2 md:p-2 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50", { 'pointer-events-none opacity-50': _ctx.pagingData.currentPage == 1 }]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args)), ["prevent"]))
        }, [
          _createVNode(_component_ChevronLeftIcon, {
            class: "h-4 w-4",
            "aria-hidden": "true"
          })
        ], 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, ({ index, isActive, skip }) => {
          return (_openBlock(), _createElementBlock("a", {
            href: `#page-${index}`,
            "aria-current": "page",
            class: _normalizeClass(["inline-flex items-center px-2.5 sm:px-4 py-1 md:py-2 border text-xs font-medium", {
            'bg-indigo-50 border-sky relative z-10 text-sky': isActive,
            'border-gray-300 text-gray-500 hover:bg-gray-50': !isActive
          }]),
            key: index,
            onClick: _withModifiers(($event: any) => (_ctx.setCurrentPage(index)), ["prevent"])
          }, [
            (!skip)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(index), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString('...'))
                ], 64))
          ], 10, _hoisted_8))
        }), 128)),
        _createElementVNode("a", {
          href: "#next",
          class: _normalizeClass(["relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50", { 'pointer-events-none opacity-50': _ctx.pagingData.currentPage == _ctx.pagingData.pageCount }]),
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)), ["prevent"]))
        }, [
          _createVNode(_component_ChevronRightIcon, {
            class: "h-4 w-4",
            "aria-hidden": "true"
          })
        ], 2)
      ])
    ])
  ]))
}
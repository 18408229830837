import { client } from "@/core/plugins/expose-auth0-plugin"
import { ClaimType } from "@/core/auth/claimType"
import { unref } from "vue"

import { RoleType } from "@/core/auth/permissionEnums"
import { useAuth0 } from "@auth0/auth0-vue"

export const getRoles = () => {
  const user = unref(client)?.user

  if (!user) {
    return []
  }

  const roles = user[ClaimType.ROLES]
  
  return roles
}

export const userHasRole = (role: RoleType) : boolean => {
  const roles = getRoles()
  
  if (roles && roles.indexOf(role) >= 0) {
    return true
  }

  return false
}

export const userHasOneRole = (roles: RoleType[]) : boolean => {
  const userRoles = getRoles()
  
  roles.forEach((roleSearch) => {
    if (userRoles && userRoles.indexOf(roleSearch) >= 0) {
      return true;
    }
  })
  
  return false
}
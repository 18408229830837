declare global {
  interface Window {
    _hsq: any
  }
}

const HubspotTracker = {
    _hsq: window._hsq || [],

	push (event, data) {
		this._hsq.push([event, data])
        if (event == 'setPath') this._hsq.push(['trackPageView'])
	}	
}

export default HubspotTracker

  import { defineComponent, computed } from 'vue';

  import { UserIcon, OfficeBuildingIcon } from '@heroicons/vue/outline'

  import store from '@/store'

  export default defineComponent({
    name: 'OriginatorName',

    components: {
      UserIcon, OfficeBuildingIcon
    },

    setup () {
      const originator = computed(() => store.getters.publicOriginator)
      const lender = computed(() => store.getters.publicLender)

      return {
        originator,
        lender
      }
    }
  })

export default {
  label: "Borrower contact information",
  fields: {
    'name': {
      name: "name",
      label: "Full name",
      type: "text",
      value: '',
      disabled: true
    },
    'email': {
      name: "email",
      label: "Email",
      type: "email",
      value: '',
      disabled: true
    },
    'phone': {
      name: "phone",
      label: "Phone number",
      type: "phone",
      mask: "(999) 999 9999",
      value: '',
      disabled: true
    }
  }
}

  import { defineComponent, computed, ref, onMounted } from "vue"
  import { useRoute, useRouter } from "vue-router"

  import { DealAnswers, DealDocuments, DealLocation, DealQuote, Notice } from "@/components/Deal"
  import { CheckIcon, MailIcon, PhoneIcon } from "@heroicons/vue/solid"
  import { OmNavigation, OmPictures, OmAdvisorBox, OmCtaBox } from "@/components/OM"

  import axios from "axios"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  import { useStore } from "vuex"
  import { Actions, Mutations } from "@/store/enums/StoreEnums"

  import { marked } from 'marked'
  import * as DOMPurify from 'dompurify'

  export default defineComponent({
    name: "OfferingMemorandum",

    components: {
      DealAnswers,
      DealDocuments,
      DealLocation,
      DealQuote,
      CheckIcon,
      MailIcon,
      PhoneIcon,
      Notice,
      OmNavigation,
      OmPictures,
      OmAdvisorBox,
      OmCtaBox
    },

    setup (props) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()

      const { id, omId, distributionId, shareCode, originatorId } = route.params

      if (id && !userHasRole(RoleType.ADVISOR) && !originatorId) {
        router.push('/')
      }

      const map = ref<any>(null)
      
      const om = computed(() => {
        return store.getters.om
      })

      store.commit(Mutations.SET_OM_MUTATION, {})

      const loading = ref<boolean>(true)

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      onMounted(async () => {
        await store.dispatch(Actions.GET_FIELDS)

        try {
          if (originatorId) {
            await store.dispatch(Actions.GET_UNDISTRIBUTED_OM, id)
          } else if (id) {
            await store.dispatch(Actions.GET_OM, id)
          } else {
            await store.dispatch(Actions.VIEW_OM, { omId, distributionId, shareCode })
          }
        } catch (error) {
          router.push('/')
        }

        if (om.value?.dataPoints.length) {
          for (let index in om.value.dataPoints) {
            const item = om.value.dataPoints[index]
            if (item.name === 'Property address') {
              try {
                const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${item.value}&key=${process.env.VUE_APP_GOOGLE_KEY}`)
                if (data.status == "OK") map.value = data
              } catch (error) {
                console.log(error)
              }
            }
          }
        }

        loading.value = false
      })

      const highlights = computed(() => {
        return om.value.investmentHighlights.split('\n')
      })

      const showQuote = !id || originatorId

      const steps = computed(() => {
        const obj: any = {
          '#summary': 'Summary'
        }

        if (map.value) obj['#location'] = 'Location'
        if (om.value.dataPoints.length) obj['#data-points'] = 'Data points'
        if (om.value.documents.length) obj['#documents'] = 'Documents'
        if (showQuote) obj['#soft-quote'] = 'Soft Quote'
        
        return obj
      })

      const type = computed(() => {
        if (om.value.dataPoints.length) {
          return om.value.dataPoints.filter((item: any) => item.name == 'Property type')[0]?.value || ''
        }

        return 'SBA'
      })

      const photo = computed(() => {
        const map = {
          'david@janover.ventures': '/media/advisors/david.jpeg',
          'blake@janover.ventures': '/media/advisors/blake.png',
          'joe.nolan@janover.ventures': '/media/advisors/joe.jpeg',
          'tyler@janover.ventures': '/media/advisors/tyler.jpeg',
          'brian@janover.ventures': '/media/advisors/brian.jpeg',
          'bryan@janover.ventures': '/media/advisors/bryan.jpeg',
          'brandon@janover.ventures': '/media/advisors/brandon.png',
          'brad.beattie@janover.ventures': '/media/advisors/brad.jpeg',
          'brad@janover.ventures': '/media/advisors/brad.jpeg',
          'jalexander@janover.ventures': '/media/advisors/joey.jpeg',
          'lenders@janover.co': '/media/advisors/joey.jpeg'
        }

        return map[om.value?.advisor.email] || '/media/advisors/joey.jpeg'
      })

      const advisorsInfo = computed(() => store.getters.advisorsInfo)

      const contactInfo: any = computed(() => {
        return Object.values(advisorsInfo.value).filter(
          (item: any) => item.email == om.value?.advisor.email || item.alternativeEmail == om.value?.advisor.email
        )[0] || {}
      })

      const goTo = (key) => {
        document.querySelector(key).scrollIntoView({
          behavior: "smooth"
        })
      }

      const parseMarkdown = (value) => {
        return DOMPurify.sanitize(marked.parse(value))
      }

      const distribution = computed(() => {
        if (!showQuote) return false
        return store.getters.originatorUserOmDistributions.filter((item:any) => {
          return item.id == route.params.distributionId
        })[0]
      })

      return {
        om,
        loading,
        steps,
        map,
        highlights,
        showQuote,
        profile,
        type,
        photo,
        goTo,
        parseMarkdown,
        distribution,
        contactInfo
      }
    }
  })

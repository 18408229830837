import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "space-y-3" }
const _hoisted_2 = { class: "flex items-center mb-3 md:mb-0 md:w-1/3 flex-shrink-0" }
const _hoisted_3 = { class: "text-gray-900 text-sm md:pt-2" }
const _hoisted_4 = { class: "border rounded-md divide-y w-full" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "text-gray-600 text-sm" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "flex items-center justify-end"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 1,
  class: "text-gray-500 font-medium text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentTextIcon = _resolveComponent("DocumentTextIcon")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_DownloadIcon = _resolveComponent("DownloadIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, key) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "md:flex items-start justify-between",
        key: key
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(key), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (doc, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "px-3 py-2 flex justify-between items-center",
              key: index
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_DocumentTextIcon, { class: "w-4 h-4 mr-2 text-sky" }),
                _createElementVNode("span", _hoisted_6, [
                  _createTextVNode("Attachment "),
                  (group.length > 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "#" + _toDisplayString(index+1), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              (doc.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (['.ppt', '.pptx', '.doc', '.docx', '.xls', '.xlsx', '.pdf'].some(r => doc.value.includes(r)))
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "text-blue-500 text-sm font-medium mr-2 cursor-pointer",
                          onClick: ($event: any) => (_ctx.showModal('ModalPreviewDocument', { file: { src: doc.value, title: key } }))
                        }, [
                          _createVNode(_component_EyeIcon, { class: "w-4 h-4" })
                        ], 8, _hoisted_9))
                      : _createCommentVNode("", true),
                    _createElementVNode("a", {
                      href: doc.value,
                      class: "text-sky font-medium text-sm",
                      download: ""
                    }, [
                      _createVNode(_component_DownloadIcon, { class: "w-4 h-4" })
                    ], 8, _hoisted_10)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_11, "Protected"))
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}
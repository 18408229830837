
  import { defineComponent, ref } from "vue"

  import { CloudUploadIcon, XIcon } from "@heroicons/vue/outline"

  export default defineComponent({
    name: "PicturesUpload",

    props: ['label', 'key', 'value'],

    emits: ['update-pictures-queue', 'remove-pictures'],

    components: { CloudUploadIcon, XIcon },

    setup (props, { emit }) {
      const files = ref<any[]>([])
      const removedImages = ref<any[]>([])

      const handleImage = async (e) => {
        files.value = []

        if (e.target?.files.length) {
          files.value = Array.from(e.target.files)

          emit('update-pictures-queue', files.value)
        }
      }
      
      const removeImage = (id) => {
        removedImages.value.push(id)
        emit('remove-pictures', removedImages.value)
      }

      const removeFileFromQueue = (name) => {
        files.value = files.value.filter((item: any) => item.name !== name)
        emit('update-pictures-queue', files.value)
      }
      
      return {
        files,
        handleImage,
        removeImage,
        removeFileFromQueue,
        removedImages
      }
    }
  })

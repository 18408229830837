import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { FieldsApi } from "@/core/services/apis"

export default {
  state: {
    field: {},
    fields: [],
    fieldFilterMetadata: {}
  },

  getters: {
    questions (state) {
      return state.fields
    }    
  },

  mutations: {
    [Mutations.SET_FIELD_MUTATION] (state, field: []) {
      state.field = field
    },

    [Mutations.SET_FIELDS_MUTATION](state, fields: []) {
      state.fields = fields
    },

    [Mutations.SET_FIELDS_FILTER_METADATA](state, filterMetadata) {
      state.fieldFilterMetadata = filterMetadata
    }
  },
  
  actions: {
    async [Actions.GET_FIELD] ({ commit }, payload) {
      const { id } = payload
      
      const { data } = await FieldsApi.getField(id)

      commit(Mutations.SET_FIELD_MUTATION, data)

      return data
    },
  
    async [Actions.GET_FIELDS] ({ commit, getters }) {
      console.log('GET_FIELDS')
      if (!getters.questions.length) {
        const { data } = await FieldsApi.getPagedFields(
          500,
          1,
          'created',
          'DESC',
          {}
        )

        commit(Mutations.SET_FIELDS_MUTATION, data.data)
      }
    },

    async [Actions.GET_FIELD_FILTER] ({ commit }, payload) {
      const { data } = await FieldsApi.getFieldFilterMetadata()

      commit(Mutations.SET_FIELDS_FILTER_METADATA, data)
      
      return data
    }
  }
}

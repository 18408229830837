export enum LenderMatchingActions {
    GET_MATCHING = "getMatching",
    GET_PAGED_MATCHING = "getPagedMatching",
    GET_MATCHING_FILTER_METADATA = "getMatchingFilter",
    UPDATE_MATCHING_FILTER = "updateMatchingFilter",
    CLEAR_MATCHING_FILTER = "clearMatchingFilter",
    UPDATE_MATCHING_SORTING = "updateMatchingSorting",
    GET_DISTRIBUTIONS = "getDistributions",
    CREATE_DISTRIBUTIONS = "createDistributions",
    UPDATE_DISTRIBUTIONS = "updateDistributions",
    SET_STAGE = "setStage"
}
export enum LenderMatchingMutations {
    SET_MATCHING_MUTATION = "setMatching",
    SET_MATCHING_FILTER_METADATA = "setMatchingFilterMetadata",
    SET_MATCHING_PAGING = "setMatchingPaging",
    SET_MATCHING_FILTER_VALUE = "setMatchingFilterValue",
    SET_DISTRIBUTIONS = "setDistributions"
}

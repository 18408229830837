
  import { defineComponent, ref, toRefs, computed, watch } from "vue"
  import { useStore } from "vuex"

  import { XIcon } from "@heroicons/vue/solid"

  import getTags from "@/core/helpers/getTags"
  
  import MapSelector from "./components/Map.vue"

  import { Actions } from "@/store/enums/StoreEnums"

  import { convertToCurrency, converToNumber } from "@/core/helpers/convertToCurrency"

  import { useModals, useTypes } from "@/composables"

  import Wysiwig from "@/components/Wysiwig/index.vue"

  export default defineComponent({
    name: 'ModalNewLender',

    emits: [
      'closed',
      'created',
      'edited'
    ],

    components: {
      XIcon,
      MapSelector,
      Wysiwig
    },

    props: {
      editor: {
        type: Boolean,
        default () {
          return false
        }
      },

      lender: {
        type: Object,
        default () {
          return {}
        }
      }
    },

    setup(props, { emit }) {
      const store = useStore()

      const { closeModal, showNotification } = useModals()
      const { executionTypes, propertyTypes, footprints, footprintIds, firmTypes, typeNamesMap } = useTypes()

      const { editor } = toRefs<any>(props)
      const { lender } = toRefs<any>(props)

      const collectedData = ref<any>({
        name: '',
        address: null,
        isVerified: false,
        lenderUri: '',
        logoUri: null,
        description: '',
        firmTypes: new Set(),
        propertyTypes: new Set(),
        executionTypes: new Set(),
        footprints: new Set(),
        minimumLoanAmount: null,
        maximumLoanAmount: null,
        balanceSheet: 0,
        fdic: '',
        ncua: ''
      })

      watch(() => collectedData.value.minimumLoanAmount, (newValue) => {
        collectedData.value.minimumLoanAmount = convertToCurrency(newValue)
      })

      watch(() => collectedData.value.maximumLoanAmount, (newValue) => {
        collectedData.value.maximumLoanAmount = convertToCurrency(newValue)
      })

      const isValid = computed(() => {
        if (!collectedData.value.name || !collectedData.value.lenderUri) return false

        return true
      })

      const showAlerts = ref(false)
      const loading = ref(false)

      if (editor.value) {
        const map = ['executionTypes', 'propertyTypes', 'firmTypes', 'footprints']

        if (lender.value.creditBox?.minimumAmount) collectedData.value.minimumLoanAmount = convertToCurrency(lender.value.creditBox.minimumAmount)
        if (lender.value.creditBox?.maximumAmount) collectedData.value.maximumLoanAmount = convertToCurrency(lender.value.creditBox.maximumAmount)

        for (let key in collectedData.value) {
          if (map.includes(key)) {
            if (key == 'firmTypes') {
              if (lender.value[key].length) {
                collectedData.value[key] = new Set(getTags(lender.value[key], 1000, 'id'))
              }
            } else {
              if (lender.value.creditBox[key].length) {
                collectedData.value[key] = new Set(lender.value.creditBox[key])
              }
            }
          } else if (!['minimumLoanAmount', 'maximumLoanAmount'].includes(key)) {
            collectedData.value[key] = lender.value[key]
          }
        }
      }

      const showFdicField = computed(() => collectedData.value.firmTypes.has('Bank'))
      const showNcuaField = computed(() => collectedData.value.firmTypes.has('CreditUnion'))

      const handleFormSubmit = async () => {
        if (!loading.value) {
          showAlerts.value = true
          loading.value = true

          if (isValid.value) {
            const payload: any = {}

            for (let key in collectedData.value) {
              if (['minimumLoanAmount', 'maximumLoanAmount'].includes(key)) {
                payload[key] = converToNumber(collectedData.value[key])
              } else {
                if (['footprints', 'executionTypes', 'propertyTypes', 'firmTypes'].includes(key)) {
                  payload[key] = Array.from(collectedData.value[key])
                } else {
                  payload[key] = collectedData.value[key]
                }
              }
            }

            if (editor.value) payload.id = lender.value.id

            payload.address = null

            try {
              if (!editor.value) {                
                const result = await store.dispatch(Actions.CREATE_LENDER, payload)

                emit('closed')
              } else {
                const result = await store.dispatch(Actions.UPDATE_LENDER, payload)

                emit('edited', result)
              }
              
              loading.value = false
              showNotification('Success!')
              closeModal('ModalNewLender')
            } catch(error: any) {
              console.log(error)
              loading.value = false
              showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
            }
          } else {
            showAlerts.value = true
          }
        }
      }

      const categories = ref<{[key: string]: any}>({
        amountRange: {
          title: 'Loan amounts',
          subtitle: 'Minimum and maximum loan amounts'
        },
        firmTypes: {
          title: 'Firm types',
          subtitle: 'Type of business'
        },
        executionTypes: {
          title: 'Execution types',
          subtitle: 'Types of commercial property loans'
        },
        propertyTypes: {
          title: 'Property types',
          subtitle: 'Types of property'
        },
        footprints: {
          title: 'Footprint',
          subtitle: 'States to lend in'
        },
      })

      const updateValue = (model, value) => {
        if (collectedData.value[model].has(value)) {
          collectedData.value[model].delete(value)
        } else {
          collectedData.value[model].add(value)
        }
      }

      const selectGroup = (model, types) => {
        Object.keys(types).forEach(item => {
          if (!collectedData.value[model].has(item)) collectedData.value[model].add(item)
        })
      }
      
      const options: { [key: string]: any } = {
        executionTypes: executionTypes.value,
        propertyTypes: propertyTypes.value,
        footprints: footprints.value,
        firmTypes: firmTypes.value
      }

      return {
        closeModal,
        handleFormSubmit,
        collectedData,
        showAlerts,
        loading,
        categories,
        typeNamesMap,
        updateValue,
        selectGroup,
        options,
        showFdicField,
        showNcuaField
      }
    }
  })


  import { defineComponent } from "vue"

  import { CheckIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "DealLenderSteps",

    components: {
      CheckIcon
    },
    
    setup () {
      const stages = [
        {
          label: "DEAl Quality AI check",
          text: "We’ve got all the data we need to proceed",
          completed: true
        },
        {
          label: "validate data with borrower",
          text: "Check that the borrower still need our help and get more context if needed",
          completed: true
        },
        {
          label: "Create an Offering memorandum",
          text: "We’ll ask few more questions and request documents that were tailored to your loan request",
          active: true
        },
        {
          label: "get Lenders feedback",
          text: "Lenders getting back to us with terms and we present you the real deal",
        },
        {
          label: "Your pen is on its way",
          text: "We’ve sent you a fun pen to sign the funding docs"
        }
      ]

      return {
        stages
      }
    }
  })

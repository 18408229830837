import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-10" }
const _hoisted_2 = { class: "max-w-7xl mx-auto flex items-center" }
const _hoisted_3 = { class: "text-lg md:text-3xl font-bold text-white capitalize" }
const _hoisted_4 = { class: "bg-white rounded-lg shadow-sm" }
const _hoisted_5 = { class: "divide-y" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BanIcon = _resolveComponent("BanIcon")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_OriginatorOverview = _resolveComponent("OriginatorOverview")!
  const _component_CreditBoxNavigation = _resolveComponent("CreditBoxNavigation")!
  const _component_CreditBox = _resolveComponent("CreditBox")!
  const _component_originator_stats = _resolveComponent("originator-stats")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.originator.name || 'Loading...'), 1),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal('ModalNewOriginator', { originator: _ctx.originator, editor: true }))),
          class: "bg-white rounded-md py-1.5 px-4 ml-4"
        }, " Edit "),
        (_ctx.originator.deleted)
          ? (_openBlock(), _createBlock(_component_BanIcon, {
              key: 0,
              class: "w-8 h-8 text-red-600 ml-4"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_base_spinner, {
          key: 0,
          class: "h-80 rounded-lg shadow-sm"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_OriginatorOverview, { originator: _ctx.originator }, null, 8, ["originator"]),
            _createVNode(_component_CreditBoxNavigation, {
              modelValue: _ctx.activeTabIndex,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTabIndex) = $event)),
              creditBoxes: _ctx.originator.creditBoxes
            }, null, 8, ["modelValue", "creditBoxes"]),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(), _createBlock(_component_CreditBox, {
                data: _ctx.creditBoxes[_ctx.activeTabIndex]?.value || {},
                name: _ctx.creditBoxes[_ctx.activeTabIndex]?.name || '',
                key: _ctx.activeTabIndex,
                originator: _ctx.originator
              }, null, 8, ["data", "name", "originator"]))
            ])
          ]),
          _createVNode(_component_originator_stats, {
            class: "mt-6",
            originator: _ctx.originator
          }, null, 8, ["originator"])
        ], 64))
  ], 64))
}
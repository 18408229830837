import ApiClient from "@/core/services/ApiClient";
import ApiResponse from "../helpers/ApiResponse";

const basePath = "iq2/deal";

export interface DealFilterProps {
  CreatedDateMin?: Date;
  CreatedDateMax?: Date;
  Hot?: boolean;
  Type?: string;
  Status?: string;
}

class DealsApi {
  public static async getDeal(id: string): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/${id}`);
  }

  public static async getDealFilterMetadata(): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/filter-metadata`);
  }

  public static async getPagedDeals(
    count: number,
    page: number,
    sortField: string,
    sortDirection: string,
    filters: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getFromNode<any>(
      `deal/paged?pageSize=${count}&page=${page}&${DealsApi.buildQuerySlug(filters).join('&')}`,
      true
    );
  }

  // public static async getPagedDeals(
  //   count: number,
  //   page: number,
  //   sortField: string,
  //   sortDirection: string,
  //   filter: DealFilterProps
  // ): Promise<ApiResponse<any>> {
  //   return await ApiClient.getPaged<any>(
  //     `${basePath}/paged`,
  //     true,
  //     count,
  //     page,
  //     sortField,
  //     sortDirection,
  //     DealsApi.buildQuerySlug(filter)
  //   );
  // }

  public static async getPagedRecommendations(
    count: number,
    page: number,
    sortField: string,
    sortDirection: string,
    filter: DealFilterProps,
    originatorId: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getPaged<any>(
      `originators/${originatorId}/dealrecommendations/paged`,
      true,
      count,
      page,
      sortField,
      sortDirection,
      DealsApi.buildQuerySlug(filter)
    );
  }

  public static async updateDeal(
    id: string,
    summary: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.patch<any>(
      `${basePath}`,
      {
        id,
        summary
      }
    );
  }

  public static async createDeal(
    payload: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}`, payload)
  }

  private static buildQuerySlug(filter: DealFilterProps): string[] {
    if (filter == null) return [];

    const slugParts: string[] = [];

    for (const key in filter) {
      if (!filter[key]) continue
      if (filter[key] == 'Multifamily 5+ Units') slugParts.push(`${key}=${encodeURIComponent('Multifamily 5+ Units')}`)
      else if (filter[key] == 'Yes') slugParts.push(`${key}=true`)
      else if (filter[key] == 'No') slugParts.push(`${key}=false`)
      else if (Array.isArray(filter[key])) {
        slugParts.push(`${key}=${Array.from(filter[key]).join(`&${key}=`)}`)
      }
      else slugParts.push(`${key}=${filter[key]}`)
    }

    return slugParts;
  }
}

export default DealsApi;


  import { defineComponent, computed } from "vue"

  import store from "@/store"

  import { useModals } from "@/composables"

  import { BadgeCheckIcon, ExclamationCircleIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'OriginatorAccountTerms',

    components: {
      BadgeCheckIcon,
      ExclamationCircleIcon
    },

    setup () {
      const { showModal } = useModals()

      const termsAccepted = computed(() => {
        return store.getters.termsAccepted
      })

      return {
        termsAccepted,
        showModal
      }
    }
  })

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "overflow-x-auto" }
const _hoisted_2 = { class: "inline-block min-w-full align-middle" }
const _hoisted_3 = { class: "overflow-x-auto ring-1 ring-black ring-opacity-5" }
const _hoisted_4 = {
  key: 0,
  class: "min-w-full divide-y divide-gray-200"
}
const _hoisted_5 = { class: "bg-gray-50" }
const _hoisted_6 = { class: "hover:bg-gray-100" }
const _hoisted_7 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_8 = ["checked"]
const _hoisted_9 = {
  key: 0,
  class: "divide-y divide-gray-200 bg-white"
}
const _hoisted_10 = {
  key: 0,
  class: "h-40 bg-white"
}
const _hoisted_11 = {
  key: 1,
  class: "h-80 p-6 border-t"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.tableData.length)
          ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
              _createElementVNode("thead", _hoisted_5, [
                _createElementVNode("tr", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: i,
                      scope: "col",
                      class: _normalizeClass(["py-3 pl-4 pr-4 text-xs font-medium uppercase tracking-wide text-gray-500", {
                    'text-left': i > 0 && i < _ctx.tableHeader.length - 1,
                    'text-left pr-6': i === _ctx.tableHeader.length - 1,
                    'text-left pl-6': i === 0
                  }])
                    }, [
                      (_ctx.showCheckbox && cell.name == 'Name')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              class: "default:ring-2 mr-2",
                              checked: _ctx.isAllSelected,
                              onChange: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('bulkSelect', $event)), ["prevent"]))
                            }, null, 40, _hoisted_8),
                            _createElementVNode("span", null, _toDisplayString(cell.name), 1)
                          ]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(cell.name), 1)
                          ], 64))
                    ], 2))
                  }), 128))
                ])
              ]),
              (_ctx.tableData.length)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (item, i) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: i,
                        class: "hover:bg-gray-50"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: i,
                            class: _normalizeClass(["whitespace-nowrap py-4 pl-4 pr-4 text-sm max-w-xs overflow-hidden", {
                    'text-left': i > 0 && i < _ctx.tableHeader.length - 1,
                    'text-left pr-6': i === _ctx.tableHeader.length - 1,
                    'text-left pl-6': i === 0
                  }])
                          }, [
                            _renderSlot(_ctx.$slots, `cell-${cell.key}`, { row: item })
                          ], 2))
                        }), 128))
                      ]))
                    }), 128)),
                    (_ctx.tableData.length < 4 && _ctx.minHeight)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (item, index) => {
                            return (_openBlock(), _createElementBlock("td", { key: index }, " "))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.emptyTableText), 1))
      ])
    ])
  ]))
}
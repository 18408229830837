
  import { defineComponent, ref, computed, toRefs } from "vue"

  import { Preview, Editor } from "./components"

  export default defineComponent({
    name: "CreditBox",

    props: ['data', 'name', 'originator'],

    emits: ['updated'],

    components: {
      Preview,
      Editor
    },

    setup (props, { emit }) {
      const isEditor = ref<boolean>(false)
      const loading = ref<boolean>(false)

      const { name } = toRefs(props)

      if (!name.value) isEditor.value = true

      const activeComponent = computed(() => {
        return isEditor.value ? Editor : Preview
      })

      const handleSubmit = () => {
        isEditor.value = false
        emit('updated')
      }
      
      return {
        isEditor,
        activeComponent,
        handleSubmit
      }
    }
  })

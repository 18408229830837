import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-sky border-b border-indigo-300 border-opacity-25 lg:border-none" }
const _hoisted_2 = { class: "mx-auto px-2 px-4 sm:px-6 lg:px-8 xl:px-0 max-w-7xl 2xl:max-w-screen-2xl" }
const _hoisted_3 = { class: "relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25" }
const _hoisted_4 = { class: "flex items-center lg:px-0" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 2,
  class: "ml-3"
}
const _hoisted_8 = {
  key: 0,
  class: "hidden lg:block lg:ml-4"
}
const _hoisted_9 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_layout_header_menu = _resolveComponent("layout-header-menu")!
  const _component_layout_user_menu = _resolveComponent("layout-user-menu")!
  const _component_MenuAlt3Icon = _resolveComponent("MenuAlt3Icon")!
  const _component_layout_mobile_menu = _resolveComponent("layout-mobile-menu")!
  const _component_base_menu_toggle = _resolveComponent("base-menu-toggle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.route.name == 'gelt')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "https://janover.co",
                  class: "flex-shrink-0",
                  target: "_blank",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogoClick && _ctx.handleLogoClick(...args)), ["prevent"]))
                }, [
                  _createElementVNode("img", {
                    class: "block h-8 mw-40",
                    src: _ctx.logoSrc,
                    alt: "Workflow"
                  }, null, 8, _hoisted_5)
                ]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: "/",
                  class: "flex-shrink-0",
                  onClick: _withModifiers(_ctx.handleLogoClick, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "block h-8 mw-40",
                      src: _ctx.logoSrc,
                      alt: "Workflow"
                    }, null, 8, _hoisted_6)
                  ]),
                  _: 1
                }, 8, ["onClick"])),
            (_ctx.isAuthenticated)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_layout_header_menu, { class: "hidden lg:flex" })
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_layout_user_menu)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createBlock(_component_base_menu_toggle, {
                key: 1,
                class: "block lg:hidden relative",
                "menu-styles": 'right-0 top-full w-40 z-50'
              }, {
                toggle: _withCtx(() => [
                  _createVNode(_component_MenuAlt3Icon, { class: "w-6 h-6 text-white cursor-pointer" })
                ]),
                items: _withCtx(() => [
                  _createVNode(_component_layout_mobile_menu)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isAuthenticated && _ctx.route.name !== 'gelt')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                to: "/",
                class: "text-white font-medium"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Log in ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("input", {
      type: "file",
      ref: "fileInput",
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileSelected && _ctx.onFileSelected(...args))),
      style: {"display":"none"},
      accept: "image/*"
    }, null, 544)
  ], 64))
}
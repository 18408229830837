
  import { defineComponent, toRefs, computed } from "vue"

  import { useTypes } from "@/composables"

  import { convertToShortCurrency, converToNumber } from "@/core/helpers/convertToCurrency"

  import { CreditBoxConfig } from "@/core/config"

  import ListItem from "./ListItem.vue"
  import CountiesPreview from "./CountiesPreview.vue"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: "CreditBox",

    props: ['data', 'name', 'canCancel', 'canDelete', 'originator'],

    emits: ['showEditor', 'submit'],

    components: {
      ListItem,
      CountiesPreview
    },

    setup (props, { emit }) {
      const { data } = toRefs(props)

      const {
        executionTypes,
        propertyTypes,
        footprints,
        typeNamesMap
      } = useTypes()

      const showEditor = () => {
        emit('showEditor')
      }

      const amountRange = computed(() => {
        const { minimumAmount, maximumAmount } = data.value.amountRange ? data.value.amountRange : data.value

        return {
          minimumAmount: convertToShortCurrency(minimumAmount),
          maximumAmount: convertToShortCurrency(maximumAmount)
        }
      })
      
      return {
        sections: CreditBoxConfig,
        typeNamesMap,
        convertToShortCurrency,
        amountRange,
        showEditor,
        userHasRole,
        RoleType
      }
    }
  })

import { computed } from "vue"

import { useStore } from "vuex"

import PortalUserProfile from "@/core/models/PortalUserProfile"

import { getRoles } from "@/core/auth/userHelper"

import { Actions } from "@/store/enums/StoreEnums"

export default function useUserProfile () {
  const store = useStore()

  const userProfile = computed<PortalUserProfile>(() => {
    return store.getters.getCurrentUserProfile
  })

  if (!userProfile.value.email) {
    store.dispatch(Actions.GET_USER_PROFILE)
  } 

  const avatarPlaceholder = computed(() => {
    const { name } = userProfile.value

    return name ? `${ name.split(' ')[0][0] }` : '-'
  })

  const getUserProfile = async () => {
    return await store.dispatch(Actions.GET_USER_PROFILE)
  }

  const updateUserProfile = async (payload) => {
    return await store.dispatch(Actions.UPDATE_USER_PARTIAL, payload)
  }

  const userRoles = getRoles()

  return {
    userRoles: userRoles.length ? userRoles : ['admin'],
    userProfile,
    avatarPlaceholder,
    updateUserProfile,
    getUserProfile
  }
}

  import { computed, defineComponent, onMounted, ref, watch } from "vue"

  import LendersFilter from "@/components/Lenders/LendersFilter.vue"
  
  import DataTable from "@/components/DataTable/index.vue"
  import { BasePagination, BaseSpinner, BaseTag } from "@/components/Base"

  import { useStore } from "vuex"

  import { Actions } from "@/store/enums/StoreEnums"

  import getTags from "@/core/helpers/getTags"
  import { StatesMap, PropertyTypesMap, ExecutionTypesMap, FirmTypesMap } from "@/core/constants"
  import { convertToCurrency, convertToShortCurrency } from "@/core/helpers/convertToCurrency"
  import { LenderGridConfig } from "@/core/config"

  import { useModals, useTypes } from "@/composables"

  export default defineComponent({
    name: "LendersList",

    components: {
      LendersFilter,
      DataTable,
      BasePagination,
      BaseSpinner,
      BaseTag
    },

    setup () {
      const loading = ref(true)

      const store = useStore()

      const paging = computed(() => {
        return store.getters.lendersPaging
      })

      const lenders = computed(() => {
        return store.getters.lenders
      })

      onMounted(async () => {
        await store.dispatch(Actions.GET_LENDERS_FILTER)
        await store.dispatch(Actions.GET_PAGED_LENDERS)

        loading.value = false
      })

      const { showModal } = useModals()

      const onPageChange = async (pageNumber) => {
        await store.dispatch(Actions.GET_PAGED_LENDERS, pageNumber)
      }

      const { typeNamesMap } = useTypes()

      return {
        loading,
        paging,
        lenders,
        tableHeader: LenderGridConfig,
        convertToShortCurrency,
        getTags,
        showModal,
        onPageChange,
        typeNamesMap
      }
    }
  })


import { defineComponent, computed, ref } from "vue"
import { useRoute } from "vue-router"

import LayoutUserMenu from "./UserMenu.vue"
import LayoutMobileMenu from "./MobileMenu.vue"
import LayoutHeaderMenu from "./HeaderMenu.vue"

import { BaseMenuToggle } from "@/components/Base"

import { MenuAlt3Icon } from "@heroicons/vue/solid"

import store from "@/store"

export default defineComponent({
  name: 'LayoutNav',

  components: {
    LayoutUserMenu,
    LayoutHeaderMenu,
    MenuAlt3Icon,
    BaseMenuToggle,
    LayoutMobileMenu
  },

  setup () {
    const route = useRoute()
    const fileInput = ref<HTMLInputElement | null>(null)
    const logoSrc = ref('/media/logos/logo-full-white.svg')

    const isAuthenticated = computed(() => {
      return !!store.getters.getCurrentUserProfile?.email
    })

    const handleLogoClick = (event: MouseEvent) => {
      if (event.metaKey || event.ctrlKey) {
        event.preventDefault()
        fileInput.value?.click()
      }
    }

    const onFileSelected = (event: Event) => {
      const target = event.target as HTMLInputElement
      if (target.files && target.files[0]) {
        const file = target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          if (e.target?.result) {
            logoSrc.value = e.target.result as string
          }
        }
        reader.readAsDataURL(file)
      }
    }

    return {
      isAuthenticated,
      route,
      fileInput,
      logoSrc,
      handleLogoClick,
      onFileSelected
    }
  }
})

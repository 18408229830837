<template>
  <div class="text-base leading-7 text-gray-600">
    <h3 class="text-2xl font-bold tracking-tight text-gray-900 flex items-center gap-1">
      <span>About Gelt Financial</span>
    </h3>
    
    <p class="my-3 text-base leading-7 text-gray-600">
      Gelt Financial closes mortgages for commercial property owners and real estate investors nationwide.
      When searching for a private mortgage lender, it’s sometimes challenging to ensure you’re working directly with a lender,
      but at Gelt Financial, there is no third-party hand-off.
      Gelt Financial is a direct lender and will work with you from start to finish, handling all servicing post-closing.
    </p>
    
    <a href="https://geltfinancial.com/" target="_blank">
      <button
        class="inline-flex border border-sky text-sky items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-secondary text-secondary-foreground hover:bg-secondary/80 h-11 px-8 rounded-md mt-1 hover:bg-sky hover:text-white"
      >
        Learn More &nbsp;&nbsp;→
      </button>
    </a>
  </div>
</template>
import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { OriginatorDistributionsApi } from "@/core/services/apis"


export default {
  state: {
    originator: {},
    distributions: [],
    omDistributions: [],
    metaData: {},
    filter: {},
    paging: {
      currentPage: 1,
      pageSize: 100,
      pageCount: 1,
      sortField: "createdDate",
      sortDirection: "DESC",
      totalResults: 0
    },
    inquiries: []
  },

  getters: {
    originatorUser (state) {
      return state.originator
    },
    
    originatorUserDistributions (state) {
      return state.distributions
    },

    originatorUserOmDistributions (state) {
      return state.omDistributions
    },

    originatorUserDistributionsPaging (state) {
      return state.paging
    },

    originatorUserDistributionsFilter (state) {
      return state.filter
    },

    inquiries (state) {
      return state.inquiries
    }
  },

  mutations: {
    [Mutations.SET_ORIGINATOR_USER_MUTATION] (state, originator: []) {
      state.originator = originator
    },

    [Mutations.SET_ORIGINATOR_USER_DISTRIBUTIONS_MUTATION] (state, distributions: []) {
      state.distributions = distributions
    },

    [Mutations.SET_ORIGINATOR_USER_DISTRIBUTIONS_FILTER_METADATA] (state, filterMetadata) {
      state.filterMetadata = filterMetadata
    },

    [Mutations.SET_ORIGINATOR_USER_DISTRIBUTIONS_PAGING] (state, paging) {
      for (const key in paging) {
        state.paging[key] = paging[key]
      }
    },

    [Mutations.SET_ORIGINATOR_USER_DISTRIBUTIONS_FILTER_VALUE] (state, payload) {
      for (const key in payload) {
        state.filter[key] = payload[key]
      }
    },

    [Mutations.SET_INQUIRIES] (state, payload) {
      state.inquiries = payload
    },

    [Mutations.SET_ORIGINATOR_USER_OM_DISTRIBUTIONS] (state, distributions: []) {
      state.omDistributions = distributions
    }
  },

  actions: {
    async [Actions.GET_ORIGINATOR_SETTINGS] ({ commit }, id) {
      const { data } = await OriginatorDistributionsApi.getOriginatorSettings(id)

      console.log('Actions.GET_ORIGINATOR_SETTINGS', data)

      commit(Mutations.SET_ORIGINATOR_USER_MUTATION, data)
      
      return data
    },

    async [Actions.GET_ORIGINATOR_USER_DISTRIBUTIONS_PAGED] ({ commit, getters }, page = 1) {
      console.log('GET_ORIGINATOR_USER_DISTRIBUTIONS_PAGED')
      const { pageSize, currentPage, sortField, sortDirection } = getters.originatorUserDistributionsPaging

      const originatorId = getters.getCurrentUserProfile.originatorId

      const { data } = await OriginatorDistributionsApi.getPagedOriginatorDistributions(
        pageSize,
        page,
        sortField,
        sortDirection,
        originatorId,
        null
      )

      commit(Mutations.SET_ORIGINATOR_USER_DISTRIBUTIONS_MUTATION, data.data)
      commit(Mutations.SET_ORIGINATOR_USER_DISTRIBUTIONS_PAGING, {
        currentPage: data.page,
        pageCount: data.pageCount,
        totalResults: data.totalCount
      })

      return data.data
    },

    async [Actions.GET_ORIGINATOR_USER_DISTRIBUTIONS_BY_ID] ({ commit, getters }, originatorId) {
      console.log('GET_ORIGINATOR_USER_DISTRIBUTIONS_BY_ID')
      const { sortField, sortDirection } = getters.originatorUserDistributionsPaging
      const page = 1

      const { data } = await OriginatorDistributionsApi.getPagedOriginatorDistributions(
        1000,
        1,
        sortField,
        sortDirection,
        originatorId,
        null
      )

      commit(Mutations.SET_ORIGINATOR_USER_DISTRIBUTIONS_MUTATION, data.data)
      commit(Mutations.SET_ORIGINATOR_USER_DISTRIBUTIONS_PAGING, {
        currentPage: data.page,
        pageCount: data.pageCount,
        totalResults: data.totalCount
      })

      return data.data
    },

    async [Actions.GET_ORIGINATOR_USER_OM_DISTRIBUTIONS] ({ commit, getters }, offeringMemorandumId) {
      const { pageSize, currentPage, sortField, sortDirection } = getters.originatorUserDistributionsPaging

      const originatorId = getters.getCurrentUserProfile.originatorId

      const { data } = await OriginatorDistributionsApi.getPagedOriginatorDistributions(
        pageSize,
        1,
        sortField,
        sortDirection,
        originatorId,
        offeringMemorandumId
      )

      commit(Mutations.SET_ORIGINATOR_USER_OM_DISTRIBUTIONS, data.data)
      
      return data.data
    },

    async [Actions.SUBMIT_QUOTE] ({ dispatch, getters }, payload) {
      const { originatorId } = payload
      const { data } = await OriginatorDistributionsApi.submitQuote(originatorId, payload)

      console.log(data)

      if (getters.getCurrentUserProfile.originatorId) {
        await dispatch(Actions.GET_ORIGINATOR_USER_DISTRIBUTIONS_PAGED)
      }

      return data
    },

    async [Actions.UPDATE_QUOTE] ({ dispatch, getters }, payload) {
      const { originatorId } = payload
      const { data } = await OriginatorDistributionsApi.updateQuote(originatorId, payload)

      return data
    },

    async [Actions.REJECT_QUOTE] ({ dispatch, getters }, payload) {
      const { originatorId, distributionId } = payload
      const { data } = await OriginatorDistributionsApi.submitQuoteReject(originatorId, distributionId, payload)

      console.log(data)

      await dispatch(Actions.GET_ORIGINATOR_USER_DISTRIBUTIONS_PAGED)

      return data
    },

    async [Actions.UPDATE_ORIGINATOR_SETTINGS] ({ commit }, { id, payload }) {
      console.log(payload)

      const { data } = await OriginatorDistributionsApi.updateOriginatorSettings(id, payload)

      commit(Mutations.SET_ORIGINATOR_USER_MUTATION, data)

      return data
    },

    async [Actions.INQUIRE] (ctx, { originatorId, payload }) {
      console.log('Actions.INQUIRE')

      const { data } = await OriginatorDistributionsApi.inquire(originatorId, payload)

      return data
    },

    async [Actions.UPDATE_INQUIRIES] ({ commit }) {
      const savedInquiries: string | null = window.localStorage.getItem('inquired')

      if (savedInquiries) commit(Mutations.SET_INQUIRIES, JSON.parse(savedInquiries))
    }

    // async [Actions.GET_ORIGINATOR_USER_FILTER] ({ commit }, payload) {
    //   const { data } = await OriginatorDistributionsApi.getOriginatorFilterMetadata({
    //     originatorId,
    //     distributionId
    //   })

    //   commit(Mutations.SET_ORIGINATOR_FILTER_METADATA, data)
      
    //   return data
    // },

    // async [Actions.UPDATE_ORIGINATOR_FILTER] ({ commit, dispatch }, payload) {
    //   commit(Mutations.SET_ORIGINATOR_FILTER_VALUE, payload)
    //   await dispatch(Actions.GET_PAGED_ORIGINATORS)
    // }
  }
}

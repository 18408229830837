import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative z-10",
    ref: "toggle",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isToggled = !_ctx.isToggled))
  }, [
    _renderSlot(_ctx.$slots, "toggle"),
    (_ctx.isToggled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute mt-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2 px-4", _ctx.menuStyles]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _renderSlot(_ctx.$slots, "items")
        ], 2))
      : _createCommentVNode("", true)
  ], 512))
}
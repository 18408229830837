
  import { defineComponent, onMounted } from "vue"
  import { PostHogTracker } from "@/core/services/trackers"

  export default defineComponent({
    name: "EmptyDashboard",

    setup () {
      onMounted(() => {
        PostHogTracker.capture('Awaiting Approval message', {})
      })
    }
  })

import { Auth0VueClient } from "@auth0/auth0-vue";
import { ref, Ref } from "vue";

export const client: Ref<Auth0VueClient | null> = ref(null);

export function exposeAuth0() {
  return {
    install(app) {
      client.value = app.config.globalProperties['$auth0']
    }
  }
}

    import { defineComponent, ref, toRefs } from "vue"
    
    import { useModals, useUserProfile } from "@/composables"
  
    import { XIcon, TrashIcon } from "@heroicons/vue/solid"

    import store from "@/store"
    import { Actions } from "@/store/enums/StoreEnums"
  
    export default defineComponent({
      name: 'ModalShortList',
  
      emits: ['closed'],
  
      components: {
        XIcon,
        TrashIcon
      },

      props: ['data'],
  
      setup(props) {
        const { closeModal, showModal, showNotification } = useModals()
        const { userProfile } = useUserProfile()
  
        const loading = ref<boolean>(false)

        const showProfile = async (originatorId) => {
          const originator = await store.dispatch(Actions.GET_ORIGINATOR, { id: originatorId })
          showModal('ModalOriginator', { originator })
        }

        const showAlerts = ref<boolean>(false)

        const collectedData = ref<any>({
          name: '',
          description: '',
          id: '',
          originators: ''
        })

        const { data } = toRefs(props)

        for (let key in collectedData.value) {
          collectedData.value[key] = data.value[key]
        }

        const originatorsToDelete = ref<any>([])

        const removeFromList = async (id: string) => {
          originatorsToDelete.value.push(id)
        }

        const handleFormSubmit = async () => {
          console.log('handleFormSubmit')

          if (!loading.value) {
            if (!collectedData.value.name) {
              showAlerts.value = true
            } else {
              showAlerts.value = false
              loading.value = true

              try {
                const payload: any = {
                  advisorId: userProfile.value.advisorId,
                  id: collectedData.value.id,
                  name: collectedData.value.name,
                  description: collectedData.value.description,
                  originators: collectedData.value.originators.filter((item:any) => !originatorsToDelete.value.includes(item.id))
                }
                  
                await store.dispatch(Actions.UPDATE_SHORTLIST, payload)
                
                loading.value = false
                showNotification(`${collectedData.value.name} was updated`)
                closeModal('ModalShortList')
              } catch (error: any) {
                console.log(error)
                showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
                loading.value = false
              }
            }
          }
        }
  
        return {
          closeModal,
          loading,
          showProfile,
          removeFromList,
          handleFormSubmit,
          collectedData,
          showAlerts,
          originatorsToDelete
        }
      }
    })
  

  import { defineComponent, watch, computed, ref, onMounted, nextTick } from "vue"
  import { useRouter, useRoute } from "vue-router"

  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  import {
    DealEditorBorrowerDataConfig,
    DealEditorOmDataConfig
  } from "@/core/config"

  import { useDealData, useModals } from "@/composables"

  import { CheckIcon, EyeIcon } from "@heroicons/vue/solid"
  import { BaseSpinner } from "@/components/Base"
  import {
    DealAddDataPoint,
    DealEditorField,
    DealAddDocument,
    DealDocumentsQueue,
    DealDocumentUpload,
    DealPicturesUpload,
    DealOwner,
    DealGroupedDocuments
  } from "@/components/Deal"

  import Draggable from "vue3-draggable"

  export default defineComponent({
    name: "DealEditor",

    components: {
      Draggable,
      BaseSpinner,
      CheckIcon,
      EyeIcon,
      DealOwner,
      DealEditorField,
      DealPicturesUpload,
      DealAddDataPoint,
      DealAddDocument,
      DealDocumentsQueue,
      // DealDocumentUpload
      DealGroupedDocuments
    },

    setup () {
      const router = useRouter()

      const loading = ref(false)
      const formIsReady = ref(false)

      const { showNotification } = useModals()
      
      const {
        deal,
        om,
        questions,
        questionsAsObject,
        customerName,
        createOM,
        updateOM,
        getOM,
        name,
        updatePicture,
        uploadDocument,
        deleteDocument,
        removePicture,
        getDealData
      } = useDealData()

      const omData = ref<any>(DealEditorOmDataConfig)
      const borrowerData = ref<any>(DealEditorBorrowerDataConfig)

      const answersData = ref<any>({
        label: "Data points",
        fields: []
      })

      const documentsData = ref<any>({
        label: "Documents",
        fields: []
      })

      const buildForm = () => {
        omData.value.fields.headline.value = om.value?.headline || name.value || ''
        omData.value.fields.pictures.value = om.value?.pictures || []
        omData.value.fields.narrative.value = om.value?.narrative || ''
        omData.value.fields.investmentHighlights.value = om.value?.investmentHighlights || ''

        borrowerData.value.fields.name.value = `${customerName.value.firstName} ${customerName.value.lastName}`
        borrowerData.value.fields.email.value = deal.value?.customerData.email
        borrowerData.value.fields.phone.value = deal.value?.customerData.phone

        if (om.value.dataPoints) {
          answersData.value.fields = om.value.dataPoints
        }

        if (deal.value.data) {
          const existingDataPoints = answersData.value.fields.map((item: any) => item.name)

          for (let name in deal.value.data) {
            if (
              !['estimatedProgress', 'type'].includes(name) &&
              !existingDataPoints.includes(name) &&
              questionsAsObject.value[name]?.type !== 'document'
            ) {
              if (deal.value.data[name]) {
                let value = deal.value.data[name]

                if (name.toLowerCase().includes('address')) {
                  if (typeof value === 'object') {
                    value = Object.entries(value).filter((item: any) => {
                      return !['latitude', 'longitude'].includes(item[0])
                    }).map((item: any) => item[1]).join(', ')
                  }
                }

                if (name == 'Purchase date' && typeof value == 'object') {
                  value = `${value.month}/${value.day}/${value.year}`
                }

                answersData.value.fields.push({
                  isVisible: false,
                  $type: 'text',
                  name,
                  value
                })
              }
            }
          }
        }

        if (om.value.documents) {
          documentsData.value.fields = om.value.documents
        }

        const existingDocuments = documentsData.value.fields.map((item: any) => item.name)

        Object.keys(deal.value.data).filter((documentName: string) => {
          return questionsAsObject.value[documentName]?.type == 'document'
        }).forEach((documentName: any) => {
          if (!existingDocuments.includes(documentName) && deal.value.data[documentName]) {
            if (Array.isArray(deal.value.data[documentName])) {
              deal.value.data[documentName].forEach((value: any) => {
                documentsData.value.fields.push({
                  $type: 'document',
                  name: documentName,
                  value,
                  isVisible: false
                })  
              })
            } else {
              documentsData.value.fields.push({
                $type: 'document',
                name: documentName,
                value: deal.value.data[documentName],
                isVisible: false
              })
            }
          }
        })

        formIsReady.value = true
      }

      onMounted(async () => {
        await getOM()

        buildForm()
      })

      const collectedData = ref<any>({})
      const owner = ref<any>(null)
      const queues = ref<any>({
        pictures: [],
        picturesRemoval: [],
        documents: [],
        documentsRemoval: []
      })

      const saveUpdatedOM = async () => {
        loading.value = true

        const payload: any = {
          "advisor": owner.value || om.value.advisor,
          "id": deal.value.offeringMemorandumId,
          "dealId": deal.value.id,
          "hubSpotDealId": deal.value.hubspotID || null,
          "borrower": {
            "userAccountId": deal.value.customer,
            "email": deal.value.customerData.email,
            "name": `${customerName.value.firstName} ${customerName.value.lastName}`,
            "phoneNumber": deal.value.customerData.phone
          },
          "shortDescription": "",
          "headline": om.value.headline,
          "pictures": om.value.pictures,
          "narrative": om.value.narrative,
          "investmentHighlights": om.value.investmentHighlights,
          "marketData": {},
          "dataPoints": [],
          "documents": []
        }

        for (const key of ['investmentHighlights', 'narrative', 'headline']) {
          if (collectedData.value[key]?.value) {
            payload[key] = collectedData.value[key].value
          }
        }

        const dataPoints: any = []

        answersData.value.fields.forEach((item: any) => {
          if (collectedData.value[item.name]) {
            dataPoints.push(collectedData.value[item.name])
          } else {
            dataPoints.push(item)
          }
        })

        const documents = documentsData.value.fields

        payload.dataPoints = dataPoints
        payload.documents = documents

        try {
          const response = await updateOM(payload)
              
          const omID = deal.value.offeringMemorandumId

          const { documents, documentsRemoval, pictures, picturesRemoval } = queues.value
          
          if (pictures.length) {
            for (let i = 0; i < pictures.length; i++) {
              await updatePicture(omID, pictures[i])
            }
          }

          if (picturesRemoval.length) {
            for (let i = 0; i < picturesRemoval.length; i++) {
              await removePicture(omID, picturesRemoval[i])
            }
          }

          if (documents.length) {            
            for (let i = 0; i < documents.length; i++) {
              await uploadDocument(omID, documents[i].name, documents[i].file, !!documents[i].isVisible)
            }
          }

          if (documentsRemoval.length) {
            for (let i = 0; i < documentsRemoval.length; i++) {
              await deleteDocument(omID, documentsRemoval[i])
            }
          }

          router.push({ name: 'deal-shortlist' })
        } catch (error) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
          loading.value = false
        }
      }

      const saveOM = async () => {
        loading.value = true

        const payload: any = {
          "dealId": deal.value.id,
          "hubSpotDealId": deal.value.hubspotID || null,
          "borrower": {
            "userAccountId": deal.value.customer,
            "email": deal.value.customerData.email,
            "name": `${customerName.value.firstName} ${customerName.value.lastName}`,
            "phoneNumber": deal.value.customerData.phone
          },
          "shortDescription": "",
          "headline": "",
          "pictures": [],
          "narrative": "",
          "investmentHighlights": "",
          "marketData": {},
          "dataPoints": [],
          "documents": []
        }

        for (const key of ['investmentHighlights', 'narrative', 'headline']) {
          if (collectedData.value[key]?.value) {
            payload[key] = collectedData.value[key].value
          }
        }

        if (!payload.headline) payload.headline = name.value

        const dataPoints: any = []

        answersData.value.fields.forEach((item: any) => {
          if (collectedData.value[item.name]) {
            dataPoints.push(collectedData.value[item.name])
          } else {
            dataPoints.push(item)
          }
        })

        const documents = documentsData.value.fields

        payload.dataPoints = dataPoints
        payload.documents = documents

        try {
          const response = await createOM(payload)
              
          const omID = response.id

          const { documents, pictures } = queues.value
          
          if (pictures.length) {
            for (let i = 0; i < pictures.length; i++) {
              await updatePicture(omID, pictures[i])
            }
          }

          if (queues.value.documents.length) {            
            for (let i = 0; i < documents.length; i++) {
              await uploadDocument(omID, documents[i].name, documents[i].file, !!documents[i].isVisible)
            }
          }

          router.push({ name: 'deal-shortlist' })
        } catch (error) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
          loading.value = false
        }
      }

      const handleFormSubmit = async () => {
        if (!loading.value) {
          if (deal.value.offeringMemorandumId) {
            await saveUpdatedOM()
          } else {
            await saveOM()
          }
        }
      }

      const onFieldChanged = ({ value, name, isVisible, type = 'text', file }) => {
        collectedData.value[name] = { value, name, isVisible, $type: type }
      }

      const updateDocumentsQueue = ({ name, isVisible, file, $type }) => {
        const existingDocuments = queues.value.documents.map((item:any) => item.file?.name)

        if (existingDocuments.includes(file.name)) {
          queues.value.documents[existingDocuments.indexOf(file.name)].isVisible = isVisible
        } else {
          queues.value.documents.push({ name, isVisible, file, value: file.name })
        }
      }

      const documentsChanged = ref<boolean>(false)

      const updateDocument = ({ name, isVisible, file, value, documentId, $type }) => {
        documentsChanged.value = true

        if (!file && documentId || documentId == 0) {
          if (Number.isInteger(documentId)) {
            documentsData.value.fields[documentId].isVisible = isVisible
          } else {
            documentsData.value.fields.forEach((item: any) => {
              if (item.documentId == documentId) item.isVisible = isVisible
            })
          }
        } else {
          updateDocumentsQueue({ name, isVisible, file, $type })
        }
      }

      const updateDocumentsRemovalQueue = ({ documentId }) => {
        documentsChanged.value = true
        queues.value.documentsRemoval.push(documentId)
      }

      const removeDocumentFromQueue = (name) => {
        queues.value.documents = queues.value.documents.filter((item: any) => item.file.name !== name)
      }

      const updatePicturesQueue = (files) => {
        queues.value.pictures = files
      }

      const updatePicturesRemovalQueue = (ids) => {
        queues.value.picturesRemoval = ids
      }

      const handleOwnerChange = (advisor) => {
        owner.value = advisor
      }

      const handleNewDataPoint = async ({ isVisible, name, value, $type = 'text' }) => {
        const fields = answersData.value.fields

        fields.push({ isVisible, name, value, $type })

        answersData.value.fields = null
        await nextTick()
        answersData.value.fields = fields
        
        collectedData.value[name] = { isVisible, name, value, $type }
      }

      const formIsValid = computed(() => {
        return !!Object.values(collectedData.value).length || queues.value.pictures.length || queues.value.picturesRemoval.length || queues.value.documents.length || owner.value || documentsChanged.value
      })

      return {
        loading,
        deal,
        om,
        formIsValid,
        formIsReady,
        handleOwnerChange,
        omData,
        borrowerData,
        answersData,
        documentsData,
        onFieldChanged,
        updatePicturesQueue,
        updatePicturesRemovalQueue,
        updateDocumentsQueue,
        updateDocumentsRemovalQueue,
        updateDocument,
        removeDocumentFromQueue,
        questionsAsObject,
        questions,
        handleFormSubmit,
        queues,
        handleNewDataPoint,
        collectedData
      }
    }
  })


  import { defineComponent, toRefs, computed } from 'vue'
  import { convertToShortCurrency, convertToCurrency, converToNumber } from "@/core/helpers/convertToCurrency"
  import { useModals } from '@/composables'

  export default defineComponent({
    name: 'OriginatorCreditBox',

    props: ['creditBox', 'name', 'originator'],

    setup (props) {
      const { creditBox } = toRefs(props)

      const executionTypes = computed(() => {
        const obj = {
          displayedTypes: '',
          toggle: ''
        }

        if (creditBox.value?.ExecutionTypes?.length) {
          const types = creditBox.value.ExecutionTypes.map((item: any) => item.Description)

          if (types.length > 5) {
            obj.displayedTypes = types.slice(0, 5).join(', ')
            obj.toggle = `+ ${types.length - 5} more`
          } else {
            obj.displayedTypes = types.join(', ')
          }
        }

        return obj
      })

      const propertyTypes = computed(() => {
        const obj = {
          displayedTypes: '',
          toggle: ''
        }

        if (creditBox.value?.PropertyTypes?.length) {
          const types = creditBox.value.PropertyTypes.map((item: any) => item.Description)

          if (types.length > 5) {
            obj.displayedTypes = types.slice(0, 5).join(', ')
            obj.toggle = `+ ${types.length - 5} more`
          } else {
            obj.displayedTypes = types.join(', ')
          }
        }

        return obj
      })
      
      const footprints = computed(() => {
        const obj = {
          displayedTypes: '',
          toggle: ''
        }

        if (creditBox.value?.Footprints?.length) {
          const types = creditBox.value.Footprints.map((item: any) => item.Description)

          if (types.length > 5) {
            obj.displayedTypes = types.slice(0, 5).join(', ')
            obj.toggle = `+ ${types.length - 5} more`
          } else {
            obj.displayedTypes = types.join(', ')
          }
        }

        return obj
      })

      const { showModal } = useModals()

      return {
        executionTypes,
        propertyTypes,
        footprints,
        convertToShortCurrency,
        showModal
      }
    }
  })

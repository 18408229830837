
  import { defineComponent, ref, watch } from "vue"
  import { useStore } from "vuex"
  import { useRoute } from "vue-router"

  import { onClickOutside } from '@vueuse/core'

  import useUserProfile from '@/composables/useUserProfile'
  
  import LogoutButton from "@/components/Auth/LogoutButton.vue"
  import { BaseAvatar } from "@/components/Base"

  import { getRoles, userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: "LayoutUserMenu",
    
    components: {
      LogoutButton,
      // BaseAvatar
    },

    setup() {
      const store = useStore()
      const route = useRoute()

      const { avatarPlaceholder, userProfile } = useUserProfile()

      const userMenuToggled = ref(false)

      const userMenu = ref(null)
      
      onClickOutside(userMenu, (e) => {
        if (userMenuToggled.value) userMenuToggled.value = false
      })

      watch(route, (newValue, oldValue) => {
        if (userMenuToggled.value) userMenuToggled.value = false
      })

      const nav = [
        {
          path: '/account',
          label: 'Profile',
          key: 'profile'
        },
        // {
        //   path: '/deals',
        //   label: 'Deals',
        //   key: 'deals'
        // },
        // {
        //   path: '/lenders',
        //   label: 'Originators',
        //   key: 'lenders'
        // }
      ]

      return {
        userMenuToggled,
        avatarPlaceholder,
        userMenu,
        nav,
        userProfile,
        userHasRole,
        RoleType
      }
    },
  })

import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { ShortListsApi } from "@/core/services/apis"

export default {
  state: {
    shortLists: []
  },

  getters: {
    shortLists (state: any) {
      return state.shortLists
    }
  },

  mutations: {
    [Mutations.SET_SHORTLISTS_MUTATION] (state: any, shortLists: []) {
      console.log('Mutations.SET_SHORTLISTS_MUTATION')
      state.shortLists = shortLists
    }
  },

  actions: {
    async [Actions.GET_SHORTLISTS] ({ commit }) {
      console.log('Actions.GET_SHORTLISTS')
      
      const { data } = await ShortListsApi.getPaged(
        100,
        1,
        "name",
        "ASC"
      )

      commit(Mutations.SET_SHORTLISTS_MUTATION, data.data)
      
      return data
    },

    async [Actions.CREATE_SHORTLIST] ({ dispatch }, payload: any) {
      const { data } = await ShortListsApi.createShortList(payload)
      
      await dispatch(Actions.GET_SHORTLISTS)

      return data
    },
    
    async [Actions.UPDATE_SHORTLIST] ({ dispatch }, payload: any) {
      const { data } = await ShortListsApi.updateShortList(payload)
      
      await dispatch(Actions.GET_SHORTLISTS)

      return data
    },

    async [Actions.DELETE_SHORTLIST] ({ dispatch }, shortListId: string) {
      const { data } = await ShortListsApi.deleteShortList(shortListId)

      await dispatch(Actions.GET_SHORTLISTS)

      return data
    }
  }
}


  import { defineComponent, computed, ref } from "vue"

  import { useModals, useDealData } from "@/composables"

  import { convertToCurrency } from "@/core/helpers/convertToCurrency.ts"
  
  import { DocumentTextIcon, UserAddIcon, EyeIcon, PlusIcon, ClipboardCopyIcon, CheckCircleIcon } from "@heroicons/vue/solid"

  import Feedback from "@/components/Deal/Feedback.vue"
  import SoftQuote from "@/components/Deal/SoftQuote.vue"

  import { BaseSpinner } from "@/components/Base"

  import copy from 'copy-to-clipboard'

  export default defineComponent({
    name: "DealQuotes",

    components: {
      UserAddIcon,
      SoftQuote,
      Feedback,
      PlusIcon,
      ClipboardCopyIcon,
      CheckCircleIcon
    },

    setup () {
      const { distributions, deal } = useDealData()
      const { showModal } = useModals()

      const quotes = computed(() => {
        if (!distributions.value?.length) return []
        return distributions.value.filter((item: any) => {
          if (item.softQuote) return item
        })
      })

      const feedbacks = computed(() => {
        if (!distributions.value?.length) return []

        const feedbacks: any = []
        
        distributions.value.forEach((item: any) => {
          item.events.forEach((event: any) => {
            if (event.type == 'InformationRequested') feedbacks.push({
              comment: event.data.comment,
              originator: item.originator,
              lender: item.lender,
              createdDate: event.created
            })
          })
        })

        return feedbacks
      })

      const copied = ref<boolean>(false)

      const copyQuoteMatrixLink = () => {
        copied.value = true
        console.log(`${process.env.VUE_APP_IQ2}/deal/${deal.value.id}`)
        copy(`${process.env.VUE_APP_IQ2}/deal/${deal.value.id}`)
      }

      return {
        distributions,
        deal,
        convertToCurrency,
        quotes,
        feedbacks,
        showModal,
        copyQuoteMatrixLink,
        copied
      }
    }
  })

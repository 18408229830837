
  import { defineComponent, ref, toRefs, computed, onMounted } from "vue"

  import { useModals } from "@/composables"

  import { ChevronDownIcon, ChevronUpIcon, XIcon } from "@heroicons/vue/solid"

  import ListItem from "@/components/CreditBox/components/ListItem.vue"
  import { convertToCurrency } from "@/core/helpers/convertToCurrency"

  export default defineComponent({
    name: 'ModalPublicCreditBox',

    components: {
      XIcon,
      ListItem,
      ChevronDownIcon,
      ChevronUpIcon
    },

    props: ['originator', 'creditBox'],

    setup(props) {
      const { creditBox } = toRefs(props)
      const { closeModal, showModal } = useModals()

      const searchQuery = ref<string>("")

      const filteredStates = computed(() => {
        if (searchQuery.value === "") {
          return creditBox.value.Footprints
        }

        const searchTerm = searchQuery.value.toLowerCase();

        return creditBox.value.Footprints.filter(({ Description, Types }) => {
          const stateMatch = Description.toLowerCase().includes(searchTerm)
          
          if (stateMatch) return true

          return Types.some(({ Description, _id }) => {
            return Description.toLowerCase().includes(searchTerm) || String(_id).includes(searchTerm)
          })
        })
      })

      const shouldHighlight = (id: string, description: string): boolean => {
        if (!searchQuery.value.trim()) {
          return false
        }

        const searchTerm = searchQuery.value.trim().toLowerCase()
        
        return id.toLowerCase().includes(searchTerm) || description.toLowerCase().includes(searchTerm)
      }

      const isVisible = ref({})

      onMounted(() => {
        creditBox.value.Footprints.forEach((item: any) => isVisible.value[item.Description] = false)
      })

      const toggle = (name) => {
        let value = isVisible.value[name]
        for (let key in isVisible.value) isVisible.value[key] = false
        if (!value) isVisible.value[name] = true
      }

      const showForm = () => {
        showModal('ModalGetQuotePublic', { originator: props.originator })
        closeModal('ModalPublicCreditBox')
      }

      return {
        closeModal,
        convertToCurrency,
        searchQuery,
        filteredStates,
        shouldHighlight,
        isVisible,
        toggle,
        showForm
      }
    }
  })

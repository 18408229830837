
  import { defineComponent, computed, ref } from "vue"

  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  import { useTypes } from "@/composables"

  import { CreditBox, CreditBoxNavigation } from "@/components/CreditBox"

  export default defineComponent({
    name: "OriginatorAccountLoanSettings",

    components: {
      CreditBox,
      CreditBoxNavigation
    },

    setup () {
      const settings = computed(() => {
        return store.getters.originatorUser
      })

      const { executionTypes, propertyTypes, footprintTypes, typeNamesMap } = useTypes()

      const creditBoxes = computed(() => {
        return Object.entries(settings.value.creditBoxes).map(([key, value]) => {
          return {
            name: key,
            value
          }
        })
      })

      const activeTabIndex = ref<number>(0)

      return {
        settings,
        creditBoxes,
        activeTabIndex
      }
    }
  })


  import { defineComponent, computed, ref, toRefs } from "vue"

  import { DocumentTextIcon, DownloadIcon, EyeIcon } from "@heroicons/vue/solid"

  import { useModals } from "@/composables"

  export default defineComponent({
    name: "DealDocumentsList",

    components: {
      DocumentTextIcon,
      DownloadIcon,
      EyeIcon
    },

    props: ['documents'],

    setup (props) {
      const { showModal } = useModals()
      const { documents } = toRefs(props)

      const visibleDocuments: any = computed(() => {
        if (!documents.value || !documents.value.length) return []
        return documents.value.filter((item: any) => item.isVisible)
      })

      const groups: any = computed(() => {
        const obj = {}

        visibleDocuments.value.forEach((item: any) => {
          if (!obj[item.name]) obj[item.name] = []
          obj[item.name].push(item)
        })

        return obj
      })

      return {
        visibleDocuments,
        groups,
        showModal
      }
    }
  })


  import { defineComponent, ref, computed } from "vue"

  import { IPagination } from "@/core/helpers/ListHelpers"

  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

  export default defineComponent({
    name: "Pagination",

    props: {
      pagingData: { type: Object as () => IPagination  }
    },

    components: {
      ChevronLeftIcon,
      ChevronRightIcon
    },

    emits: ['current-change'],

    setup (props, { emit }) {
      const paging = ref<IPagination>(props.pagingData ?? {
        currentPage: 1,
        pageSize: 10,
        pageCount: 1,
        sortField: "created",
        sortDirection: "DESC",
        totalResults: 0
      })

      const row = 5

      const prevPage = () => {
        if (paging.value.currentPage > 1) {
          emit('current-change', paging.value.currentPage - 1)
        }
      }

      const nextPage = () => {
        if (paging.value.currentPage < paging.value.pageCount) {
          emit('current-change', paging.value.currentPage + 1)
        }
      }

      const setCurrentPage = (value) => {
        if (value == 'skip-back') {
          value = paging.value.currentPage - row
          if (value < 0) value = 1
        } else if (value == 'skip-forward') {
          value = paging.value.currentPage + row
          if (value > paging.value.pageCount) value = paging.value.pageCount
        }
        
        emit('current-change', value)
      }

      const pages = computed(() => {
        const pages: Array<any> = []

        let startingPoint = paging.value.currentPage > 1 ? paging.value.currentPage - 1 : 1

        if (startingPoint + row >= paging.value.pageCount) {
          startingPoint = paging.value.pageCount - row + 1
          if (startingPoint < 1) startingPoint = 1
        }

        if (startingPoint > 2) {
          pages.push({
            index: 1,
            isActive: false
          })

          pages.push({
            index: 'skip-back',
            skip: true,
            isActive: false
          })
        }

        for (let i = 0; i < row; i++) {
          if (i + startingPoint <= paging.value.pageCount) {
            pages.push({
              index: i + startingPoint,
              isActive: i + startingPoint == paging.value.currentPage
            })
          }     
        }

        if (startingPoint + row < paging.value.pageCount) {
          pages.push({
            index: 'skip-forward',
            skip: true,
            isActive: false
          })

          pages.push({
            index: paging.value.pageCount,
            isActive: false
          })
        }

        return pages
      })

      return {
        paging,
        pages,
        nextPage,
        prevPage,
        setCurrentPage
      }
    }
  })

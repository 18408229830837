
  import { defineComponent, onMounted, ref, computed } from "vue"
  import { useStore } from "vuex"

  import { BasePagination, BaseSpinner, BaseRating } from "@/components/Base"
  import DataTable from "@/components/DataTable/index.vue"
  import LendersFilter from "@/components/Lenders/LendersFilter.vue"

  import { MatchingPlainGridConfig } from "@/core/config"
  import { convertToShortCurrency } from "@/core/helpers/convertToCurrency"
  
  import getTags from "@/core/helpers/getTagsAndFixDisplay"

  import { Actions } from "@/store/enums/StoreEnums"

  import { useDealData, useModals } from "@/composables"

  import { ShieldCheckIcon, BanIcon } from "@heroicons/vue/solid"

  import moment from "moment"

  export default defineComponent({
    name: "LendersMatching",

    components: {
      BasePagination,
      DataTable,
      LendersFilter,
      ShieldCheckIcon,
      BaseRating,
      BanIcon
    },

    setup () {
      const store = useStore()

      const { showModal, showNotification } = useModals()

      const paging = computed(() => {
        return store.getters.matchingPaging
      })

      const loading = ref(true)

      const { deal, om, updateOriginatorsList, updateMany } = useDealData()

      const matching = computed(() => {
        return store.getters.matching
      })

      const distributions = computed(() => {
        return store.getters.distributions.filter(item => {
          return !item.deleted
        }).map(item => {
          return item.originator.id
        })
      })

      const lenders = computed(() => {
        return store.getters.distributions.filter(item => {
          return !item.deleted
        }).map(item => {
          return item.originator.lenderId
        })
      })

      onMounted(async () => {
        await store.dispatch(Actions.GET_MATCHING_FILTER_METADATA, om.value.id)
        await store.dispatch(Actions.GET_PAGED_MATCHING)

        loading.value = false
      })

      const onPageChange = async (pageNumber) => {
        await store.dispatch(Actions.GET_PAGED_MATCHING, pageNumber)
      }

      const showProfile = async (originatorId) => {
        const originator = await store.dispatch(Actions.GET_ORIGINATOR, { id: originatorId })
        showModal('ModalOriginator', { originator })
      }

      const showLender = async (originatorId) => {
        const lender = await store.dispatch(Actions.GET_LENDER, { id: originatorId })
        showModal('ModalLender', { lender })
      }

      const handleCheckboxClick = async (originator) => {
        if (!originator.deleted) {
          const { id, name, lenderId } = originator

          const originatorIds = new Set(distributions.value)

          if (!originatorIds.has(id) && lenders.value.includes(lenderId)) {
            showModal('ModalSameLender', { originator, distributions, om })
          } else await updateOriginatorsList(id, name)
        }
      }

      const handleBulkSelect = async (e) => {
        const isChecked = e.target.checked
        const originatorIds = new Set(distributions.value || [])
        let amount = 0

        matching.value.forEach((originator) => {
          const { id, deleted } = originator

          if (!deleted) {
            if (isChecked && !originatorIds.has(id)) {
              originatorIds.add(id)
              amount++
            } else if (!isChecked && originatorIds.has(id)) {
              originatorIds.delete(id)
              amount++
            }
          }
        })

        if (amount > 0) {
          await updateMany(Array.from(originatorIds))
          const action = isChecked ? 'added to' : 'removed from'
          showNotification(`${amount} originators were ${action} the shortlist`)
        }
      }

      const isAllSelected = computed(() => {
        return matching.value.every((originator) => {
          return originator.deleted || distributions.value.includes(originator.id)
        })
      })

      return {
        loading,
        paging,
        tableHeader: MatchingPlainGridConfig,
        matching,
        convertToShortCurrency,
        getTags,
        om,
        updateOriginatorsList,
        distributions,
        showProfile,
        showLender,
        onPageChange,
        handleCheckboxClick,
        moment,
        handleBulkSelect,
        isAllSelected
      }
    }
  })


    import { defineComponent, ref, toRefs, onMounted, computed, watch } from "vue"
  
    import { useModals, useDealData } from "@/composables"
  
    import { XIcon } from "@heroicons/vue/solid"
  
    export default defineComponent({
      name: 'ModalClearShortlistConfirmation',
  
      emits: ['closed'],
  
      components: {
        XIcon
      },
  
      props: ['originator', 'distributions', 'om'],
  
      setup(props, { emit }) {
        const { closeModal, showNotification } = useModals()
        const { clearOriginatorsList } = useDealData()
  
        const clearShortList = async () => {
          await clearOriginatorsList()
          closeModal('ModalClearShortlistConfirmation')
        }
  
        return {
          closeModal,
          clearShortList
        }
      }
    })
  
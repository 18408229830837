
  import { defineComponent, ref, toRefs, computed, watch } from "vue"
  import { useStore } from "vuex"

  import { XIcon } from "@heroicons/vue/solid"

  import { Actions } from "@/store/enums/StoreEnums"

  import { convertToCurrency, converToNumber } from "@/core/helpers/convertToCurrency.ts"

  import { useModals } from "@/composables"

  import { useRouter } from "vue-router"

  export default defineComponent({
    name: 'ModalNewDeal',

    components: {
      XIcon
    },

    props: {},

    setup() {
      const store = useStore()
      const router = useRouter()

      const { closeModal, showNotification } = useModals()

      const config = {
        'Investment Property': [
          {
            key: 'propertyType',
            label: 'Property type',
            options: ['Multifamily 5+ Units', 'Office', 'Retail', 'Industrial', 'Hospitality', '1-4 Unit Residential', 'Self Storage', 'Mobile Home Park']
          },
          {
            key: 'loanReason',
            label: 'Loan purpose',
            options: ['Purchase', 'Refinance', 'Construction']
          }
        ],
        'Business': [
          {
            key: 'creditScore',
            label: 'Credit score',
            options: ['499 or below', '500 - 599', '600 - 649', '650 - 679', '680 - 719', '720 or above']
          },
          {
            key: 'primaryPurpose',
            label: 'Loan purpose',
            options: [
              'Expand an Exisiting Business', 'Working Capital', 'Fund Payroll',
              'Purchase a Business', 'Purchase a Franchise', 'Purchase Equipment',
              'Purchase or Refinance Real Estate', 'Buy Out a Partner',
              'Start a Business', 'Finance Accounts Receivable', 'Construction Financing',
              'eCommerce Inventory'
            ]
          },
          {
            key: 'timeIncorporated',
            label: 'Time incorporated',
            options: ['It’s not incorporated', 'Less than 1 year', '1 – 2 years', '2+ years']
          }
        ]
      }

      const collectedData = ref<any>({
        type: '',
        loanAmount: '',
        propertyType: '',
        loanReason: '',
        creditScore: '',
        primaryPurpose: '',
        timeIncorporated: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        hubspotID: ''
      })

      const isValid = computed(() => {
        if (!collectedData.value.type) return false
        if (!collectedData.value.loanAmount) return false

        if (collectedData.value.type == 'Investment Property') {
          if (!collectedData.value.propertyType || !collectedData.value.loanReason) {
            return false
          }
        } else {
          if (!collectedData.value.creditScore || !collectedData.value.primaryPurpose || !collectedData.value.timeIncorporated) {
            return false
          }
        }

        if (!collectedData.value.firstName || !collectedData.value.lastName || !collectedData.value.email || !collectedData.value.phone) {
          return false
        }

        return true
      })

      watch(() => collectedData.value.loanAmount, (newValue) => {
        collectedData.value.loanAmount = convertToCurrency(newValue)
      })

      const loading = ref<boolean>(false)

      const handleFormSubmit = async () => {
        if (!loading.value) {
          if (isValid.value) {
            loading.value = true

            const payload: any = {
              firstName: collectedData.value.firstName,
              lastName: collectedData.value.lastName,
              phoneNumber: collectedData.value.phone,
              email: collectedData.value.email,
              loanAmount: collectedData.value.loanAmount,
              type: collectedData.value.type,
              hubspotID: collectedData.value.hubspotID || null
            }

            if (collectedData.value.type == 'Investment Property') {
              payload.propertyType = collectedData.value.propertyType
              payload.loanReason = collectedData.value.loanReason
            } else {
              payload.creditScore = collectedData.value.creditScore
              payload.primaryPurpose = collectedData.value.primaryPurpose
              payload.timeIncorporated = collectedData.value.timeIncorporated
            }

            try {
              const response = await store.dispatch(Actions.CREATE_DEAL, payload)

              if (response.deal) {
                router.push(`/deal/${response.deal._id}`)
                closeModal('ModalNewDeal')
              }
            } catch(error) {
              console.log(error)
              showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
            }
          }
        }
      }

      return {
        closeModal,
        handleFormSubmit,
        collectedData,
        config,
        isValid,
        loading
      }
    }
  })


  import { defineComponent, ref, onMounted } from "vue"

  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  import { SelectorIcon } from "@heroicons/vue/solid"

  import { onClickOutside } from '@vueuse/core'

  export default defineComponent({
    name: "DealOwner",

    props: ['value', 'label'],

    emits: ['owner-changed'],

    components: {
      SelectorIcon
    },

    setup (props, { emit }) {
      const advisors = ref<any>([])

      const selectedOwner = ref<any>(props.value?.name || '')

      onMounted(async () => {
        const fetched = await store.dispatch(Actions.GET_ADVISORS)

        advisors.value = fetched
        
        console.log(advisors.value)
      })

      const isOpened = ref<boolean>(false)

      const toggle = () => {
        isOpened.value = !isOpened.value
      }

      const select = (value) => {
        selectedOwner.value = value.name

        emit('owner-changed', value)
      }

      const target = ref<any>(null)

      onClickOutside(target, () => {
        isOpened.value = false
      })

      return {
        advisors,
        toggle,
        select,
        selectedOwner,
        isOpened,
        target
      }
    }
  })

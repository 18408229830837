
  import { defineComponent, ref, computed, watch } from "vue"

  import { useRoute } from "vue-router"

  import { convertToCurrency, convertToShortCurrency } from "@/core/helpers/convertToCurrency"

  import { ArrowNarrowRightIcon, ArrowNarrowDownIcon, CheckIcon, PhoneIcon, MailIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'BorrowerDeal',

    components: {
      CheckIcon,
      ArrowNarrowDownIcon,
      PhoneIcon,
      MailIcon
    },

    setup () {
      const route = useRoute()

      const showAdvisor = ref(false)
      const hideQuiz = ref(false)

      const payload = route.query.newDeal ? '' : window.localStorage.getItem('borrowerDeal')
      const dealData = ref<any>(payload ? JSON.parse(payload) : {})

      const nav = ref([
        {
          title: 'Get to know us',
          subtitle: 'Look through our site, setup an account, get started.',
          key: 'process'
        },
        {
          title: 'Build your loan request',
          subtitle: 'Help us understand your deal by sharing information with us in our intuitive loan portal',
          key: 'propertyType'
        },
        {
          title: 'Upload documentation',
          subtitle: 'Add supporting documentation to ensure we\'ve got a complete package for us to analyze',
          key: 'loanAmount'
        },
        {
          title: 'Marketplace submission',
          subtitle: 'Your assigned capital markets advisor will work closely with our algorithms to produce a picture perfect representation of your deal and shop it to the right originators from thousands of lenders across the country',
          key: 'contacts'
        },
        {
          title: 'Choose the best deal',
          subtitle: 'You\'ll work directly with your advisor or the right lender to select the best deal for you',
          key: 'approval'
        }
      ])

      const steps = ref<any>({
        loanType: {
          title: 'Is this a business loan or an investment property loan?',
          options: ['Business loan', 'Investment Property'],
          collectedData: '',
          isActive: true,
          isCompleted: false,
          nextStep: 'propertyType',
          type: 'selector'
        },
        propertyType: {
          title: 'What type of property are you inquiring about?',
          subtitle: 'If the property is a mix of multifamily and other uses such as office or retail, please select "Multifamily" as your property type.',
          options: ['Multifamily 5+ Units', 'Office', 'Retail', 'Industrial', 'Hospitality', '1-4 Unit Residential', 'Self Storage', 'Mobile Home Park'],
          collectedData: '',
          model: 'propertyType',
          isActive: false,
          isCompleted: false,
          nextStep: 'loanAmount',
          type: 'selector'
        },
        loanAmount: {
          title: 'What is the requested loan amount?',
          subtitle: 'If you aren\'t entirely sure, please make your best educated guess.',
          collectedData: {
            loanAmount: ''
          },
          type: 'loanAmount',
          isActive: false,
          isCompleted: false,
          nextStep: 'loanPurpose'
        },
        loanPurpose: {
          title: 'What is the purpose of this loan?',
          collectedData: '',
          type: 'selector',
          isActive: false,
          isCompleted: false,
          nextStep: 'timingToClose',
          options: ['Purchase', 'Refinance', 'Construction']
        },
        timingToClose: {
          title: 'When do you need to close this loan?',
          collectedData: '',
          type: 'selector',
          isActive: false,
          isCompleted: false,
          nextStep: 'contract',
          options: ['< 30 days', '30-90 days', '3-6 months', 'Unsure']
        },
        contract: {
          title: 'Is the property under contract?',
          collectedData: '',
          type: 'selector',
          isActive: false,
          isCompleted: false,
          nextStep: 'psa',
          options: ['Yes', 'No']
        },
        psa: {
          title: 'Do you have the PSA available to share now?',
          collectedData: '',
          type: 'document',
          isActive: false,
          isCompleted: false,
          nextStep: 'omReady',
        },
        omReady: {
          title: 'Do you have an Offering Memorandum handy?',
          subtitle: 'You may also leave a listing link',
          collectedData: '',
          type: 'document',
          isActive: false,
          isCompleted: false,
          nextStep: 'propertyAddress',
        },
        propertyAddress: {
          title: 'If you have the property address available, please share it here.',
          collectedData: '',
          type: 'address',
          isActive: false,
          isCompleted: false,
          nextStep: 'submit',
        }
      })

      if (route.query) {
        const { lender, loadDeal } = route.query

        if (lender) {
          showAdvisor.value = true
          hideQuiz.value = true
        }

        if (loadDeal) {
          for (const key in dealData.value) {
            if (steps.value[key]) {
              steps.value[key].collectedData = dealData.value[key]
              steps.value[key].isCompleted = true
              steps.value[key].isActive = true
            }
          }

          steps.value.loanPurpose.isActive = true
        }
      }

      const stepCompleted = (key) => {
        if (key == 'process') return true
        if (showAdvisor.value) {
          if (key == 'propertyType' || key == 'loanAmount') {
            return true
          }
        }
        return false
      }

      const stepActive = (key) => {
        if (showAdvisor.value) {
          return key == 'contacts'
        }
        
        if (key == 'propertyType' || key == 'loanAmount') {
          return true
        }
        return false
      }

      const name = computed(() => {
        if (dealData.value['loanAmount']?.loanAmount && dealData.value['propertyType']) {
          return `$${convertToShortCurrency(dealData.value['loanAmount'].loanAmount)} ${dealData.value['propertyType']}`
        }
        return 'New deal'
      })

      const updated = computed(() => {
        return 'Last updated today'
      })

      const visibleSteps = computed(() => {
        return Object.fromEntries(
          Object.entries(steps.value).filter((item: any) => {
            return item[1].isActive
          })
        )
      })

      const updateValue = (model, value) => {
        steps.value[model].collectedData = value
      }

      const processNextStep = (currentStep, nextStep) => {
        if (nextStep !== 'submit') {
          steps.value[nextStep].isActive = true
          steps.value[currentStep].isCompleted = true
        } else {
          steps.value[currentStep].isCompleted = true
          showAdvisor.value = true
        }
      }

      const skip = (key, nextStep) => {
        updateValue(key, 'Skipped')
        processNextStep(key, nextStep)
      }

      const validSteps = computed(() => {
        const obj = {}

        for (const key in steps.value) {
          const { type, collectedData } = steps.value[key]

          if (type == 'selector') {
            obj[key] = !!collectedData
          } else if (type == 'loanAmount') {
            obj[key] = !!collectedData.loanAmount
          } else if (type == 'document') {
            obj[key] = !!collectedData
          } else if (type == 'contacts') {
            obj[key] = !Object.values(collectedData).filter(item => !item).length
          } else {
            obj[key] = !!collectedData
          }
        }

        return obj
      })

      watch(steps, (newValue, oldValue) => {
        if (steps.value.loanAmount.collectedData.loanAmount) {
          steps.value.loanAmount.collectedData.loanAmount = convertToCurrency(steps.value.loanAmount.collectedData.loanAmount)
        }
      }, { deep: true })

      const handleFileInput = (key, e) => {
        if (e.target.files) {
          steps.value[key].collectedData = e.target.files[0].name
        } else {
          steps.value[key].collectedData = 'Uploaded'
        }
      }

      const saveAddress = (e) => {
        steps.value.propertyAddress.collectedData = e.formatted_address
      }

      return {
        name,
        updated,
        nav,
        stepCompleted,
        stepActive,
        steps,
        visibleSteps,
        updateValue,
        processNextStep,
        convertToShortCurrency,
        validSteps,
        skip,
        handleFileInput,
        saveAddress,
        showAdvisor,
        hideQuiz
      }
    }
  })

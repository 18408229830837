export default [
  {
    key: "name",
    name: "Name",
    sortable: false,
  },
  {
    key: "overview",
    name: "Overview",
    sortable: false
  },
  // {
  //   key: "activity",
  //   name: "Last login",
  //   sortable: false
  // },
  {
    key: "lender",
    name: "Lender",
    sortable: false
  },
  {
    key: "location",
    name: "Location",
    sortable: false
  },
  {
    key: "products",
    name: "Products",
    sortable: false
  },
  // {
  //   key: "min",
  //   name: "Min loan",
  //   sortable: true
  // },
  // {
  //   key: "max",
  //   name: "Max loan",
  //   sortable: true
  // },
  // {
  //   key: "property-type",
  //   name: "Property types",
  //   sortable: false
  // },
  // {
  //   key: "execution-type",
  //   name: "Execution types",
  //   sortable: false
  // },
  // {
  //   key: "working-area",
  //   name: "Working Area",
  //   sortable: false
  // }
]
import { createApp } from "vue";
import App from "./App.vue";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Maska from 'maska'

import { BrowserTracing } from "@sentry/tracing"
import * as Sentry from "@sentry/vue"
// import sentry from './plugins/trackers/sentry'
// import Trackers from "./plugins/trackers/Trackers";

import router from "./router/router";
import store from "./store";
// import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";

import "@/core/plugins/prismjs";
import './index.css'
import ApiClient from "@/core/services/ApiClient";
import { createAuth0 } from "@auth0/auth0-vue";
import { exposeAuth0 } from "@/core/plugins/expose-auth0-plugin";

import queryString from "query-string";

const login_hint: any = queryString.parse(location.search)?.login_hint || ''
const screen_hint: any = queryString.parse(location.search)?.screen_hint || ''

const app = createApp(App);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue: app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

app.use(
  createAuth0({
    audience: process.env.VUE_APP_AUTH0_AUDIENCE ?? '',
    domain: process.env.VUE_APP_AUTH0_DOMAIN ?? '',
    client_id: process.env.VUE_APP_AUTH0_CLIENT_ID ?? '',
    redirect_uri: `${process.env.VUE_APP_AUTH0_CALLBACK_URL}?origin=${window?.location?.pathname || ''}`,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    scope: "openid profile email",
    login_hint,
    screen_hint
  }))
.use(exposeAuth0())
.use(store)
.use(router)
.use(Maska)
.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDcReP1bAAq9xQhNaOyxgJ6gkZ-07oPvaE',
    libraries: "places",
    language: 'en',
  },
})

ApiClient.init(app);

// app.use(i18n);

app.mount("#app");

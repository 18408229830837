
import { computed, defineComponent, onMounted, ref } from "vue"
import { useStore } from "vuex"

import { Actions, Mutations } from "@/store/enums/StoreEnums"

import DataModelBase from "@/core/models/DataModelBase"
import { DealFilterProps } from "@/core/services/apis/DealsApi"
import DealGridConfig from "@/core/config/DealGridConfig"
import DealOriginatorGridConfig from "@/core/config/DealOriginatorGridConfig"

import {
  SearchIcon, FilterIcon,
  SortDescendingIcon, XIcon,
  SelectorIcon,
  ArrowNarrowDownIcon, ArrowNarrowUpIcon,
  EyeIcon, AnnotationIcon
} from '@heroicons/vue/solid'

import DataTable from "@/components/DataTable/index.vue"
import { BaseMenuToggle, BasePagination, BaseSpinner } from "@/components/Base"
import { DealsFilter } from "@/components/Deal"

import { IPagination } from "@/core/helpers/ListHelpers"

import { convertToCurrency } from "@/core/helpers/convertToCurrency.ts"
import { useModals } from "@/composables"

import moment from 'moment'

import { userHasRole } from "@/core/auth/userHelper"
import { RoleType } from "@/core/auth/permissionEnums"

const initDealFilter: DealFilterProps = {
  CreatedDateMin: undefined,
  CreatedDateMax: undefined,
  Status: "",
  Type: ""
}

export default defineComponent({
  name: "DealListing",

  components: {
    DataTable,
    BasePagination,
    BaseSpinner,
    EyeIcon,
    DealsFilter,
    AnnotationIcon
  },
  
  methods: {
    moment (date) {
      return moment(date).fromNow();
    },

    convertToCurrency
  },
  
  setup() {
    const loading = ref(true)
    
    const store = useStore()

    const deals = computed(() => {
      return store.getters.deals
    })

    const paging = computed(() => {
      return store.getters.paging
    })

    const profile = computed(() => {
      return store.getters.getCurrentUserProfile
    })

    const distributions = computed(() => store.getters.originatorUserDistributions)

    const distributionsByOmId = computed(() => {
      const obj = {}

      distributions.value.forEach((item: any) => {
        obj[item.distributionDashboardOfferingMemorandum.id] = item
      })

      return obj
    })

    const accountType = computed(() => {
      if (userHasRole(RoleType.ADVISOR)) return 'advisor'
      if (userHasRole(RoleType.ORIGINATOR)) return 'originator'
      return ''
    })

    const tableHeader = accountType.value == 'advisor' ? DealGridConfig : DealOriginatorGridConfig
    
    const getDeals = async (page = 1) => {
      loading.value = true

      try {
        if (accountType.value == 'advisor') await store.dispatch(Actions.GET_PAGED_DEALS, page)
        else await store.dispatch(Actions.GET_PAGED_RECOMMENDATIONS, page)
        loading.value = false
      } catch (error) {
        console.log(error)
        loading.value = false
        showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
      }
    }

    onMounted(async () => {
      store.commit(Mutations.RESET_DEALS)
      
      if (accountType.value == 'advisor') await store.dispatch(Actions.GET_DEAL_FILTER, {})
      
      await getDeals()
      
      if (accountType.value == 'originator') {
        await store.dispatch(Actions.GET_ORIGINATOR_USER_DISTRIBUTIONS_PAGED)
        await store.dispatch(Actions.UPDATE_INQUIRIES)
      }
    })

    const onPageChange = async (pageNumber) => {
      await getDeals(pageNumber)
    }

    const { showModal, showNotification } = useModals()

    const getName = (name) => {
      if (!name) return 'Unknown'

      if (accountType.value == 'advisor') return name
      else {
        const array = name.split(' ')
        return `${array[0]} ${array[array.length - 1][0]}.`
      }
    }

    const inquiries = computed(() => {
      return store.getters.inquiries
    })

    return {
      deals,
      paging,
      loading,
      tableHeader,
      onPageChange,
      accountType,
      showModal,
      getName,
      inquiries,
      profile,
      distributions,
      distributionsByOmId
    }
  }
})

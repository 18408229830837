
  import { defineComponent } from 'vue';

  import { FolderOpenIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    name: 'OriginatorActivity',

    components: {
      FolderOpenIcon
    },

    data () {
      return {
        deals: [
          {
            title: '$325K First Mortgage in Pueblo, CO',
            date: 'September 6th, 2023',
            description: 'Gelt Financial is pleased to announce we just closed a $325K First Mortgage on an Industrial Property in Pueblo, CO. This was structured as a cash-out refinance and allowed the borrower flexibility in their loan term.',
            imageSrc: 'https://geltfinancial.com/wp-content/uploads/2023/09/20190611_124541-jpg.webp',
            link: 'https://geltfinancial.com/deals-done/cash-out-refinance-pueblo-co/'
          },
          {
            title: '$400K First Mortgage in Orlando, FL',
            date: 'August 16th, 2023',
            description: 'Gelt Financial is excited to announce we just closed a $400K First Mortgage on two single family investment homes in Orlando, FL.',
            imageSrc: 'https://geltfinancial.com/wp-content/uploads/2023/08/picture.jpg',
            link: 'https://geltfinancial.com/deals-done/orlando-homes/'
          },
          {
            title: '$840K First Mortgage in Orlando',
            date: 'July 1st, 2023',
            description: 'Gelt Financial is excited to announce we just closed an $840K First Mortgage on an office building in Orlando, FL. This project was creative financing that needed a quick closing.',
            imageSrc: 'https://geltfinancial.com/wp-content/uploads/2023/07/Orlando-Property.webp',
            link: 'https://geltfinancial.com/deals-done/orlando-commercial-hard-money-lender/'
          },
          {
            title: '$185K First Mortgage in Cleveland, OH',
            date: 'May 11th, 2023',
            description: 'Gelt Financial recently closed a $185K First Mortgage in Cleveland, OH, on an owner-occupied warehouse.',
            imageSrc: 'https://geltfinancial.com/wp-content/uploads/2023/05/Cleveland-2.webp',
            link: 'https://geltfinancial.com/real-estate/cleveland/'
          }
        ]
      }
    }
  })

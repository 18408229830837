import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg shadow-sm overflow-hidden max-w-7xl mx-auto" }
const _hoisted_2 = { "aria-label": "Progress" }
const _hoisted_3 = {
  role: "list",
  class: "border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0"
}
const _hoisted_4 = { class: "group flex items-center w-full" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ol", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stages, ({ label, completed, active, name, canRedirect }, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "relative md:flex-1 md:flex",
            key: index
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: { name },
                class: _normalizeClass(["px-4 md:px-6 py-4 flex items-center text-sm font-medium", { 'pointer-events-none': !canRedirect }])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full", {
                  'bg-sky': completed,
                  'border-2 border-sky text-sky': active,
                  'border-2 border-gray-300 text-gray-500': !completed && !active
                }])
                  }, [
                    completed
                      ? (_openBlock(), _createBlock(_component_CheckIcon, {
                          key: 0,
                          class: "w-5 h-5 text-white"
                        }))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, "0" + _toDisplayString(index + 1), 1))
                  ], 2),
                  _createElementVNode("span", {
                    class: _normalizeClass(["ml-4 text-sm font-medium text-gray-500", { 'text-sky': active || completed }])
                  }, _toDisplayString(label), 3)
                ]),
                _: 2
              }, 1032, ["to", "class"])
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}
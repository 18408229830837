import ApiClient from "@/core/services/ApiClient"
import ApiResponse from "../helpers/ApiResponse"

const basePath = "offeringmemoranda"

class OfferingMemorandaApi {
  public static async getOM (offeringMemorandumId: string): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/${offeringMemorandumId}`)
  }

  public static async createOM (payload: any): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}`, payload)
  }

  public static async viewOM (offeringMemorandumId, distributionId, shareCode): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/${offeringMemorandumId}/distributions/${distributionId}?shareCodeId=${shareCode}`)
  }

  public static async previewOM (offeringMemorandumId, shareCode): Promise<ApiResponse<any>> {
    console.log('previewOM')
    return await ApiClient.get<any>(`${basePath}/${offeringMemorandumId}/preview/${shareCode}`, false)
  }

  public static async updateOM (payload: any): Promise<ApiResponse<any>> {
    return await ApiClient.put<any>(`${basePath}/`, payload)
  }

  public static async getDistributions (offeringMemorandumId: string): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/${offeringMemorandumId}/distributions`)
  }

  public static async updateDistributions (offeringMemorandumId: string, originatorIds: Array<any>): Promise<ApiResponse<any>> {
    return await ApiClient.put<any>(`${basePath}/${offeringMemorandumId}/distributions`, {
      originatorIds
    })
  }

  public static async createDistributions (offeringMemorandumId: string, originatorIds: Array<any>): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}/${offeringMemorandumId}/distributions`, {
      originatorIds
    })
  }

  public static async getEmail (offeringMemorandumId: string, distributionId: string): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/${offeringMemorandumId}/distributions/${distributionId}/email`)
  }

  public static async sendEmail (offeringMemorandumId: string, distributionId: string, payload: any): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(
      `${basePath}/${offeringMemorandumId}/distributions/${distributionId}/email`,
      payload
    )
  }

  public static async uploadImage (offeringMemorandumId, formData): Promise<ApiResponse<any>> {
    return await ApiClient.postImage<any>(
      `${basePath}/${offeringMemorandumId}/pictures/upload`,
      formData
    )
  }

  public static async removeImage (offeringMemorandumId, imageId): Promise<ApiResponse<any>> {
    return await ApiClient.delete<any>(
      `${basePath}/${offeringMemorandumId}/pictures/${imageId}`
    )
  }

  public static async getDocuments (offeringMemorandumId): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(
      `${basePath}/${offeringMemorandumId}/documents`
    )
  } 
  
  public static async getDocument (offeringMemorandumId, documentId): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(
      `${basePath}/${offeringMemorandumId}/documents/${documentId}`
    )
  } 
  
  public static async uploadDocument (offeringMemorandumId, formData): Promise<ApiResponse<any>> {
    return await ApiClient.postFile<any>(
      `${basePath}/${offeringMemorandumId}/documents/upload`,
      formData
    )
  }

  public static async deleteDocument (offeringMemorandumId, documentId): Promise<ApiResponse<any>> {
    return await ApiClient.delete<any>(
      `${basePath}/${offeringMemorandumId}/documents/${documentId}`
    )
  }

  public static async bulkSendEmail (offeringMemorandumId: string, distributionIds: any): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(
      `${basePath}/${offeringMemorandumId}/distributions/bulkemail`,
      { distributionIds }
    )
  }

  public static async setStage (dealId: any): Promise<ApiResponse<any>> {
    return await ApiClient.postToNode<any>(
      `set-sourcing-terms`,
      { dealId }
    )
  }
}

export default OfferingMemorandaApi

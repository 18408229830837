
  import { defineComponent, toRefs, computed } from "vue"

  import { PlusIcon } from "@heroicons/vue/solid"
  import { Tab } from "./components"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: "CreditBoxNavigation",

    components: {
      PlusIcon,
      Tab
    },

    emits: ['update:modelValue'],

    props: ['modelValue', 'creditBoxes'],

    setup (props, { emit }) {
      const { creditBoxes } = toRefs(props)
      
      const setActive = (index) => {
        emit('update:modelValue', index)
      }

      const tabs = computed(() => {
        return Object.keys(creditBoxes.value) || []
      })

      return {
        setActive,
        tabs,
        userHasRole,
        RoleType
      }
    }
  })


import { defineComponent } from "vue"

import ModalsBar from "@/components/Modals/ModalsBar.vue"
import NotificationsBar from "@/components/Notifications/index.vue"

export default defineComponent({
  name: "app",
  components: {
    ModalsBar,
    NotificationsBar
  }
})

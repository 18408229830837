
  import { defineComponent } from "vue"

  import {
    InboxInIcon,
    PresentationChartLineIcon,
    CursorClickIcon,
    ArrowRightIcon
  } from "@heroicons/vue/outline"

  import {
    LocationMarkerIcon,
    TicketIcon
  } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "OriginatorDashboard",

    components: {
      InboxInIcon,
      PresentationChartLineIcon,
      CursorClickIcon,
      ArrowRightIcon,
      LocationMarkerIcon,
      TicketIcon
    },

    data () {
      return {
        stats: [
          {
            title: 'Requests awaiting quotes',
            subtitle: '16',
            icon: 'InboxInIcon'
          },
          {
            title: 'Quotes accepted this month',
            subtitle: '58.16%',
            icon: 'PresentationChartLineIcon'
          },
          {
            title: 'Avg. response time',
            subtitle: '2 days',
            icon: 'CursorClickIcon'
          }
        ],
        deals: [
          {
            name: '$434k Multifamily Purchase, FL',
            address: '1 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Needs your quote',
            time: '15m ago',
            color: 'yellow'
          },
          {
            name: '$2M Office Refinance, CA',
            address: '5 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Needs your quote',
            time: '45m ago',
            color: 'yellow'
          },
          {
            name: '$500k Self-storage Purchase, FL',
            address: '16 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Your quote won',
            time: '1d ago',
            color: 'green'
          },
          {
            name: '$750k Mobile home park Purchase, AZ',
            address: '16 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Reviewing quotes',
            time: '1d ago',
            color: 'gray'
          },
          {
            name: '$15M Self-storage Refinance, AZ',
            address: '16 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Your quote won',
            time: '1d ago',
            color: 'green'
          },
          {
            name: '$150M Self-storage Purchase, NY',
            address: '16 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Your quote won',
            time: '1d ago',
            color: 'yellow'
          }
        ]
      }
    }
  })

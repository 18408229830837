export enum NoteActions {
    GET_NOTES = "getNotes",
    GET_NOTE_FILTER = "getNoteFilter",
    GET_NOTE = "getNote",

    UPDATE_NOTE = "updateNote",
}
export enum NoteMutations {
    SET_NOTES_MUTATION = "setNotes",
    SET_NOTES_FILTER_METADATA = "setNoteFilterMetadata",
    SET_NOTE_MUTATION = "setNote",
}


  import { defineComponent, onMounted, computed, ref } from "vue"
  import { useRoute } from "vue-router"

  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals } from "@/composables"

  import { BanIcon } from "@heroicons/vue/solid"
  import { BaseSpinner } from "@/components/Base"
  import OriginatorStats from "@/components/OriginatorStats/index.vue"
  import { CreditBox, CreditBoxNavigation } from "@/components/CreditBox"
  import { OriginatorOverview } from "@/components/Originator"

  export default defineComponent({
    name: "Originator",

    components: {
      BanIcon,
      BaseSpinner,
      OriginatorStats,
      CreditBox,
      CreditBoxNavigation,
      OriginatorOverview
    },

    setup () {
      const route = useRoute()

      const { showModal } = useModals()
      
      const loading = ref<boolean>(true)

      const originator = computed(() => {
        return store.getters.originator
      })

      const lender = computed(() => {
        return store.getters.lender
      })
      
      onMounted(async () => {
        try {
          await store.dispatch(Actions.GET_ORIGINATOR, {
            id: route.params.id
          })

          await store.dispatch(Actions.GET_LENDER, {
            id: originator.value.lenderId
          })
        } catch (e) {
          console.log(e)
        } finally {
          if (originator.value.name) loading.value = false
        }
      })

      const activeTabIndex = ref<number>(0)

      const creditBoxes = computed(() => {
        return Object.entries(originator.value.creditBoxes).map(([key, value]) => {
          return {
            name: key,
            value
          }
        })
      })

      return {
        loading,
        originator,
        lender,
        showModal,
        creditBoxes,
        activeTabIndex
      }
    }
  })

import { computed, ref } from "vue"
import { useStore } from "vuex"

import { Actions } from "@/store/enums/StoreEnums"

export default function useShortLists () {
  const store = useStore()

  const shortLists = computed(() => store.getters.shortLists)

  const getShortLists = async () => {
    await store.dispatch(Actions.GET_SHORTLISTS)
  }

  return {
    templates: shortLists,
    getTemplates: getShortLists
  }
}
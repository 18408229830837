
  import { defineComponent } from "vue"

  import {
    ArrowRightIcon
  } from "@heroicons/vue/outline"

  import {
    LocationMarkerIcon,
    TicketIcon
  } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "BorrowerDashboard",

    components: {
      ArrowRightIcon,
      LocationMarkerIcon,
      TicketIcon
    },

    data () {
      return {
        deals: [
          {
            name: '$434k Multifamily Purchase, FL',
            address: '1 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Needs your action',
            time: '15m ago',
            color: 'yellow',
            query: {
              loadDeal: true
            }
          },
          {
            name: '$2M Office Refinance, CA',
            address: '5 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Provide more data',
            time: '45m ago',
            color: 'yellow',
            query: {
              loadDeal: true
            }
          },
          {
            name: '$500k Self-storage Purchase, FL',
            address: '16 Camino Santa Maria, San Antonio, TX 78228',
            status: 'Waiting for sof quotes',
            time: '1d ago',
            color: 'green',
            query: {
              lender: true
            }
          }
        ]
      }
    }
  })

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-span-8 md:col-span-3 pt-1" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "col-span-8 md:col-span-5" }
const _hoisted_5 = {
  key: 0,
  class: "flex items-center space-x-4"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_8 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EyeOffIcon = _resolveComponent("EyeOffIcon")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid grid-cols-8 gap-2 md:gap-6", { 'opacity-50 pointer-events-none': !_ctx.display }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.visibilityControl)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!_ctx.isVisibleModel)
                ? (_openBlock(), _createBlock(_component_EyeOffIcon, {
                    key: 0,
                    class: "w-4 h-4 text-indigo-200 mr-2 cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeVisibility(!_ctx.isVisibleModel)))
                  }))
                : (_openBlock(), _createBlock(_component_EyeIcon, {
                    key: 1,
                    class: "w-4 h-4 text-indigo-500 mr-2 cursor-pointer",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeVisibility(!_ctx.isVisibleModel)))
                  }))
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("label", {
          for: _ctx.key,
          class: "form-label text-gray-600 text-sm m-0"
        }, _toDisplayString(_ctx.label), 9, _hoisted_3)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.value && !_ctx.isEditor)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("a", {
              href: _ctx.value,
              target: "_blank",
              class: "text-sky font-medium text-sm"
            }, "Download", 8, _hoisted_6),
            _createElementVNode("button", {
              type: "button",
              class: "bg-gray-100 text-gray-600 rounded-md text-sm px-3 py-1",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEditor = true))
            }, "Update")
          ]))
        : _createCommentVNode("", true),
      (!_ctx.value || _ctx.isEditor)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("input", {
              type: "file",
              name: _ctx.key,
              class: "w-56",
              onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFile && _ctx.handleFile(...args)))
            }, null, 40, _hoisted_8),
            (_ctx.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "bg-gray-100 text-gray-600 rounded-md text-sm px-3 py-1",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isEditor = false))
                }, " Cancel "))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1d8ca0ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-4 py-5 sm:px-4 flex justify-between items-center" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["checked", "onClick", "disabled"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex items-center space-x-3" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "text-gray-500 text-sm" }
const _hoisted_10 = { class: "text-gray-500" }
const _hoisted_11 = { class: "flex items-center" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lenders_filter = _resolveComponent("lenders-filter")!
  const _component_ShieldCheckIcon = _resolveComponent("ShieldCheckIcon")!
  const _component_base_rating = _resolveComponent("base-rating")!
  const _component_BanIcon = _resolveComponent("BanIcon")!
  const _component_data_table = _resolveComponent("data-table")!
  const _component_base_pagination = _resolveComponent("base-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_lenders_filter, {
            key: 0,
            scope: "matching"
          }))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_data_table, {
          key: 0,
          tableData: _ctx.matching,
          tableHeader: _ctx.tableHeader,
          minHeight: true,
          "show-checkbox": true,
          onBulkSelect: _ctx.handleBulkSelect,
          "is-all-selected": _ctx.isAllSelected
        }, {
          "cell-name": _withCtx(({ row: match }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("input", {
                type: "checkbox",
                class: "default:ring-2 ...",
                checked: _ctx.distributions.includes(match.id),
                onChange: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
                onClick: _withModifiers(($event: any) => (_ctx.handleCheckboxClick(match)), ["prevent"]),
                disabled: match.deleted
              }, null, 40, _hoisted_3),
              _createElementVNode("span", {
                class: _normalizeClass(["font-medium text-gray-900 ml-2 cursor-pointer", { 'opacity-50': match.deleted }]),
                onClick: ($event: any) => (_ctx.showProfile(match.id))
              }, _toDisplayString(match.name.replaceAll(' ', '').length ? match.name : 'Name Unknown'), 11, _hoisted_4)
            ])
          ]),
          "cell-overview": _withCtx(({ row: match }) => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ShieldCheckIcon, {
                class: _normalizeClass(["w-4 h-4", {
              'text-blue-500': match.isVerified,
              'text-gray-300': !match.isVerified
            }])
              }, null, 8, ["class"]),
              (match.rating)
                ? (_openBlock(), _createBlock(_component_base_rating, {
                    key: 0,
                    rating: match.rating
                  }, null, 8, ["rating"]))
                : _createCommentVNode("", true),
              (match.deleted)
                ? (_openBlock(), _createBlock(_component_BanIcon, {
                    key: 1,
                    class: "w-4 h-4 text-red-700"
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          "cell-lender": _withCtx(({ row: match }) => [
            _createElementVNode("div", _hoisted_6, [
              (match.lenderLogoUri)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: match.lenderLogoUri,
                    class: "max-h-4 max-w-4 mr-2"
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                class: "font-medium text-sky cursor-pointer lender-name",
                onClick: ($event: any) => (_ctx.showLender(match.lenderId))
              }, _toDisplayString(match.lenderName), 9, _hoisted_8)
            ])
          ]),
          "cell-activity": _withCtx(({ row: originator }) => [
            _createElementVNode("span", _hoisted_9, _toDisplayString(originator.lastLogin ? `Last login ${_ctx.moment(originator.lastLogin)}` : 'N/a'), 1)
          ]),
          "cell-location": _withCtx(({ row: match }) => [
            _createElementVNode("span", _hoisted_10, _toDisplayString(match.address?.City || '–') + ", " + _toDisplayString(match.address?.StateProvince || '–'), 1)
          ]),
          "cell-products": _withCtx(({ row: originator }) => [
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(originator.creditBoxes, (product, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: index,
                  class: "py-1 px-2.5 rounded-full mr-2 text-xs bg-blue-200 text-sky cursor-pointer",
                  onClick: ($event: any) => (_ctx.showProfile(originator.id))
                }, _toDisplayString(product.name), 9, _hoisted_12))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["tableData", "tableHeader", "onBulkSelect", "is-all-selected"]))
      : _createCommentVNode("", true),
    _createVNode(_component_base_pagination, {
      pagingData: _ctx.paging,
      onCurrentChange: _ctx.onPageChange
    }, null, 8, ["pagingData", "onCurrentChange"])
  ]))
}
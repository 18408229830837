export default [
  {
    name: 'Greystone',
    min: '1M',
    max: '10M',
    total: '100M',
    executionTypes: ['Refinance', 'Purchase', 'Construction'],
    propertyType: ['Office'],
    territories: ['NY']
  },
  {
    name: 'ROK Capital',
    min: '10K',
    max: '45M',
    total: '10M',
    executionTypes: ['Refinance'],
    propertyType: ['Office', 'Multifamily'],
    territories: ['CA']
  },
  {
    name: 'Janover Ventures',
    min: '1M',
    max: '100M',
    total: '100B',
    executionTypes: ['Refinance', 'Purchase', 'Construction'],
    propertyType: ['Office', 'Multifamily', 'Self Storage', '+4'],
    territories: ['FL'],
    contacts: [
      {
        name: 'Seva',
        min: '100K',
        max: '200K',
        total: '10M',
        executionTypes: ['Refinance', 'Purchase', 'Construction'],
        propertyType: ['Office'],
        territories: ['Nationwide']
      }
    ]
  },
  {
    name: 'Greystone',
    min: '1M',
    max: '10M',
    total: '100M',
    executionTypes: ['Refinance', 'Purchase', 'Construction'],
    propertyType: ['Office'],
    territories: ['NY']
  },
  {
    name: 'ROK Capital',
    min: '10K',
    max: '45M',
    total: '10M',
    executionTypes: ['Refinance'],
    propertyType: ['Office', 'Multifamily'],
    territories: ['CA']
  },
  {
    name: 'Janover Ventures',
    min: '1M',
    max: '100M',
    total: '100B',
    executionTypes: ['Refinance', 'Purchase', 'Construction'],
    propertyType: ['Office', 'Multifamily', 'Self Storage', '+4'],
    territories: ['FL']
  },
  {
    name: 'Greystone',
    min: '1M',
    max: '10M',
    total: '100M',
    executionTypes: ['Refinance', 'Purchase', 'Construction'],
    propertyType: ['Office'],
    territories: ['NY']
  },
  {
    name: 'ROK Capital',
    min: '10K',
    max: '45M',
    total: '10M',
    executionTypes: ['Refinance'],
    propertyType: ['Office', 'Multifamily'],
    territories: ['CA']
  },
  {
    name: 'Janover Ventures',
    min: '1M',
    max: '100M',
    total: '100B',
    executionTypes: ['Refinance', 'Purchase', 'Construction'],
    propertyType: ['Office', 'Multifamily', 'Self Storage', '+4'],
    territories: ['FL']
  }
]

  import { defineComponent, toRefs, computed } from 'vue'

  export default defineComponent({
    name: 'OriginatorCreditBox',

    props: ['creditBox', 'name'],

    setup (props) {
      const { creditBox } = toRefs(props)

      const executionTypes = computed(() => {
        if (creditBox.value?.ExecutionTypes?.length) {
          const types = creditBox.value.ExecutionTypes.map((item: any) => item.Description)

          if (types.length > 5) return types.slice(0, 5).join(', ') + ` + ${types.length - 5} more`
          return types.join(', ')
        }

        return ''
      })

      const propertyTypes = computed(() => {
        if (creditBox.value?.PropertyTypes?.length) {
          const types = creditBox.value.PropertyTypes.map((item: any) => item.Description)

          if (types.length > 5) return types.slice(0, 5).join(', ') + ` + ${types.length - 5} more`
          return types.join(', ')
        }

        return ''
      })
      
      const footprints = computed(() => {
        if (creditBox.value?.Footprints?.length) {
          const types = creditBox.value.Footprints.map((item: any) => item.Description)

          if (types.length > 5) return types.slice(0, 5).join(', ') + ` + ${types.length - 5} more`
          return types.join(', ')
        }

        return ''
      })

      return {
        executionTypes,
        propertyTypes,
        footprints
      }
    }
  })

export enum OriginatorActions {
    GET_ORIGINATORS = "getOriginators",
    GET_PAGED_ORIGINATORS = "getPagedOriginators",
    GET_ORIGINATOR_FILTER = "getOriginatorFilter",
    GET_ORIGINATOR = "getOriginator",
    CREATE_ORIGINATOR = "createOriginator",
    UPDATE_ORIGINATOR = "updateOriginator",
    UPDATE_ORIGINATOR_FILTER = "updateOriginatorFilter",
    CLEAR_ORIGINATOR_FILTER = "clearOriginatorFilter",
    UPDATE_ORIGINATOR_SORTING = "updateOriginatorSorting",
    SET_RATING = "setRating",
    UPDATE_RATING = "updateRating"
}
export enum OriginatorMutations {
    SET_ORIGINATORS_MUTATION = "setOriginators",
    SET_ORIGINATOR_FILTER_METADATA = "setOriginatorFilterMetadata",
    SET_ORIGINATOR_MUTATION = "setOriginator",
    SET_ORIGINATOR_PAGING = "setOriginatorPaging",
    SET_ORIGINATOR_FILTER_VALUE = "setOriginatorFilterValue"
}


  import { defineComponent, ref, onMounted, computed } from "vue"

  import { useModals } from "@/composables"

  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  export default defineComponent({
    name: "AccountContactInformation",

    props: {},

    setup () {
      const loading = ref<boolean>(false)

      const { showNotification } = useModals()

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      const formConfig = [
        {
          key: 'fullName',
          label: 'Full Name',
          type: 'text',
          fullWidth: false
        },
        {
          key: 'email',
          label: 'Email',
          type: 'email',
          fullWidth: false,
          disabled: true
        },
        {
          label: 'Phone Number',
          key: 'phoneNumber',
          type: 'text',
          fullWidth: false
        },
        {
          label: 'Bio',
          key: 'bio',
          type: 'text',
          fullWidth: false
        },
        // {
        //   label: 'Business address',
        //   type: 'address',
        //   key: 'businessAddress',
        //   fullWidth: true
        // }
      ]

      const modifiedData = ref({
        fullName: '',
        email: '',
        phoneNumber: '',
        bio: ''
      })

      onMounted(async () => {
        console.log(profile.value)

        for (let key in profile.value) {
          modifiedData.value[key] = profile.value[key]
        }
      })

      const handleFormSubmit = async () => {
        if (!loading.value) {
          loading.value = true
          try {
            const payload = Object.assign({}, modifiedData.value)
            if (payload.phoneNumber) {
              payload.phoneNumber = payload.phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '').replace('-', '')
            }
            const response = await store.dispatch(Actions.UPDATE_USER, modifiedData.value)
            console.log(response)
            showNotification('Personal information was changed')
          } catch (error: any) {
            console.log(error)
            showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
          } finally {
            loading.value = false
            
          }
        }
      }

      return {
        formConfig,
        modifiedData,
        handleFormSubmit,
        // saveAddress
      }
    }
  })

import DataModelBase from "@/core/models/DataModelBase";

class PortalUserProfile extends DataModelBase {
    id: string | undefined = '';
    lenderID: string | undefined = '';
    borrowerId: string | undefined = '';
    originatorId: string | undefined = '';
    advisorId: string | undefined = '';
    termsAccepted: Date | undefined = new Date();
    termsVersion: string | undefined = '';
    createdAt: Date | undefined = new Date();
    lastLogin: Date | undefined = new Date();
    lastPasswordReset: Date | undefined = new Date();
    locale: string | undefined = '';
    loginsCount: string | undefined = '';
    updatedAt: Date | undefined = new Date();
    multifactor: Array<string> | undefined = []
    email: string | undefined = '';
    emailVerified: boolean | undefined = false;
    phoneNumber: string | undefined = '';
    phoneVerified: boolean | undefined = false;
    userName: string | undefined = '';
    nickName: string | undefined = '';
    firstName: string | undefined = '';
    fullName: string | undefined = '';
    lastName: string | undefined = '';
    picture: string | undefined = '';
    blocked: boolean | undefined = false;
}


/*

avatarUrl: string | undefined = '';
email: string | undefined = '';
companyName: string | undefined = '';
companyId: string | undefined = '';
firstName: string | undefined = '';
lastName: string | undefined = '';
name: string | undefined = '';
phone: string | undefined = '';
username: string | undefined = '';
deleted: boolean | undefined = false;
createdDate: Date | undefined = new Date();
deletedDate: Date | undefined = new Date();
modifiedDate: Date | undefined = new Date();
*/

export default PortalUserProfile;
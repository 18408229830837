export enum DealActions {
    GET_DEALS = "getDeals",
    GET_PAGED_DEALS = "getPagedDeals",
    GET_DEAL_FILTER = "getDealFilter",
    GET_DEAL = "getDeal",
    UPDATE_DEAL = "updateDeal",
    UPDATE_DEALS_FILTER = "updateDealsFilter",
    UPDATE_DEALS_SORTING = "updateDealsSorting",
    CREATE_OM = "createOM",
    GET_OM = "getOM",
    GET_UNDISTRIBUTED_OM = "getUndistributedOM",
    UPDATE_OM = "updateOM",
    GET_DISTRIBUTION_EMAIL = "getDistributionEmail",
    SEND_DISTRIBUTION_EMAIL = "sendDistributionEmail",
    VIEW_OM = "viewOM",
    UPLOAD_PICTURE = "uploadPicture",
    REMOVE_PICTURE = "removePicture",
    GET_PAGED_RECOMMENDATIONS = "getPagedRecommendations",
    GET_RECOMMENDATIONS = "getRecommendations",
    CREATE_DEAL = "createDeal",
    UPLOAD_DOCUMENT = "uploadDocument",
    DELETE_DOCUMENT = "deleteDocument",
    BULK_SEND_EMAIL = "bulkEmailSend",
    GET_ADVISORS = "getAdvisors",
    PREVIEW_OM = "previwOM"
}

export enum DealMutations {
    SET_DEALS_MUTATION = "setDeals",
    SET_DEALS_FILTER_METADATA = "setDealsFilterMetadata",
    SET_DEALS_FILTER_VALUE = "setDealsFilterValue",
    SET_DEAL_MUTATION = "setDeal",
    SET_DEAL_PAGING = "setDealPaging",
    SET_OM_MUTATION = "setOm",
    RESET_DEALS = "resetDeals"
}

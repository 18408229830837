import { createStore } from "vuex";

import AuthModule from "@/store/_modules/AuthModule";
import DealsModule from "@/store/_modules/DealsModule";
import LendersModule from "@/store/_modules/LendersModule";
import LendersMatchingModule from "@/store/_modules/LendersMatchingModule";
import OriginatorsModule from "@/store/_modules/OriginatorsModule";
import FieldsModule from "@/store/_modules/FieldsModule";
import ModalsModule from "@/store/_modules/ModalsModule";
import ShortListsModule from "@/store/_modules/ShortListsModule";

import OriginatorUserModule from "@/store/_modules/OriginatorUserModule";
import OriginatorsPublicModule from "./_modules/OriginatorsPublicModule";

const store = createStore({
  modules: {
    AuthModule,
    DealsModule,
    LendersMatchingModule,
    LendersModule,
    OriginatorsModule,
    FieldsModule,
    ModalsModule,
    OriginatorUserModule,
    ShortListsModule,
    OriginatorsPublicModule
  }
})

export default store

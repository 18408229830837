import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
}
const _hoisted_2 = {
  key: 0,
  class: "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
  id: "options",
  role: "listbox"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "block truncate text-xs md:text-sm" }
const _hoisted_5 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_6 = { class: "flex items-center justify-start pt-3" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!
  const _component_deal_editor_field = _resolveComponent("deal-editor-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "add-data-point relative w-full max-w-xs",
      ref: "target",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "w-full rounded-md border bg-white py-2 pl-3 pr-12 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm",
        role: "combobox",
        "aria-controls": "options",
        "aria-expanded": "false",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        placeholder: "Search..."
      }, null, 512), [
        [_vModelText, _ctx.search]
      ]),
      _createElementVNode("button", _hoisted_1, [
        _createVNode(_component_SelectorIcon, { class: "w-4 h-4" })
      ]),
      (_ctx.isOpened)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (value, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-sky hover:text-white",
                role: "option",
                tabindex: "-1",
                onClick: ($event: any) => (_ctx.select(value))
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(value.name), 1)
              ], 8, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 512),
    (_ctx.activeQuestion)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(), _createBlock(_component_deal_editor_field, {
            key: _ctx.activeQuestion.name,
            label: _ctx.activeQuestion.name,
            value: _ctx.activeQuestion.type === 'selector' ? _ctx.activeQuestion.options[0] : '',
            type: _ctx.activeQuestion.type,
            appearance: _ctx.activeQuestion.appearance,
            options: _ctx.activeQuestion.options,
            name: _ctx.activeQuestion.name,
            "visibility-control": true,
            "break-line": true,
            onUpdated: _ctx.onFieldChanged
          }, null, 8, ["label", "value", "type", "appearance", "options", "name", "onUpdated"])),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: _normalizeClass(["bg-sky text-white rounded-md text-sm px-3 py-1 mr-2", { 'opacity-50': !_ctx.collectedData }]),
              disabled: !_ctx.collectedData,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
            }, " Save ", 10, _hoisted_7),
            _createElementVNode("button", {
              class: "text-sm",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, "Cancel")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}

  import { defineComponent, ref, onMounted, computed } from "vue"

  import {
    InboxInIcon,
    ClipboardCheckIcon,
    PresentationChartLineIcon,
    CursorClickIcon,
    ArrowRightIcon,
    AnnotationIcon
  } from "@heroicons/vue/outline"

  import {
    LocationMarkerIcon,
    TicketIcon
  } from "@heroicons/vue/solid"

  import store from "@/store"

  import { Actions } from "@/store/enums/StoreEnums"

  import moment from 'moment'

  export default defineComponent({
    name: "OriginatorStats",

    props: ['originator'],

    components: {
      InboxInIcon,
      ClipboardCheckIcon,
      PresentationChartLineIcon,
      CursorClickIcon,
      ArrowRightIcon,
      AnnotationIcon,
      LocationMarkerIcon,
      TicketIcon
    },

    setup (props) {
      const loading = ref<boolean>(true)

      const distributions = computed(() => store.getters.originatorUserDistributions)

      onMounted(async () => {
        try {
          await store.dispatch(Actions.GET_ORIGINATOR_USER_DISTRIBUTIONS_BY_ID, props.originator.id)
        } catch (e) {
          console.log(e)
        } finally {
          loading.value = false
        }
      })

      const statusMap = {
        'Added': 'Not sent',
        'Removed': 'Removed',
        'SentMessage': 'Received',
        'ReceivedMessage': 'Received',
        'OpenedMessage': 'Received',
        'Viewed': 'Seen',
        'Interested': 'Interested',
        'Quoted': 'Provided quote',
        'NotInterested': 'Not interested',
        'UndeliverableMessage': 'Undeliverable',
        'InformationRequested': 'Information requested',
      }

      const getDate = (date) => {
        if (!date) return 'Recently'
        return moment(date).fromNow()
      }

      const getAddress = (address) => {
        if (!address) return 'No address'

        const array = address.split(', ')
        if (array.length > 7) array.length = 7

        return array.join(', ')
      }

      const stats = computed(() => {
        const responses = distributions.value.filter((item: any) => !!item.softQuote)
        
        let responseTime = 'Unknown'

        if (responses.length) {
          const responsesTimeArray = responses.map((item: any) => {
            return Math.round((new Date(item.softQuote.createdDate).getTime() - new Date(item.createdDate).getTime()) / 86400000)
          })

          const average = responsesTimeArray.reduce((a: any, b:any) => {
            return a + b
          }) / responsesTimeArray.length

          if (average < 1) responseTime = 'Less than 1 day'
          else responseTime = average < 2 ? '1 day' : `${ average } days`
        }

        return [
          {
            title: 'Requests awaiting quotes',
            subtitle: distributions.value.length,
            icon: 'InboxInIcon'
          },
          {
            title: 'Quotes sent',
            subtitle: responses.length,
            icon: 'ClipboardCheckIcon'
          },
          {
            title: 'Quotes accepted',
            subtitle: '0%',
            icon: 'PresentationChartLineIcon'
          },
          {
            title: 'Avg. response time',
            subtitle: responseTime,
            icon: 'CursorClickIcon'
          }
        ]
      })

      return {
        stats,
        statusMap,
        loading,
        distributions,
        getDate,
        getAddress
      }
    }
  })

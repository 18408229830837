export default {
  'MarketRate': 'Market Rate',
  'AffordableHousing': 'Affordable Housing',
  'StudentHousing': 'Student Housing',
  'SeniorHousing': 'Senior Housing',
  'MilitaryHousing': 'Military Housing',
  'Manufactured': 'Manufactured',
  'MixedUse': 'Mixed Use',
  'SingleFamilyPortfolio': 'Single Family Portfolio',
  'MultiFamilyAssistedLiving': 'Multi Family Assisted Living',
  'MultiFamilyIndependentLiving': 'Multi Family Independent Living',
  'MultiFamilyLand': 'Multi Family Land',
  'Coworking': 'Co-working',
  'Medical': 'Medical',
  'ProfessionalOfficeBuilding': 'Professional Office Building',
  'Warehouse': 'Warehouse',
  'ColdStorage': 'Cold Storage',
  'FlexSpace': 'Flex Space',
  'SelfStorage': 'Self-Storage',
  'RVStorage': 'RV Storage',
  'IndustrialLand': 'Industrial Land',
  'Anchored': 'Anchored',
  'NeighborhoodCenter': 'Neighborhood Center',
  'StripMall': 'Strip Mall',
  'Mall': 'Mall',
  'SingleTenant': 'Single Tenant',
  'RetailLand': 'Retail Land',
  'NNNRetail': 'NNN Retail',
  'Restaurant': 'Restaurant',
  'Hotel': 'Hotel',
  'Motel': 'Motel',
  'ShortTermRental': 'Short Term Rental',
  'HospitalityLand': 'Hospitality Land',
  'AssistedLiving': 'Assisted Living',
  'IndependentLiving': 'Independent Living',
  'MemoryCare': 'Memory Care',
  'Hospital': 'Hospital',
  'RehabFacility': 'Rehab Facility',
  'HealthcareLand': 'Healthcare Land',
  'SingleFamily': 'Single Family',
  'Condominium': 'Condominium',
  'OneToFourUnitResidentialLand': '1-4 Unit Residential Land',
  'CoOp': 'Co-Op',
  'Specialty': 'Specialty',
  'Resort': 'Resort',
  'GolfCourse': 'Golf Course',
  'Church': 'Church',
  'ServiceStation': 'Service Station',
  'Farm': 'Farm',
  'Daycare': 'Daycare',
  'CarDealership': 'Car Dealership',
  'RVPark': 'RV Park',
  'Marina': 'Marina',
  'ParkingGarage': 'Parking Garage',
  'RandDorBioScience': 'Rand Dor Bio Science',
  'SpecialPurposeLand': 'Special Purpose Land'
}
import ApiClient from "@/core/services/ApiClient";
import ApiResponse from "../helpers/ApiResponse";

const basePath = "iq2/field";

export interface FieldFilterProps {
  CreatedDateMin?: Date;
  CreatedDateMax?: Date;
  Type?: string;
  Name?: string;
}

class FieldsApi {
  public static async getField(id: string): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/id/${id}`);
  }

  public static async getFieldFilterMetadata(): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/filter-metadata`);
  }

  public static async getFields(): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}`);
  }

  public static async getPagedFields(
    count: number,
    page: number,
    sortField: string,
    sortDirection: string,
    filter: FieldFilterProps
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getPaged<any>(
      `${basePath}/paged`,
      false,
      count,
      page,
      sortField,
      sortDirection,
      FieldsApi.buildQuerySlug(filter)
    );
  }

  private static buildQuerySlug(filter: any): string[] {
    if (filter == null) return [];

    const slugParts: string[] = [];

    if (filter.type) {
      filter.type.forEach(item => {
        slugParts.push(`Type=${item}`)
      })
    }
    // if (filter.Type) {
    //   slugParts.push(`Type=${filter.Type}`);
    // }
    // if (filter.Name) {
    //   slugParts.push(`Status=${filter.Name}`);
    // }

    return slugParts;
  }
}

export default FieldsApi;

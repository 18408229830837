
  import { defineComponent, computed } from "vue"

  import useDealData from "@/composables/useDealData"

  import { convertToCurrency } from "@/core/helpers/convertToCurrency.ts"
  
  import { DocumentTextIcon, PencilIcon } from "@heroicons/vue/solid"

  import { BaseSpinner } from "@/components/Base"

  export default defineComponent({
    name: "DealOverview",

    components: {
      DocumentTextIcon,
      PencilIcon,
      BaseSpinner
    },

    setup (props) {
      const { deal, questionsAsObject } = useDealData()

      const dealData = computed(() => {
        return Object.entries(deal.value.data).map((item: any) => {
          return {
            label: item[0],
            value: item[1]
          }
        }).filter(({ label }) => {
          return label !== 'estimatedProgress' && label !== 'type'
        })
      })
      
      const answers = computed(() => {
        return dealData.value.filter(({ label, value }) => {
          return questionsAsObject.value[label]?.type !== 'document'
        })
      })

      const documents = computed(() => {
        const documents: any = []

        dealData.value.forEach((item: any) => {
          if (questionsAsObject.value[item.label]?.type === 'document') {
            if (Array.isArray(item.value)) {
              if (item.value.length > 1) {
                for (let i in item.value) {
                  documents.push({
                    label: `${item.label} | Attachment #${Number(i) + 1}`,
                    value: item.value[i]
                  })
                }
              } else {
                documents.push({
                  label: item.label,
                  value: item.value?.[0] || ''
                })
              }
            } else {
              documents.push(item)
            }
          }
        })

        return documents
      })

      const customerData = computed(() => {
        if (!deal.value) return []

        return {
          'Full name': deal.value?.customerData.fullName,
          'Email': deal.value?.customerData.email,
          'Phone number': deal.value?.customerData.phone
        }
      })

      return {
        deal,
        customerData,
        convertToCurrency,
        questionsAsObject,
        answers,
        documents
      }
    }
  })


  import { defineComponent } from "vue"
  import useSystemLayout from "@/composables/useSystemLayout"

  export default defineComponent({
    name: "Error404",
    
    setup() {
      const { getIllustrationsPath } = useSystemLayout()

      return {
        getIllustrationsPath
      }
    }
  })


  import { defineComponent, ref, onMounted, computed } from "vue"

  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals } from "@/composables"

  export default defineComponent({
    name: 'OriginatorAccountContacts',

    setup () {
      const loading = ref(false)

      const { showNotification } = useModals()

      const settings = computed(() => {
        return store.getters.originatorUser
      })

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      const collectedData = ref({
        firstName: '',
        lastName: '',
        phone: '',
        email: profile.value.email
      })

      onMounted(() => {
        if (settings.value) {
          for (let key in collectedData.value) {
            if (settings.value[key]) collectedData.value[key] = settings.value[key]
          }
        }
      })

      const handleFormSubmit = async () => {
        if (!loading.value) {
          loading.value = true

          const payload:any = {
            firstName: collectedData.value.firstName,
            lastName: collectedData.value.lastName,
            phone: collectedData.value.phone
          }

          for (const key in settings.value) {
            if (!payload[key]) payload[key] = settings.value[key]
          }

          try {
            const response = await store.dispatch(Actions.UPDATE_ORIGINATOR_SETTINGS, {
              id: profile.value.originatorId,
              payload
            })

            showNotification('Personal information was updated')
          } catch(error: any) {
            console.log(error)
            showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
          } finally {
            loading.value = false
          }
        }
      }

      return {
        collectedData,
        settings,
        handleFormSubmit
      }
    }
  })

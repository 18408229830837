
  import { defineComponent, ref, onMounted, computed } from "vue"
  import { useRoute, useRouter } from "vue-router"
  import { useStore } from "vuex"

  import { ViewGridIcon, PlusCircleIcon } from "@heroicons/vue/solid"

  import { useDealData, useModals } from "@/composables"
  import { Actions } from "@/store/enums/StoreEnums"

  import Wysiwig from "@/components/Wysiwig/index.vue"
  import { marked } from "marked"
  import { NodeHtmlMarkdown, NodeHtmlMarkdownOptions } from 'node-html-markdown'
  import * as DOMPurify from 'dompurify'

  export default defineComponent({
    name: "DealSendEmail",

    components: {
      ViewGridIcon,
      PlusCircleIcon,
      Wysiwig
    },

    setup () {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      
      const { distributionId } = route.params
      const { deal, om } = useDealData()
      const { showNotification } = useModals()

      const loading = ref<boolean>(true)

      const distribution = computed(() => {
        return store.getters.distributions.filter(item => item.id == distributionId)[0]
      })

      const config = ref<any>({
        to: [],
        bcc: [],
        subject: "",
        body: ""
      })

      onMounted(async () => {
        const response = await store.dispatch(Actions.GET_DISTRIBUTION_EMAIL, {
          offeringMemorandaId: om.value.id,
          distributionId
        })

        for (let key in response) {
          if (key == 'body') {
            config.value.body = NodeHtmlMarkdown.translate(response[key])
          } else if (key == 'bcc' && !response[key]) {
            config.value['bcc'].push({ name: '', address: '' })
          } else {
            config.value[key] = response[key]
          }
        }

        loading.value = false
      })

      const pendingEmail = ref(false)

      const send = async () => {
        if (!pendingEmail.value) {
          pendingEmail.value = true

          const payload = config.value

          payload.body = DOMPurify.sanitize(marked.parse(payload.body))

          try {
            const data = await store.dispatch(Actions.SEND_DISTRIBUTION_EMAIL, {
              offeringMemorandumId: om.value.id,
              distributionId,
              payload
            })

            await store.dispatch(Actions.SET_STAGE, { deal: deal.value })

            router.push({ name: 'deal-om-tracker' })
          } catch(error: any) {
            console.log(error)
            showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
          }
        }
      }

      const addBcc = () => {
        config.value['bcc'].push({ name: '', address: '' })
      }

      return {
        deal,
        om,
        distribution,
        loading,
        config,
        send,
        addBcc,
        pendingEmail
      }
    }
  })

export function getAdvisorImage (email: any): string {
	const map = {
		'david@janover.ventures': '/media/advisors/david.jpeg',
		'blake@janover.ventures': '/media/advisors/blake.png',
		'joe.nolan@janover.ventures': '/media/advisors/joe.jpeg',
		'tyler@janover.ventures': '/media/advisors/tyler.jpeg',
		'brian@janover.ventures': '/media/advisors/brian.jpeg',
		'bryan@janover.ventures': '/media/advisors/bryan.jpeg',
		'brandon@janover.ventures': '/media/advisors/brandon.png',
		'brad.beattie@janover.ventures': '/media/advisors/brad.jpeg',
		'brad@janover.ventures': '/media/advisors/brad.jpeg',
		'jalexander@janover.ventures': '/media/advisors/joey.jpeg',
		'lenders@janover.co': '/media/advisors/joey.jpeg'
    }

    return map[email] || '/media/advisors/joey.jpeg'
}

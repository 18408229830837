export default [
  {
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5208"
    },
    "Category": "Industrial",
    "DisplayName": "Warehouse",
    "Name": "warehouse"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f520b"
    },
    "Category": "Industrial",
    "DisplayName": "Self-Storage",
    "Name": "self-Storage"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f51fa"
    },
    "Category": "Multifamily",
    "DisplayName": "Affordable Housing",
    "Name": "affordableHousing"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5206"
    },
    "Category": "Office",
    "DisplayName": "Professional Office Building",
    "Name": "professionalOfficeBuilding"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f51fd"
    },
    "Category": "Multifamily",
    "DisplayName": "Mobile Home Park",
    "Name": "mobileHomePark"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5203"
    },
    "Category": "Multifamily",
    "DisplayName": "Land",
    "Name": "land"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f51fc"
    },
    "Category": "Multifamily",
    "DisplayName": "Military Housing",
    "Name": "militaryHousing"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5220"
    },
    "Category": "1-4 unit residential",
    "DisplayName": "Condominium",
    "Name": "condominium"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f51fe"
    },
    "Category": "Multifamily",
    "DisplayName": "Prefab Housing",
    "Name": "prefabHousing"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5217"
    },
    "Category": "Hospitality",
    "DisplayName": "Land",
    "Name": "land"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5227"
    },
    "Category": "Specialty",
    "DisplayName": "Day Care",
    "Name": "dayCare"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f521b"
    },
    "Category": "Healthcare",
    "DisplayName": "Hospital",
    "Name": "hospital"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f51fb"
    },
    "Category": "Multifamily",
    "DisplayName": "Student Housing",
    "Name": "studentHousing"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f51f9"
    },
    "Category": "Multifamily",
    "DisplayName": "Market Rate",
    "Name": "marketRate"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f51ff"
    },
    "Category": "Multifamily",
    "DisplayName": "Mixed Use",
    "Name": "mixedUse"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5204"
    },
    "Category": "Office",
    "DisplayName": "Coworking",
    "Name": "coworking"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5212"
    },
    "Category": "Retail",
    "DisplayName": "Single Tenant",
    "Name": "singleTenant"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f522c"
    },
    "Category": "Specialty",
    "DisplayName": "R&D/Bio Science",
    "Name": "r&D/BioScience"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5200"
    },
    "Category": "Multifamily",
    "DisplayName": "Single Family Portfolio",
    "Name": "singleFamilyPortfolio"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5202"
    },
    "Category": "Multifamily",
    "DisplayName": "Independent Living",
    "Name": "independentLiving"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f520d"
    },
    "Category": "Industrial",
    "DisplayName": "Land",
    "Name": "land"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5213"
    },
    "Category": "Retail",
    "DisplayName": "Land",
    "Name": "land"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5218"
    },
    "Category": "Healthcare",
    "DisplayName": "Assisted Living",
    "Name": "assistedLiving"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5222"
    },
    "Category": "Specialty",
    "DisplayName": "Resort",
    "Name": "resort"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5223"
    },
    "Category": "Specialty",
    "DisplayName": "Golf Course",
    "Name": "golfCourse"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f520f"
    },
    "Category": "Retail",
    "DisplayName": "Neighborhood Center",
    "Name": "neighborhoodCenter"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f521a"
    },
    "Category": "Healthcare",
    "DisplayName": "Memory Care",
    "Name": "memoryCare"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f520a"
    },
    "Category": "Industrial",
    "DisplayName": "Flex Space",
    "Name": "flexSpace"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5210"
    },
    "Category": "Retail",
    "DisplayName": "Strip Mall",
    "Name": "stripMall"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5214"
    },
    "Category": "Hospitality",
    "DisplayName": "Hotel",
    "Name": "hotel"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5225"
    },
    "Category": "Specialty",
    "DisplayName": "Service Station",
    "Name": "serviceStation"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5209"
    },
    "Category": "Industrial",
    "DisplayName": "Cold Storage",
    "Name": "coldStorage"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5228"
    },
    "Category": "Specialty",
    "DisplayName": "Car Dealership",
    "Name": "carDealership"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5229"
    },
    "Category": "Specialty",
    "DisplayName": "Rv Park",
    "Name": "rvPark"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f520c"
    },
    "Category": "Industrial",
    "DisplayName": "Rv Storage",
    "Name": "rvStorage"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5215"
    },
    "Category": "Hospitality",
    "DisplayName": "Motel",
    "Name": "motel"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5221"
    },
    "Category": "1-4 unit residential",
    "DisplayName": "Land",
    "Name": "land"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5226"
    },
    "Category": "Specialty",
    "DisplayName": "Farm",
    "Name": "farm"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5201"
    },
    "Category": "Multifamily",
    "DisplayName": "Assisted Living",
    "Name": "assistedLiving"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5205"
    },
    "Category": "Office",
    "DisplayName": "Medical",
    "Name": "medical"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5207"
    },
    "Category": "Office",
    "DisplayName": "Land",
    "Name": "land"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f522b"
    },
    "Category": "Specialty",
    "DisplayName": "Parking Garage",
    "Name": "parkingGarage"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f520e"
    },
    "Category": "Retail",
    "DisplayName": "Anchored",
    "Name": "anchored"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f521c"
    },
    "Category": "Healthcare",
    "DisplayName": "Rehab Facility",
    "Name": "rehabFacility"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f522d"
    },
    "Category": "Specialty",
    "DisplayName": "Land",
    "Name": "land"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5211"
    },
    "Category": "Retail",
    "DisplayName": "Mall",
    "Name": "mall"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5219"
    },
    "Category": "Healthcare",
    "DisplayName": "Independent Living",
    "Name": "independentLiving"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5224"
    },
    "Category": "Specialty",
    "DisplayName": "Church",
    "Name": "church"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f5216"
    },
    "Category": "Hospitality",
    "DisplayName": "Short Term Rental",
    "Name": "shortTermRental"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f522a"
    },
    "Category": "Specialty",
    "DisplayName": "Marina",
    "Name": "marina"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f521d"
    },
    "Category": "Healthcare",
    "DisplayName": "Land",
    "Name": "land"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f521e"
    },
    "Category": "1-4 unit residential",
    "DisplayName": "Single Family Portfolio",
    "Name": "singleFamilyPortfolio"
  },{
    "_id": {
      "$oid": "62a0496c1cc2a214b02f521f"
    },
    "Category": "1-4 unit residential",
    "DisplayName": "Single Family",
    "Name": "singleFamily"
  }
]
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "inline-flex items-center justify-center rounded-md border bg-white hover:bg-gray-50 text-gray-600 px-3 py-2 w-28 text-sm font-medium focus:outline-none mr-2"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "flex items-center justify-start flex-wrap mr-6"
}
const _hoisted_4 = {
  key: 1,
  class: "cursor-pointer inline-flex items-center pl-3 pr-2 py-1 mx-1 rounded-full text-xs font-medium bg-indigo-100 hover:bg-indigo-200 text-sky my-0.5"
}
const _hoisted_5 = {
  key: 2,
  class: "cursor-pointer inline-flex items-center pl-3 pr-2 py-1 mx-1 rounded-full text-xs font-medium bg-indigo-100 hover:bg-indigo-200 text-sky my-0.5"
}
const _hoisted_6 = { class: "relative rounded-md shadow-sm flex-shrink-0" }
const _hoisted_7 = { class: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterIcon = _resolveComponent("FilterIcon")!
  const _component_base_select = _resolveComponent("base-select")!
  const _component_base_menu_toggle = _resolveComponent("base-menu-toggle")!
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_search_icon = _resolveComponent("search-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["hidden md:flex justify-start items-center", { 'pointer-events-none opacity-50': _ctx.blocked }])
    }, [
      _createVNode(_component_base_menu_toggle, { "menu-styles": 'left-0 top-full w-96 py-3' }, {
        toggle: _withCtx(() => [
          _createElementVNode("button", _hoisted_1, [
            _createVNode(_component_FilterIcon, {
              class: "h-4 w-4 mr-1 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" Add filter ")
          ])
        ]),
        items: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterMetadata, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "flex items-center justify-between my-1"
            }, [
              _createElementVNode("label", {
                for: key,
                class: "w-1/3 text-gray-700 font-medium text-sm"
              }, _toDisplayString(value), 9, _hoisted_2),
              (_ctx.displayedValuesMap[key].length)
                ? (_openBlock(), _createBlock(_component_base_select, {
                    key: key,
                    options: _ctx.displayedValuesMap[key],
                    modelValue: _ctx.filtersData[key] || '',
                    "onUpdate:modelValue": ($event: any) => (_ctx.onFilterChange(key, $event))
                  }, null, 8, ["options", "modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _: 1
      }),
      (_ctx.appliedFilters.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appliedFilters, ({ key, value }, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (value instanceof Set)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Array.from(value), (value, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "cursor-pointer inline-flex items-center pl-3 pr-2 py-1 mx-1 rounded-full text-xs font-medium bg-indigo-100 hover:bg-indigo-200 text-sky my-0.5",
                        key: index
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.displayedValuesMap[key][value] || value) + " ", 1),
                        _createVNode(_component_XIcon, {
                          class: "h-3 w-3 ml-1",
                          onClick: ($event: any) => (_ctx.removeFilterValue(key, value))
                        }, null, 8, ["onClick"])
                      ]))
                    }), 128))
                  : (key === 'name' || key === 'originatorName' || key === 'query')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createTextVNode(_toDisplayString(value) + " ", 1),
                        _createVNode(_component_XIcon, {
                          class: "h-3 w-3 ml-1",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchQuery = ''))
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createTextVNode(_toDisplayString(value) + " ", 1),
                        _createVNode(_component_XIcon, {
                          class: "h-3 w-3 ml-1",
                          onClick: ($event: any) => (_ctx.onFilterChange(key, ''))
                        }, null, 8, ["onClick"])
                      ]))
              ], 64))
            }), 128)),
            (_ctx.appliedFilters.length > 0)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#clear-filters",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clearFilters && _ctx.clearFilters(...args)), ["prevent"])),
                  class: "text-sky font-semibold text-xs ml-2"
                }, " Clear filters "))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_search_icon, {
          class: "h-5 w-5 text-gray-400",
          "aria-hidden": "true"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        name: "search",
        class: "focus:ring-indigo-800 focus:border-indigo-800 block w-full pl-10 sm:text-sm border-gray-300 rounded-md border h-10 text-left",
        placeholder: "Borrower name",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchQuery) = $event))
      }, null, 512), [
        [_vModelText, _ctx.searchQuery]
      ])
    ])
  ], 64))
}
// import Analytics from 'analytics-node'

declare global {
  interface Window {
    analytics: any
  }
}

const SegmentTracker = {
	async identify (userId) {
    try {
      await window.analytics.identify(
        userId
      )
    } catch(e) {
      console.log(e)
    }
	},

	async track (event, payload) {
    try {
      await window.analytics.track(event, payload)
    } catch(e) {
      console.log(e)
    }
	},

  async page (page) {
    try {
      await window.analytics.page({
        properties: {
          path: page.fullPath,
        },
      })
    } catch(e) {
      console.log(e)
    }
  }
}

export default SegmentTracker
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "col-span-8 md:col-span-3" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "form-label text-gray-600 text-sm m-0" }
const _hoisted_5 = { class: "col-span-8 md:col-span-5 flex items-center justify-between" }
const _hoisted_6 = { class: "text-sm pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EyeOffIcon = _resolveComponent("EyeOffIcon")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queue, ({ isVisible, name, file }, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["grid grid-cols-8 gap-2 md:gap-6 px-4 md:px-6", { 'border-b pb-4 md:mb-4': index + 1 < _ctx.queue.length }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!isVisible)
              ? (_openBlock(), _createBlock(_component_EyeOffIcon, {
                  key: 0,
                  class: "w-4 h-4 text-indigo-200 mr-2 cursor-pointer",
                  onClick: ($event: any) => (_ctx.changeVisibility(_ctx.queue[index], true))
                }, null, 8, ["onClick"]))
              : (_openBlock(), _createBlock(_component_EyeIcon, {
                  key: 1,
                  class: "w-4 h-4 text-indigo-500 mr-2 cursor-pointer",
                  onClick: ($event: any) => (_ctx.changeVisibility(_ctx.queue[index], false))
                }, null, 8, ["onClick"])),
            _createElementVNode("span", _hoisted_4, _toDisplayString(name), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, "File [" + _toDisplayString(file.name) + "] added to uploading queue. Save OM to continue", 1),
          _createVNode(_component_XIcon, {
            class: "w-4 h-4 text-gray-400 cursor-pointer",
            onClick: ($event: any) => (_ctx.removeFromQueue(file.name))
          }, null, 8, ["onClick"])
        ])
      ], 2))
    }), 128))
  ]))
}
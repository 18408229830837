
  import { defineComponent } from "vue"
  
  import AccountDetails from "./Details.vue"
  import AccountContactInformation from "./ContactInformation.vue"

  export default defineComponent({
    name: "AdvisorAccount",
    
    components: {
      AccountDetails,
      AccountContactInformation
    }
  })

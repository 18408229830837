
  import { defineComponent, ref, toRefs, computed, watch } from "vue"

  import { CreditBoxConfig } from "@/core/config"
  import { convertToCurrency, converToNumber } from "@/core/helpers/convertToCurrency"

  import MapSelector from "@/components/Accounts/Originator/MapSelector.vue"
  import CountiesSelector from "./CountiesSelector.vue"

  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  import { useTypes, useModals } from "@/composables"
  import { PostHogTracker, HubspotTracker } from "@/core/services/trackers"
  
  export default defineComponent({
    name: "CreditBoxEditor",

    props: ['data', 'name', 'canCancel', 'canDelete', 'originator'],

    emits: ['showEditor', 'submit'],

    components: {
      CountiesSelector
    },

    setup (props, { emit }) {
      const loading = ref<boolean>(false)
      
      const { data, name, originator } = toRefs(props)

      const { executionTypes, propertyTypes, footprints, typeNamesMap, footprintIds } = useTypes()
      const { showNotification } = useModals()

      const settings = computed(() => {
        return store.getters.originatorUser || {}
      })

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile || {}
      })

      const collectedData = ref<any>({
        name: '',
        notes: null,
        propertyTypes: new Set(),
        executionTypes: new Set(),
        footprints: new Set(),
        minimumAmount: null,
        maximumAmount: null
      })

      if (name) {
        collectedData.value.name = name.value

        const initialData = JSON.parse(JSON.stringify(data.value))
        
        for (let key in initialData) {
          if (Array.isArray(initialData[key])) collectedData.value[key] = new Set(initialData[key])
          else if (['minimumAmount', 'maximumAmount'].includes(key)) {
            if (initialData[key]) collectedData.value[key] = convertToCurrency(initialData[key])
          }
          else if (['amountRange'].includes(key)) {
            if (initialData[key].minimumAmount) collectedData.value.minimumAmount = convertToCurrency(initialData[key].minimumAmount)
            if (initialData[key].maximumAmount) collectedData.value.maximumAmount = convertToCurrency(initialData[key].maximumAmount)
          }
          else if (initialData[key]) collectedData.value[key] = initialData[key]
        }
      }

      const invalidName = computed(() => {
        if (!collectedData.value.name) return true

        const takenNames = Object.keys(settings.value?.creditBoxes || originator.value.creditBoxes)

        if (takenNames.includes(collectedData.value.name)) return name.value !== collectedData.value.name

        return false
      })

      const updateValue = (model, value) => {
        if (collectedData.value[model].has(value)) {
          collectedData.value[model].delete(value)
        } else {
          collectedData.value[model].add(value)
        }
      }

      const selectGroup = (model, types) => {
        Object.keys(types).forEach(item => {
          if (!collectedData.value[model].has(item)) collectedData.value[model].add(item)
        })
      }

      watch(() => collectedData.value.minimumAmount, (newValue) => {
        collectedData.value.minimumAmount = convertToCurrency(newValue)
      })

      watch(() => collectedData.value.maximumAmount, (newValue) => {
        collectedData.value.maximumAmount = convertToCurrency(newValue)
      })

      const handleFormSubmit = async () => {
        if (!loading.value) {
          loading.value = true

          const payload:any = !originator.value ? {
            firstName: settings.value.firstName,
            lastName: settings.value.lastName,
            phone: settings.value.phone
          } : {
            isVerified: originator.value.isVerified,
            lenderId: originator.value.lenderId,
            email: originator.value.email,
            name: originator.value.name,
            preferredCommunicationType: originator.value.preferredCommunicationType,
            phone: originator.value.phone,
            id: originator.value.id,
            rating: originator.value.rating,
            description: originator.value.description,
            deleted: originator.value.deleted
          }

          const newCreditBox: any = {
            notes: collectedData.value.notes
          }

          console.log('new credit box', newCreditBox)

          for (const key in collectedData.value) {
            if (!['minimumAmount', 'maximumAmount', 'name', 'notes'].includes(key)) {
              newCreditBox[key] = Array.from(collectedData.value[key])
            }
          }

          newCreditBox.minimumAmount = collectedData.value.minimumAmount ? parseInt(collectedData.value.minimumAmount?.replaceAll(',', '')) : null
          newCreditBox.maximumAmount = collectedData.value.maximumAmount ? parseInt(collectedData.value.maximumAmount?.replaceAll(',', '')) : null

          if (name.value) {
            const creditBoxes = JSON.parse(JSON.stringify(originator.value ? originator.value.creditBoxes : settings.value.creditBoxes))

            payload.creditBoxes = Object.fromEntries(
              Object.entries(creditBoxes).map(([key, value]: any) => {
                if (key !== name.value) return [key, value]

                newCreditBox.id = value.id

                return [collectedData.value.name, newCreditBox]
              })
            )
          } else {
            payload.creditBoxes = JSON.parse(JSON.stringify(originator.value ? originator.value.creditBoxes : settings.value.creditBoxes))
            payload.creditBoxes[collectedData.value.name] = newCreditBox
          }

          try {
            console.log(payload)
            if (originator.value) {
              await store.dispatch(Actions.UPDATE_ORIGINATOR, payload)
            } else {
              await store.dispatch(Actions.UPDATE_ORIGINATOR_SETTINGS, {
                id: profile.value.originatorId,
                payload
              })  
            }
            
            HubspotTracker.push('trackEvent', {
              id: 'IQ3 – Credit Box Updated',
            })
            PostHogTracker.capture('Credit box updated', payload)

            emit('submit')
            showNotification('Loan settings were updated')
          } catch(error) {
            console.log(error)
          } finally {
            loading.value = false
          }
        }
      }

      return {
        sections: CreditBoxConfig,
        collectedData,
        invalidName,
        updateValue,
        selectGroup,
        options: {
          executionTypes: executionTypes.value,
          propertyTypes: propertyTypes.value,
          footprints: footprints.value   
        },
        typeNamesMap,
        handleFormSubmit
      }
    }
  })


  import { defineComponent, ref } from "vue"

  import { ExclamationIcon } from "@heroicons/vue/outline"
  import { XIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'Notice',

    props: ['title', 'text'],

    components: {
      ExclamationIcon,
      XIcon
    },

    setup () {
      const isVisible = ref<boolean>(true)

      return {
        isVisible
      }
    }
  })

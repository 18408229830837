
  import { ref, toRefs, defineComponent, onMounted, nextTick, computed } from 'vue'
  import { OriginatorsPublicApi } from "@/core/services/apis"
  import { DotsHorizontalIcon } from '@heroicons/vue/solid'
  import { PostHogTracker } from '@/core/services/trackers'
  import { useRoute } from 'vue-router'


  export default defineComponent({
    name: 'Chat',
    
    props: ['originator', 'botName'],

    emits: ['ended'],

    components: {
      DotsHorizontalIcon
    },

    setup(props, { emit }) {
      const messages = ref<any>([])
      const newMessage = ref('')
      const scrollable = ref<any>(null)
      const conversationId = ref<any>(null)
      const loading = ref<boolean>(false)
      const { originator } = toRefs<any>(props)
      const isDone = ref<boolean>(false)
      const isFocused = ref<boolean>(false)
      const route = useRoute()
      let retryCount = 0
      const formattedMessages = computed(() => {
            return messages.value.map(message => {
              if (message.sender === 'system') {
                const formattedText = message.text.replace(/https?:\/\/[^\s]+/g, url => {
                  return `<a href="${url}" target="_blank" class="text-blue-500 hover:underline">${url}</a>`;
                });
                return { ...message, text: formattedText };
              }
              return message;
            });
          });
      const clientId = computed(() => {
        if (route.name == 'GroundbreakerChat') {
          return 'groundbreaker'
        }
        if (route.name == 'JProChat') {
          return 'jpro'
        }
        if (route.name == 'IlalChat') {
          return 'ilal'
        }
        return route.name == 'LendingOneChat' ? 'lendingone' : 'gelt'
      })

      const initialMessage = computed(() => {
        if (route.name == 'GroundbreakerChat') {
          return '👋 Welcome to Groundbreaker, would you like to impress your investors and close more deals?';
        }
        if (route.name == 'JProChat') {
          return "👋 Welcome to Janover Pro, would you like to connect with lenders across America?";
        }
        if (route.name == 'GeltFinancialsChat') {
          return "👋 Hi there! I'm Steven, Gelt Financial's Chatbot (currently in beta). Please reach out to info@geltfinancial.com with any comments about my performance. Can I help you get financing today?";
        }
        if (route.name == 'IlalChat') {
          return "👋 Hi there, are you looking for a place to retire in luxury, a new adventure, or both?";
        }
        return '👋 Hi there! Can I help you get started with a new loan quote? Or do you have any questions I can answer first?';
      });

      const formatTime = (timestamp) => {
        const d = new Date(timestamp)

        return String(d.getHours()).padStart(2, '0') + ':' + String(d.getMinutes()).padStart(2, '0')
      }

      const scrollToBottom = () => {
        nextTick(() => {
          if (scrollable.value) {
            scrollable.value.scrollTop = scrollable.value.scrollHeight
          }
        })
      }

      const sendMessage = async () => {
        if (newMessage.value.trim() === '') return;

        if (!socket.value || socket.value.readyState !== WebSocket.OPEN) {
          console.log("WebSocket is not connected. Attempting to reconnect...");
          await reconnectWebSocket();
        }

        if (socket.value && socket.value.readyState === WebSocket.OPEN) {
          loading.value = true;
          PostHogTracker.capture('Message Sent', {
            message: newMessage.value,
            originatorId: originator?.value?._id || '',
            clientId: clientId.value
          });
          messages.value.push({ sender: 'user', text: newMessage.value, timestamp: new Date() });
          socket.value.send(JSON.stringify({
            conversationId: conversationId.value,
            userMessage: newMessage.value,
            originator: originator?.value?._id || '',
            clientId: clientId.value
          }));
          newMessage.value = '';
          scrollToBottom();
        } else {
          console.error("Failed to reconnect WebSocket.");
        }
      };


      const isPreviousMessageFromSameSender = (index: number) => {
        return index > 0 && messages.value[index - 1].sender === messages.value[index].sender;
      }

      const socket = ref<any>(null)

      const reconnectWebSocket = () => {
        return new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            initWebSocket();
            resolve(); // Correctly resolving a Promise<void>
          }, 1000);
        });
      };




      const endChat = () => {
        socket.value.close();
        emit('ended')
      }

      const initWebSocket = () => {
          if (socket.value && socket.value.readyState === WebSocket.OPEN) {
            socket.value.close();
          }
          const maxRetries = 6; // Maximum number of retries
          const retryInterval = 2000; // Time in milliseconds to wait before a retry
          
          socket.value = new WebSocket(process.env.VUE_APP_NODE_WSS_URL);

          socket.value.onopen = () => {
              console.log('Conversation started');
              retryCount = 0; 
          };

          socket.value.onerror = async (error) => {
            await reconnectWebSocket();
          };

          socket.value.onmessage = (event) => {
            const response = JSON.parse(event.data)
            conversationId.value = response._id
            localStorage.setItem('ALxWLyRvbmgSeSAA', conversationId.value)
            messages.value = []
            messages.value = response.messages
            isDone.value = response.should_terminate_chat
            scrollToBottom()
            loading.value = false
          }

          socket.value.onclose = (event) => {
            if (!event.wasClean && retryCount < maxRetries) {
              retryCount++;
              console.log(`WebSocket closed. Attempting to reconnect... (Attempt ${retryCount})`);
              setTimeout(initWebSocket, retryInterval);
            } else {
              console.log("WebSocket closed, maximum retry attempts reached");
            }
          };

          scrollToBottom()
      };

      onMounted(async () => {
        conversationId.value = localStorage.getItem('ALxWLyRvbmgSeSAA')

        if (conversationId.value) {
          try {
            const { data } = await OriginatorsPublicApi.getConversation(conversationId.value)
            messages.value = data.messages
            isDone.value = data.should_terminate_chat
          } catch (error) {
            localStorage.removeItem('ALxWLyRvbmgSeSAA')
          }
        } else {
          newMessage.value = ''
          messages.value = [
            {
              sender: 'system',
              text: initialMessage.value,
              timestamp: new Date()
            }
          ]
          nextTick(() => {
            document?.getElementById('chat-message')?.focus()
          })
        }

        initWebSocket()
      })

      const startNewConversation = () => {
        newMessage.value = ''
        messages.value = []
        conversationId.value = null
        localStorage.removeItem('ALxWLyRvbmgSeSAA')
        loading.value = false
        isDone.value = false

        if (socket.value && socket.value.readyState === WebSocket.OPEN) {
          socket.value.close()
        }

        initWebSocket()
      }

      return {
        formattedMessages,
        messages,
        newMessage,
        sendMessage,
        scrollable,
        formatTime,
        loading,
        isDone,
        endChat,
        startNewConversation,
        isPreviousMessageFromSameSender,
        isFocused
      }
    },
  })

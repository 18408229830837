
  import { defineComponent, computed } from "vue"

  import { useRoute } from "vue-router"

  import useDealData from "@/composables/useDealData.ts"

  import { CheckIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "DealLenderSteps",

    components: {
      CheckIcon
    },
    
    setup () {
      const route = useRoute()

      const { deal, distributions } = useDealData()

      const stages = computed(() => {
        const name: string = route?.name?.toString() || ''
        
        return [
          {
            label: deal.value.offeringMemorandumId ? "Edit OM" : "Create an OM",
            active: ['deal-editor', 'deal-overview'].includes(name),
            completed: ['deal-shortlist', 'deal-om-tracker', 'deal-om-send', 'deal-quotes'].includes(name),
            name: 'deal-editor',
            canRedirect: true
          },
          {
            label: "Choose lenders",
            active: route.name === 'deal-shortlist',
            completed: ['deal-om-tracker', 'deal-om-send', 'deal-quotes'].includes(name),
            name: 'deal-shortlist',
            canRedirect: !!deal.value.offeringMemorandumId
          },
          {
            label: "OM tracker",
            active: ['deal-om-tracker', 'deal-om-send'].includes(name),
            completed: name == 'deal-quotes',
            name: 'deal-om-tracker',
            canRedirect: !!distributions.value.length
          },
          {
            label: "Review quotes",
            name: 'deal-quotes',
            canRedirect: !!distributions.value.length,
            active: name == 'deal-quotes'
          }
        ]
      })

      return {
        stages,
        route,
        deal
      }
    }
  })

const DealGridConfig = [
  {
    name: "Customer",
    key: "customer",
    sortable: true,
    width: "min-w-150px"
  },
  {
    name: "Loan Amount",
    key: "amount",
    sortable: false,
  },
  {
    name: "Loan Purpose",
    key: "purpose",
    sortable: false,
  },
  {
    name: "Type",
    key: "type",
    sortingField: "type",
    sortable: true,
  },
  {
    name: "Location",
    key: "state",
    sortable: false,
  },
  {
    name: "",
    key: "details",
    sortable: false,
  }
]

export default DealGridConfig

import { computed } from "vue"

import { useStore } from "vuex"

export default function useTypes () {
	const store = useStore()

	const firmTypes = computed(() => {
        return store.getters.firmTypes
	})

	const executionTypes = computed(() => {
        return store.getters.executionTypes
	})

	const propertyTypes = computed(() => {
		return store.getters.propertyTypes
	})

	const footprintTypes = computed(() => {
		return store.getters.footprintTypes
	})

	const footprints = computed(() => {
		return store.getters.footprintTypes
	})

	const footprintIds = computed(() => {
		return store.getters.footprintIds
	})

	const footprintsNested = computed(() => {
		return store.getters.footprintsNested
	})

	const typeNamesMap = computed(() => {
		return store.getters.typeNamesMap
	})

	return {
		firmTypes,
		executionTypes,
		propertyTypes,
		footprintTypes,
		footprints,
		typeNamesMap,
		footprintIds,
		footprintsNested
	}
}
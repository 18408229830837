
  import { defineComponent, ref, toRefs, computed } from 'vue'

  import { SelectorIcon } from "@heroicons/vue/solid"

  import { onClickOutside } from '@vueuse/core'

  export default defineComponent({
    name: 'Multiselect',

    props: {
      modelValue: {
        type: Array,
        default () {
          return []
        }
      },

      options: {
        type: Object,
        default () {
          return {}
        }
      },

      key: {
        type: String,
        default () {
          return ''
        }
      }
    },

    emits: ['update:modelValue'],

    components: {
      SelectorIcon
    },

    setup (props, { emit }) {
      const isOpened = ref<boolean>(false)
      const target = ref<any>(null)
      const search = ref<string>('')
      
      const {
        modelValue,
        options,
        key
      } = toRefs<any>(props)

      onClickOutside(target, () => {
        isOpened.value = false
        search.value = ''
      })

      const updateValue = (value) => {
        let newValue = modelValue.value

        if (!newValue.includes(value)) {
          newValue.push(value)
        } else {
          newValue = newValue.filter(item => item !== value)
        }

        emit('update:modelValue', newValue)
      }

      const availableOptions = computed(() => {
        return Object.fromEntries(
          Object.entries(options.value).filter(item => {
            return !modelValue.value.includes(item[0])
          })
        )
      })

      const filteredOptions = computed(() => {
        let query = JSON.parse(JSON.stringify(search.value)).toLowerCase()
        
        return Object.fromEntries(
          Object.entries(availableOptions.value).filter((item: any) => {
            return item[1].toString().toLowerCase().includes(query)
          })
        )
      })

      const toggle = () => {
        if (isOpened.value) {
          isOpened.value = false
          search.value = ''
        } else {
          isOpened.value = true
        }
      }

      return {
        isOpened,
        search,
        target,
        availableOptions,
        filteredOptions,
        updateValue,
        toggle
      }
    }
  })

import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { ContactInfoApi, PortalUserApi, TypesApi } from "@/core/services/apis"
import PortalUserProfile from "@/core/models/PortalUserProfile"

import { useAuth0 } from "@auth0/auth0-vue"
import { getRoles } from "@/core/auth/userHelper"

import queryString from "query-string"

import { PostHogTracker, SegmentTracker, HubspotTracker } from "@/core/services/trackers"

export default {
  state: {
    currentUserProfile: new PortalUserProfile(),
    types: {},
    advisorsInfo: []
  },

  getters: {
    getCurrentUserProfile (state) {
      return state.currentUserProfile
    },

    termsAccepted (state) {
      return state.currentUserProfile.termsAccepted
    },

    advisorsInfo (state) {
      if (state.advisorsInfo.length) {
        return Object.fromEntries(
          state.advisorsInfo.map((item: any) => [item.email, item])
        )
      }
      
      return {}
    },

    types (state) {
      return state.types
    },

    executionTypes (state) {
      const obj: any = {}

      const other:any = {
        types: {},
        description: 'Other'
      }

      const { executionTypes } = state.types

      if (executionTypes && executionTypes.length) {
        executionTypes.forEach(({ description, id, types }) => {
          if (!types) {
            other.types[id] = description
          } else {
            obj[id] = { description, types: {} }
            
            types.forEach(item => {
              obj[id].types[item.id] = item.description
            })
          }
        })
      }

      if (Object.entries(other.types).length) {
        obj['Other'] = other
      }

      return obj
    },

    propertyTypes (state) {
      const obj: any = {}

      const other:any = {
        types: {},
        description: 'Other'
      }

      const { propertyTypes } = state.types

      if (propertyTypes && propertyTypes.length) {
        propertyTypes.forEach(({ description, id, types }) => {
          if (!types) {
            other.types[id] = description
          } else {
            obj[id] = { description, types: {} }
            
            types.forEach(item => {
              obj[id].types[item.id] = item.description
            })
          }
        })
      }

      if (Object.entries(other.types).length) {
        obj['Other'] = other
      }

      return obj
    },

    firmTypes (state) {
      const obj: any = {}

      const { firmTypes } = state.types

      if (firmTypes && firmTypes.length) {
        firmTypes.forEach(({ description, id }) => {
          obj[id] = description
        })
      }

      return obj
    },

    footprintTypes (state) {
      const obj: any = {}

      const { footprintTypes } = state.types

      if (footprintTypes && footprintTypes.length) {
        footprintTypes.forEach((item: any) => {
          let { stateCode, description } = item

          const map = {
            'Connecticut': 'CT',
            'Vermont': 'VT',
            'Alabama': 'AL',
            'Hawaii': 'HI'
          }

          if (stateCode == 'District of Columbia') {
            stateCode = 'DC'
            description = 'District of Columbia'
          } else {
            if (map[description]) stateCode = map[description]
          }
          
          obj[stateCode] = description
        })
      }

      return obj
    },

    footprintIds (state) {
      const obj: any = {}

      const { footprintTypes } = state.types

      if (footprintTypes && footprintTypes.length) {
        for (let i = 0; i < footprintTypes.length; i++) {
          let { stateCode, description } = footprintTypes[i]
          const { id } = footprintTypes[i]

          const map = {
            'Connecticut': 'CT',
            'Vermont': 'VT',
            'Alabama': 'AL',
            'Hawaii': 'HI'
          }

          if (stateCode == 'District of Columbia') {
            stateCode = 'DC'
            description = 'District of Columbia'
          } else {
            if (map[description]) stateCode = map[description]
          }
          
          obj[id.toString()] = description

          for (let j = 0; j<footprintTypes[i].types.length; j++) {
            const county = footprintTypes[i].types[j]
            obj[county.id.toString()] = `${county.description}, ${footprintTypes[i].stateCode}`
          }
        }
      }

      return obj
    },

    footprintsNested (state) {
      return state.types.footprintTypes.map((item: any) => {
        let { stateCode, description } = item
        const { types } = item

        const map = {
          'Connecticut': 'CT',
          'Vermont': 'VT',
          'Alabama': 'AL',
          'Hawaii': 'HI',
          'Arkansas': 'AR'
        }

        if (stateCode == 'District of Columbia') {
          stateCode = 'DC'
          description = 'District of Columbia'
        } else {
          if (map[description]) stateCode = map[description]
        }

        return {
          stateCode,
          description,
          types
        }
      })
    },

    counties (state) {
      return []
    },

    typeNamesMap (state) {
      const obj:any = {}

      for (const key in state.types) {
        if (Array.isArray(state.types[key])) {
          state.types[key].forEach(({ description, id, types }) => {
            obj[id] = description

            if (types) {
              types.forEach(item => {
                obj[item.id] = item.description
              })
            }
          })
        }
      }

      return obj
    }
  },

  mutations: {
    [Mutations.SET_USER_PROFILE_MUTATION] (state,  user) {
      state.currentUserProfile = user;
    },

    [Mutations.SET_TYPES] (state, types) {
      state.types = types
    },

    [Mutations.SET_ADVISORS_INFO] (state, advisorsInfo) {
      state.advisorsInfo = advisorsInfo
    }
  },

  actions: {
    async [Actions.INIT_APP] ({ dispatch }) {
      const { origin } = queryString.parse(location.search)

      PostHogTracker.init(undefined)
      
      await dispatch(Actions.GET_ADVISORS_INFO)
      await dispatch(Actions.GET_TYPES)
      await dispatch(Actions.LOGGED_IN)
      
      return origin
    },

    async [Actions.LOGOUT] ({ commit }) {
      console.log('Actions.LOGOUT')
    },


    async [Actions.LOGGED_IN] ({ dispatch, commit }) {
      console.log('Actions.LOGGED_IN')
      
      const { data } = await PortalUserApi.loggedIn()

      PostHogTracker.init(data.email)
      HubspotTracker.push('identify', { email: data.email })

      await SegmentTracker.identify(data.email)

      if (data.originatorId) {
        const userRoles = getRoles() 
        if (!userRoles.includes('originator')) {
          console.log('No Originator Role detected', userRoles)

          // await dispatch(Actions.LOGOUT)
          // window.location.reload()
          // return
        }
        else {
          if (!data.termsAccepted) commit(Mutations.SHOW_MODAL, { modal: 'ModalTermsAndConditions' })
          await dispatch(Actions.GET_ORIGINATOR_SETTINGS, data.originatorId)
        }
      }

      commit(Mutations.SET_USER_PROFILE_MUTATION, data)

      return data
    },

    async [Actions.GET_USER_PROFILE] ({ commit }) {
      console.log('Actions.GET_USER_PROFILE')
      const { data } = await PortalUserApi.getProfileInfo()

      commit(Mutations.SET_USER_PROFILE_MUTATION, data)

      return data
    },

    async [Actions.UPDATE_USER_PARTIAL] ({ commit }, payload) {
      console.log('Actions.UPDATE_USER_PARTIAL', payload)
      const { data } = await PortalUserApi.updateUserPartial(payload)
      
      commit(Mutations.SET_USER_PROFILE_MUTATION, data)

      return data
    },

    async [Actions.UPDATE_USER] ({ commit }, payload) {
      console.log('Actions.UPDATE_USER', payload)

      const { data } = await PortalUserApi.updateUserProfile(payload)

      commit(Mutations.SET_USER_PROFILE_MUTATION, data)

      return data
    },

    async [Actions.GET_TYPES] ({ commit }) {
      const { data } = await TypesApi.getTypes()

      commit(Mutations.SET_TYPES, data)
    },

    async [Actions.GET_ADVISORS_INFO] ({ commit }) {
      const { data } = await ContactInfoApi.getAdvisorsInfo()

      commit(Mutations.SET_ADVISORS_INFO, data)
    }
  }
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-8 gap-2 md:gap-6" }
const _hoisted_2 = { class: "col-span-8 md:col-span-3 pt-1.5" }
const _hoisted_3 = { class: "form-label text-gray-600 text-sm m-0" }
const _hoisted_4 = { class: "col-span-8 md:col-span-5" }
const _hoisted_5 = {
  type: "button",
  class: "absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
}
const _hoisted_6 = {
  key: 0,
  class: "absolute z-10 mt-1 top-full max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
  id: "options",
  role: "listbox"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "block truncate text-xs md:text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "relative w-full max-w-xs",
        ref: "target",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "w-full rounded-md border bg-white py-2 pl-3 pr-12 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm",
          role: "combobox",
          "aria-controls": "options",
          "aria-expanded": "false",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOwner) = $event)),
          readonly: ""
        }, null, 512), [
          [_vModelText, _ctx.selectedOwner]
        ]),
        _createElementVNode("button", _hoisted_5, [
          _createVNode(_component_SelectorIcon, { class: "w-4 h-4" })
        ]),
        (_ctx.isOpened)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.advisors, (value, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-sky hover:text-white",
                  role: "option",
                  tabindex: "-1",
                  onClick: ($event: any) => (_ctx.select(value))
                }, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(value.name.replaceAll(' ', '').length ? value.name : value.email), 1)
                ], 8, _hoisted_7))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 512)
    ])
  ]))
}

  import { defineComponent, ref, onMounted, computed } from "vue"

  import {
    InboxInIcon,
    ClipboardCheckIcon,
    PresentationChartLineIcon,
    CursorClickIcon,
    ArrowRightIcon,
    AnnotationIcon
  } from "@heroicons/vue/outline"

  import {
    LocationMarkerIcon,
    TicketIcon
  } from "@heroicons/vue/solid"

  import { useStore } from "vuex"
  import { Actions } from "@/store/enums/StoreEnums"

  import { BaseSpinner } from "@/components/Base"

  import moment from 'moment'
  import axios from 'axios'

  export default defineComponent({
    name: "OriginatorDashboard",

    components: {
      InboxInIcon,
      ClipboardCheckIcon,
      PresentationChartLineIcon,
      CursorClickIcon,
      ArrowRightIcon,
      LocationMarkerIcon,
      TicketIcon,
      BaseSpinner,
      AnnotationIcon
    },

    setup () {
      const store = useStore()

      const loading = ref<boolean>(true)

      const distributions = computed(() => {
        return store.getters.originatorUserDistributions
      })

      const sortedDistributions = computed(() => {
        const obj: any = {}, active: any = [], completed: any = [], declined: any = []

        let copy = Array.from(distributions.value)
        
        copy.sort((a: any, b: any) => {
          let bDate: any = new Date(b.createdDate)
          let aDate: any = new Date(a.createdDate)
          return bDate - aDate
        }).forEach((item: any) => {
          if (['Added', 'SentMessage', 'ReceivedMessage', 'OpenedMessage', 'Viewed'].includes(item.status)) {
            active.push(item)
          } else if (item.status == 'NotInterested') {
            declined.push(item)
          } else {
            completed.push(item)
          }
        })

        if (active.length) obj.active = active
        if (completed.length) obj.completed = completed
        if (declined.length) obj.declined = declined

        return obj
      })

      onMounted(async () => {
        try {
          await store.dispatch(Actions.GET_ORIGINATOR_USER_DISTRIBUTIONS_PAGED)
        } catch (e) {
          console.log(e)
        } finally {
          loading.value = false
        }
      })

      const statusMap = {
        'Added': 'Not sent',
        'Removed': 'Removed',
        'SentMessage': 'Received',
        'ReceivedMessage': 'Received',
        'OpenedMessage': 'Received',
        'Viewed': 'Seen',
        'Interested': 'Interested',
        'Quoted': 'Provided quote',
        'NotInterested': 'Not interested',
        'UndeliverableMessage': 'Undeliverable',
        'InformationRequested': 'Information requested',
      }

      const getDate = (date) => {
        if (!date) return 'Recently'
        return moment(date).fromNow()
      }

      const getAddress = (address) => {
        if (!address) return 'No address'

        const array = address.split(', ')
        if (array.length > 7) array.length = 7

        return array.join(', ')
      }

      const stats = computed(() => {
        const responses = distributions.value.filter((item: any) => !!item.softQuote)
        
        let responseTime = 'Unknown'

        if (responses.length) {
          const responsesTimeArray = responses.map((item: any) => {
            return Math.round((new Date(item.softQuote.createdDate).getTime() - new Date(item.createdDate).getTime()) / 86400000)
          })

          const average = responsesTimeArray.reduce((a: any, b:any) => {
            return a + b
          }) / responsesTimeArray.length

          if (average < 1) responseTime = 'Less than 1 day'
          else responseTime = average < 2 ? '1 day' : `${ average } days`
        }

        return [
          {
            title: 'Requests awaiting quotes',
            subtitle: distributions.value.length,
            icon: 'InboxInIcon'
          },
          {
            title: 'Quotes sent',
            subtitle: responses.length,
            icon: 'ClipboardCheckIcon'
          },
          {
            title: 'Quotes accepted',
            subtitle: '0%',
            icon: 'PresentationChartLineIcon'
          },
          {
            title: 'Avg. response time',
            subtitle: responseTime,
            icon: 'CursorClickIcon'
          }
        ]
      })

      const notifications = computed(() => {
        return distributions.value.map((item: any) => {
          return {
            date: getDate(item.modifiedDate),
            name: item.distributionDashboardOfferingMemorandum.headline,
            route: `/offering-memorandum/${item.distributionDashboardOfferingMemorandum.id}/distribution/${item.id}/share-code/${item.shareCode}`
          }
        }).reverse()
      })

      const labelsMap = {
        active: {
          title: 'Awaiting response',
          subtitle: 'These are loan opportunities you’ve been sent that are awaiting your feedback. Click on the deal to review it, provide feedback, quote it, or reject it'
        },
        completed: {
          title: 'In Review',
          subtitle: 'Thank you for quoting this! Our team is working with the borrower on providing feedback and choosing a path forward'
        },
        declined: {
          title: 'Archived',
          subtitle: 'These are deals you turned down or that closed with another lender'
        }
      }

      const showArchive = ref<boolean>(false)

      const onSectionClick = (key) => {
        if (key == 'declined') showArchive.value = !showArchive.value
      }

      return {
        stats,
        statusMap,
        loading,
        distributions,
        sortedDistributions,
        getDate,
        getAddress,
        notifications,
        labelsMap,
        showArchive,
        onSectionClick
      }
    }
  })


  import { defineComponent } from "vue"

  import { LockClosedIcon, ArrowNarrowRightIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "SignupWelcomeStep",

    components: {
      LockClosedIcon,
      ArrowNarrowRightIcon
    },

    emits: ['nextStep']
  })

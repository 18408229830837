
  import { defineComponent, ref, toRefs, onMounted, computed, watch } from "vue"
  import { useStore } from "vuex"
  import { useRoute } from "vue-router"

  import { Actions } from "@/store/enums/StoreEnums"

  import { XIcon, UserAddIcon, EyeIcon, PencilIcon, BanIcon, ShieldCheckIcon } from "@heroicons/vue/solid"
  import { BaseTag, BaseSpinner, BaseRating } from "@/components/Base"
  import DataTable from "@/components/DataTable/index.vue"

  import getTags from "@/core/helpers/getTags"
  import { convertToCurrency, convertToShortCurrency } from "@/core/helpers/convertToCurrency"
  import { OriginatorGridConfig } from "@/core/config"

  import { useModals } from "@/composables"

  import { marked } from "marked"
  import * as DOMPurify from 'dompurify'

  import moment from "moment"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: 'ModalLender',

    emits: ['closed'],

    props: {
      lender: {
        type: Object,
        default () {
          return {}
        }
      }
    },

    methods: {
      moment (date) {
        return moment(date).fromNow()
      }
    },

    components: {
      XIcon,
      UserAddIcon,
      BaseRating,
      BaseSpinner,
      DataTable,
      // EyeIcon,
      PencilIcon,
      BanIcon,
      ShieldCheckIcon
    },

    setup(props, { emit }) {
      const route = useRoute()
      const store = useStore()

      const loading = ref(true)

      const { closeModal, showModal, showNotification } = useModals()

      const { lender } = toRefs<any>(props)

      const localLender = ref<any>(lender.value)

      const storeLender = computed(() => {
        return store.getters.lender
      })

      const originators = ref<Array<any>>([])

      const storeOriginator = computed(() => store.getters.originator)

      const getOriginators = async () => {
        try {
          loading.value = true

          const { data } = await store.dispatch(Actions.GET_ORIGINATORS, {
            count: 100,
            page: 1,
            sortField: 'created',
            sortDirection: 'desc',
            filters: {
              lenderId: lender.value.id,
              showDeleted: true
            }
          })

          originators.value = data

          loading.value = false
        } catch (error: any) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
        }
      }

      watch(storeOriginator, async () => {
        if (originators.value.map((item:any) => item.id).includes(storeOriginator.value.id)) {
          await getOriginators()
          
          localLender.value = await store.dispatch(Actions.GET_LENDER, {
            id: localLender.value.id
          })    
        }
      }, { deep: true })

      watch(storeLender, async (newValue) => {
        if (localLender.value.id == newValue.id) {
          localLender.value = newValue

          if (newValue.creditBox?.amountRange) {
            const { minimumAmount, maximumAmount } = newValue.creditBox.amountRange
            localLender.value.creditBox.minimumAmount = minimumAmount
            localLender.value.creditBox.maximumAmount = maximumAmount
          }

          getOriginators()

          if (route.name == 'lenders') await store.dispatch(Actions.GET_LENDERS)
        }
      }, { deep: true })

      const handleNewOriginator = async () => {
        await getOriginators()
      }

      const handleLenderUpdate = async (newLener) => {
        localLender.value = newLener
      }

      onMounted(async () => {
        await getOriginators()
      })

      const typeNamesMap = computed(() => {
        return store.getters.typeNamesMap
      })

      const categories = ref<any>({
        loanAmount: {
          title: 'Loan amounts',
          subtitle: 'Minimum and maximum loan amounts',
          model: 'amountRange'
        },
        firmType: {
          title: 'Firm types',
          subtitle: 'Type of business',
          model: 'firmTypes',
          items: 9
        },
        executionType: {
          title: 'Execution types',
          subtitle: 'Types of commercial property loans',
          model: 'executionTypes',
          items: 9
        },
        propertyType: {
          title: 'Property types',
          subtitle: 'Types of property',
          model: 'propertyTypes',
          items: 9
        },
        footprints: {
          title: 'Footprint',
          subtitle: 'States to lend in',
          model: 'footprints',
          items: 9
        },
      })

      onMounted(() => {
        if (lender.value.description && !userHasRole(RoleType.BROKER)) {
          categories.value.description = {
            title: 'Description & Notes',
            model: 'description'
          }
        }
      })

      const parseMarkdown = (value) => {
        return DOMPurify.sanitize(marked.parse(value))
      }

      return {
        closeModal,
        showModal,
        getTags,
        loading,
        originators,
        handleNewOriginator,
        handleLenderUpdate,
        tableHeader: OriginatorGridConfig.filter((item: any) => item.key !== 'lender'),
        // filteredOriginators,
        convertToCurrency,
        getOriginators,
        localLender,
        categories,
        typeNamesMap,
        convertToShortCurrency,
        parseMarkdown,
        userHasRole,
        RoleType
      }
    }
  })

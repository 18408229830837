import numeral from "numeral";

export function convertToCurrency(value): string {
	if (!value) return value
	if (value == 'Protected') value = Math.floor(Math.random() * Math.floor(1350)) * 1000
	value = value.toString().replace(/[^\d]/g,'')
	return value ? numeral(value).format('0,0') : ''
}

export function converToNumber (value): number {
	return Number(value?.toString().replace(/[^\d]/g,''))
}

export function convertToShortCurrency (value): string {
	if (!value) return ''
	value = value.toString().replace(/[^\d]/g,'')
	value = value ? numeral(value).format('0.0a') : ''
	return value.replace('.0k', 'k').replace('.0m', 'm').replace('.0b', 'b').replace('.0t', 't')
}

  import { defineComponent, ref, computed } from "vue"

  import Contacts from "./Contacts.vue"
  import Terms from "./Terms.vue"
  import LoanSettings from "./LoanSettings.vue"

  import { AnnotationIcon } from "@heroicons/vue/outline"

  import store from "@/store"

  export default defineComponent({
    name: "OriginatorSettings",

    components: {
      Contacts,
      Terms,
      LoanSettings,
      AnnotationIcon
    },

    setup () {
      const termsAccepted = computed(() => {
        return store.getters.termsAccepted
      })

      const goTo = (key) => {
        document.querySelector(key).scrollIntoView({
          behavior: "smooth"
        })
      }

      return {
        termsAccepted,
        goTo
      }
    }
  })


  import { defineComponent, ref, toRefs, computed, watch } from "vue"

  import { EyeOffIcon, EyeIcon, SelectorIcon, XIcon } from "@heroicons/vue/solid"
  import { CloudUploadIcon } from "@heroicons/vue/outline"

  import { convertToCurrency, converToNumber } from "@/core/helpers/convertToCurrency.ts"

  import { onClickOutside } from '@vueuse/core'

  import { useStore } from "vuex"
  import { Actions } from "@/store/enums/StoreEnums"

  import Wysiwig from "@/components/Wysiwig/index.vue"

  export default defineComponent({
    name: "EditorField",

    props: [
      "label",
      "value",
      "type",
      "appearance",
      "options",
      "visibilityControl",
      "isVisible",
      "key",
      "name",
      "disabled",
      "placeholder"
    ],

    emits: ['updated'],

    components: {
      EyeOffIcon,
      EyeIcon,
      SelectorIcon,
      XIcon,
      Wysiwig,
      CloudUploadIcon
    },

    setup (props, { emit }) {
      let debounce: any = null
      
      const model = ref(props.value)
      const isVisibleModel = ref(!!props.isVisible)
      const visibilityControl = ref(!!props.visibilityControl)

      const { appearance, label, type, name } = toRefs(props)

      const preview = ref<any>(null)

      const isValid = computed(() => {
        if (!model.value) {
          if (label.value == 'Heading') return false
          if (visibilityControl.value) return !isVisibleModel.value
          else return true
        }

        if (type.value == 'numeric' && appearance.value !== 'currency') return /^[0-9]*$/.test(model.value)
        
        return true
      })

      const commitChange = (value = model.value) => {
        if (isValid.value) {
          emit('updated', {
            name: name.value,
            value,
            isVisible: isVisibleModel.value,
            type: 'text'
          })
        }
      }

      watch(model, (newValue, oldValue) => {
        if (appearance.value == 'currency') model.value = convertToCurrency(newValue)
        
        clearTimeout(debounce)
        debounce = setTimeout(commitChange, 500)
      })

      const changeVisibility = (value) => {
        isVisibleModel.value = value

        commitChange()
      }

      const isOpened = ref<boolean>(false)
      const target = ref<any>(null)
      const search = ref<string>('')

      onClickOutside(target, () => {
        isOpened.value = false
        search.value = ''
      })

      const updateValue = (value) => {
        model.value = value

        commitChange()
      }

      const toggle = () => {
        if (isOpened.value) {
          isOpened.value = false
          search.value = ''
        } else {
          isOpened.value = true
        }
      }

      const saveAddress = (e) => {
        model.value = e.formatted_address
      }

      const file = ref('')

      const handleImage = async (e) => {
        if (e.target?.files.length) {
          file.value = e.target.files[0]

          // preview.value = URL.createObjectURL(e.target.files[0])

          emit('updated', { type: 'image', file: e.target.files[0] })
        }
      }
      
      const removeImage = () => { // @seva it doesn't remove it from the page but it removes it from the store, pls fix below
        model.value = []
        emit('updated', { type: 'image', file: null })
      }

      const removeFile = () => {
        file.value = ""
        emit('updated', { type: 'image', file: null })
      }

      return {
        model,
        isValid,
        isVisibleModel,
        changeVisibility,
        search,
        target,
        updateValue,
        isOpened,
        toggle,
        saveAddress,
        handleImage,
        removeImage,
        file,
        removeFile,
        preview
      }
    }
  })

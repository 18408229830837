export default [
  {
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5194"
    },
    "Category": "FHA/HUD",
    "DisplayName": "Hud 221(D)(4)",
    "Name": "HUD221d4"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5196"
    },
    "Category": "",
    "DisplayName": "Balance Sheet",
    "Name": "BalanceSheet"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f518d"
    },
    "Category": "SBA",
    "DisplayName": "Sba 7(A)",
    "Name": "SBA7a"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5195"
    },
    "Category": "FHA/HUD",
    "DisplayName": "Hud 223F",
    "Name": "HUD223f"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f519e"
    },
    "Category": "",
    "DisplayName": "Construction",
    "Name": "Construction"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5190"
    },
    "Category": "Fannie Mae Multifamily",
    "DisplayName": "Fannie Dus",
    "Name": "FannieDUS"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5198"
    },
    "Category": "",
    "DisplayName": "Cmbs",
    "Name": "CMBS"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f518e"
    },
    "Category": "SBA",
    "DisplayName": "Sba 504",
    "Name": "SBA504"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5197"
    },
    "Category": "",
    "DisplayName": "Hard Money",
    "Name": "HardMoney"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5199"
    },
    "Category": "",
    "DisplayName": "Non Bank Balance Sheet",
    "Name": "NonBankBalanceSheet"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f51a3"
    },
    "Category": "",
    "DisplayName": "Sale/Leaseback Financing",
    "Name": "SaleOrLeaseback"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f519c"
    },
    "Category": "",
    "DisplayName": "Mezzanine And Pref Equity",
    "Name": "MezzanineAndPrefEquity"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f519d"
    },
    "Category": "",
    "DisplayName": "Lp/Gp Equity",
    "Name": "LPGPEquity"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f518f"
    },
    "Category": "Fannie Mae Multifamily",
    "DisplayName": "Fannie Small",
    "Name": "FannieSmall"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5193"
    },
    "Category": "FHA/HUD",
    "DisplayName": "Hud 232",
    "Name": "HUD232"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f5192"
    },
    "Category": "Freddie Mac Multifamily",
    "DisplayName": "Optigo (Freddie Small)",
    "Name": "Optigo"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f519a"
    },
    "Category": "",
    "DisplayName": "Bridge/Transitional",
    "Name": "Bridge"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f51a0"
    },
    "Category": "",
    "DisplayName": "Lines Of Credit",
    "Name": "LinesOfCredit"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f519f"
    },
    "Category": "",
    "DisplayName": "B-Note",
    "Name": "BNote"
  },{
    "_id": {
      "$oid": "62a0494b1cc2a214b02f51a2"
    },
    "Category": "",
    "DisplayName": "Low-Income Housing Tax Credits",
    "Name": "LowIncomeHousingTaxCredits"
  }
]
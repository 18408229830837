
  import { defineComponent } from "vue"

  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

  interface IHeaderConfiguration {
    name?: string;
    key: string;
    sortingField?: string;
    sortable?: boolean;
  }

  export default defineComponent({
    name: "DataTable",
    
    props: {
      tableHeader: {
        type: Array as () => Array<IHeaderConfiguration>,
        required: true,
      },
      tableData: { type: Array, required: true },
      emptyTableText: { type: String, default: "No data found" },
      loading: { type: Boolean, default: false },
      minHeight: { type: Boolean, default: false },
      showCheckbox: { type: Boolean, default: false },
      isAllSelected: { type: Boolean, default: false }
    },

    emits: ['bulkSelect'],
    
    components: {}
  });

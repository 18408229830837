
  import { defineComponent, ref, toRefs, onMounted, computed, watch } from "vue"
  import { useRoute } from "vue-router"
  
  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"
  import { PostHogTracker, HubspotTracker } from "@/core/services/trackers"

  import { useModals } from "@/composables"

  export default defineComponent({
    name: 'QuoteMoreInfo',

    props: ['om'],

    emits: ['submitted'],

    setup (props, { emit }) {
      const route = useRoute()

      const { om } = toRefs(props)

      const { showNotification } = useModals()

      const collectedData = ref<any>({
        comment: ''
      })

      const handleFormSubmit = async () => {
        const payload = {
          "type": "InformationRequest",
          "comment": collectedData.value.comment,
          "offeringMemorandumId": om.value.id,
          "originatorId": store.getters.getCurrentUserProfile.originatorId,
          "distributionId": route.params.distributionId,
        }

        try {
          const response = await store.dispatch(Actions.REJECT_QUOTE, payload)

          PostHogTracker.capture('OM – More Info Request', payload)
          HubspotTracker.push('trackEvent', {
            id: 'IQ3 – More Info Request',
          })
          
          emit('submitted')
        } catch (error) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
        }
      }

      return {
        collectedData,
        handleFormSubmit
      }
    }
  })

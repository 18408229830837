
  import {
    defineComponent,
    computed,
    ref,
    watch,
  } from "vue"
  
  import { useStore } from "vuex"

  import { BaseMenuToggle, Select } from "@/components/Base"

  import {
    FilterIcon,
    XIcon,
    SearchIcon
  } from "@heroicons/vue/solid"

  import { Actions } from "@/store/enums/StoreEnums"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: 'DealsFilter',
    
    components: {
      BaseMenuToggle,
      FilterIcon,
      XIcon,
      BaseSelect: Select,
      SearchIcon
    },

    props: {
      blocked: {
        type: Boolean,
        default () {
          return false
        }
      }
    },

    setup (props) {
      const store = useStore()

      const debounce = ref<any>(null)

      const filterMetadata = {
        type: 'Deal type',
        loanPurpose: 'Loan purpose',
        propertyType: 'Property type'
      }

      if (userHasRole(RoleType.ADVISOR)) {
        filterMetadata['hasOfferingMemorandum'] = 'Has OM'
        filterMetadata['distributeTo'] = 'Distributed to'
      }

      const paging = computed(() => {
        return store.getters.paging
      })

      const sortMetadata = computed(() => {
        return store.getters.dealFilterMetadata?.sortFields || {}
      })

      const filtersData = computed(() => {
        return store.getters.filter
      })

      const onFilterChange = async (filter, event) => {
        let filterValue: any = event
        
        if (Array.isArray(event)) {
          filterValue = new Set(event)
        } else if (event?.target?.value) {
          filterValue = event.target.value
        }

        await store.dispatch(Actions.UPDATE_DEALS_FILTER, {
          [filter]: filterValue
        })
      }

      const removeFilterValue = async (key, value) => {
        let filter = new Set(filtersData.value[key])

        filter.delete(value)

        await store.dispatch(Actions.UPDATE_DEALS_FILTER, {
          [key]: filter
        })
      }

      const setSortDirection = async (event) => {        
        await store.dispatch(Actions.UPDATE_DEALS_SORTING, {
          sortDirection: event.target.value
        })
      }

      const setSortField = async (event) => {        
        await store.dispatch(Actions.UPDATE_DEALS_SORTING, {
          sortField: event.target.value
        })
      }

      const accountType = computed(() => {
        if (userHasRole(RoleType.ADVISOR)) return 'advisor'
        if (userHasRole(RoleType.ORIGINATOR)) return 'originator'
        return ''
      })

      const appliedFilters = computed(() => {        
        return Object.entries(store.getters.filter).filter((item: any) => {
          if (accountType.value == 'originator' && item[0] == 'status') return false
          if (accountType.value == 'originator' && item[0] == 'CreatedDateMin') return false
          if (item[1] instanceof Set) return !!item[1].size
          return !!item[1]
        }).map(item => {
          return {
            key: item[0],
            value: item[1]
          }
        })
      })

      const searchQuery = ref('')

      watch(searchQuery, () => {
        clearTimeout(debounce.value)

        debounce.value = setTimeout(async () => {      
          await store.dispatch(Actions.UPDATE_DEALS_FILTER, {
            query: searchQuery.value
          })
        }, 1000)
      })

      const displayedValuesMap = {
        propertyType: ['Multifamily 5+ Units', 'Office', 'Retail', 'Hospitality', 'Industrial', '1-4 Unit Residential', 'Self Storage', 'Mobile Home Park'],
        loanPurpose: ['Purchase', 'Refinance', 'Construction'],
        distributeTo: ['Brad Beattie', 'Brandon', 'Brian Hamada', 'Bryan Rippe', 'Joe', 'Joe Nolan', 'Tyler Pepper', 'Sean Snyder'],
        hasOfferingMemorandum: ['Yes', 'No'],
        type: ['Investment Property', 'Business']
      }

      const clearFilters = async () => {
        await store.dispatch(Actions.UPDATE_DEALS_FILTER, {})
      }

      return {
        filterMetadata,
        filtersData,
        appliedFilters,
        onFilterChange,
        removeFilterValue,
        sortMetadata,
        paging,
        setSortDirection,
        setSortField,
        searchQuery,
        displayedValuesMap,
        clearFilters
      }
    }
  })

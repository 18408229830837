
  import { defineComponent, ref, onMounted } from "vue"

  import { CheckCircleIcon, XIcon, ExclamationCircleIcon } from "@heroicons/vue/outline"

  export default defineComponent({
    name: "Notification",

    props: ['text', 'type'],

    components: {
      CheckCircleIcon,
      XIcon,
      ExclamationCircleIcon
    },

    setup () {
      const isVisible = ref(true)

      onMounted(() => {
        setTimeout(() => {
          isVisible.value = false
        }, 5000)
      })

      return {
        isVisible
      }
    }
  })

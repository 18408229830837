import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1e6719af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-wrap w-full h-96" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GMapMap, {
      center: _ctx.center,
      zoom: 17,
      style: {"height":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_GMapMarker, { position: _ctx.center }, null, 8, ["position"])
      ]),
      _: 1
    }, 8, ["center"])
  ]))
}
export default {
  fields: {
    'headline': {
      name: "headline",
      label: "Heading",
      type: "text",
      value: ''
    },
    'pictures': {
      name: "pictures",
      label: "Pictures",
      type: "gallery-uploader",
      value: []
    },
    'narrative': {
      name: "narrative",
      label: "Narrative / Summary",
      type: "wysiwig",
      value: ''
    },
    'investmentHighlights': {
      name: "investmentHighlights",
      label: "Deal highlights",
      type: "textarea",
      value: '',
      placeholder: 'Please use 3-4 words per line:\nGreat LTV for this town\nGreat location'
    }
  }
}
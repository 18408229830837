import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg shadow w-64" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-md font-bold" }
const _hoisted_5 = { class: "text-xs text-gray-500" }
const _hoisted_6 = { class: "space-y-2 py-4 px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex items-center justify-between border-b p-4 px-6 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showProfile(_ctx.feedback.originator.id)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.feedback.lender.logoUri)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.feedback.lender.logoUri,
              class: "max-h-8 mr-2"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.feedback.lender.name), 1),
          _createElementVNode("div", _hoisted_5, " by " + _toDisplayString(_ctx.feedback.originator.name), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", null, _toDisplayString(_ctx.feedback.comment), 1)
    ])
  ]))
}

  import { defineComponent } from 'vue';

  import { UserIcon, OfficeBuildingIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    name: 'OriginatorName',

    components: {
      UserIcon, OfficeBuildingIcon
    },

    props: ['lender', 'originator']
  })


  import { defineComponent, computed } from 'vue';

  import { FolderOpenIcon } from '@heroicons/vue/outline'

  import store from '@/store'

  export default defineComponent({
    name: 'OriginatorActivity',

    components: {
      FolderOpenIcon
    },

    setup () {
      const deals = computed(() => store.getters.publicClosedDeals)

      return {
        deals
      }
    }
  })

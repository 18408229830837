
  import { defineComponent, computed } from 'vue'

  import store from '@/store'
  import numeral from 'numeral'

  export default defineComponent({
    name: 'OriginatorHighlight',

    setup () {
      const originator = computed(() => store.getters.publicOriginator)
      const softQuotes = computed(() => store.getters.publicSoftQuotes)

      const loanAmount = computed(() => {
        if (!softQuotes.value?.length) return 0

        const total = softQuotes.value.reduce((sum, quote) => sum + quote.LoanAmount, 0);
        return numeral(total / softQuotes.value.length).format('$0.0a');
      })

      const LTV = computed(() => {
        if (!softQuotes.value?.length) return 0

        const total = softQuotes.value.reduce((sum, quote) => sum + quote.MaxLTV, 0);
        return (total / softQuotes.value.length).toFixed(0);
      })

      return {
        originator,
        softQuotes,
        loanAmount,
        LTV
      }
    }
  })


  import { defineComponent, ref, computed, onMounted } from "vue"
  import { useRoute } from "vue-router"

  import { CheckIcon, EyeIcon, ChevronDownIcon } from "@heroicons/vue/solid"
  import { CollectionIcon, SaveIcon, PencilIcon } from "@heroicons/vue/outline"
  import { LendersMatchingPlain } from "@/components/Lenders"
  import { BaseMenuToggle } from "@/components/Base"

  import { useDealData, useModals, useShortLists } from "@/composables"

  export default defineComponent({
    name: "ShortList",

    components: {
      CheckIcon,
      LendersMatchingPlain,
      EyeIcon,
      BaseMenuToggle,
      CollectionIcon,
      SaveIcon,
      PencilIcon,
      ChevronDownIcon
    },

    setup () {
      const route = useRoute()
      
      const id = ref(route.params.id)
      const { deal, om, distributions } = useDealData()
      const { templates, getTemplates } = useShortLists()
      const { showModal } = useModals()

      onMounted(async () => await getTemplates())

      const menu = computed(() => {
        return [
          {
            key: 'export',
            label: 'Save shortlist',
            icon: 'SaveIcon',
            modal: 'ModalExportList',
            disabled: !distributions.value.length
          },
          {
            key: 'import',
            label: 'Select shortlist',
            icon: 'CollectionIcon',
            modal: 'ModalImportList',
            disabled: !templates.value.length
          },
          {
            key: 'manage',
            label: 'Manage shortlists',
            icon: 'PencilIcon',
            modal: 'ModalManageShortLists',
            disabled: !templates.value.length
          }
        ]
      })

      const clearOriginatorsList = () => {
        showModal('ModalClearShortlistConfirmation')
      }

      return {
        deal,
        om,
        distributions,
        showModal,
        clearOriginatorsList,
        menu
      }
    }
  })

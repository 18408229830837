export default {
  'Bank': 'Bank',
  'CMBSLender': 'CMBS Lender',
  'CreditUnion': 'Credit Union',
  'BalanceSheetLender': 'Balance Sheet Lender',
  'FamilyOffice': 'Family Office',
  'InvestmentBank': 'Investment Bank',
  'LifeCompany': 'Life Company',
  'PensionFund': 'Pension Fund',
  'REIT': 'REIT',
  'NonBankSBALender': 'Non Bank SBA Lender',
  'DebtFund': 'Debt Fund',
  'Fintech': 'Fintech',
  'Mortgage Broker': 'MortgageBroker'
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "px-4 md:px-6 space-y-3" }
const _hoisted_2 = { class: "col-span-8 md:col-span-3 pt-2" }
const _hoisted_3 = { class: "form-label text-gray-600 text-sm m-0" }
const _hoisted_4 = { class: "col-span-8 md:col-span-5" }
const _hoisted_5 = { class: "border divide-y rounded-md" }
const _hoisted_6 = { class: "flex items-center justify-start" }
const _hoisted_7 = { class: "block text-gray-700 text-xs" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "flex items-center space-x-2" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EyeOffIcon = _resolveComponent("EyeOffIcon")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_DownloadIcon = _resolveComponent("DownloadIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, key) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "grid grid-cols-8 gap-2 md:gap-6",
        key: key
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(key), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (doc, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["py-2 px-3 flex items-center justify-between", { 'opacity-50 pointer-events-none': _ctx.removedDocuments.includes(doc.documentId) }])
              }, [
                _createElementVNode("div", _hoisted_6, [
                  (!doc.isVisible)
                    ? (_openBlock(), _createBlock(_component_EyeOffIcon, {
                        key: 0,
                        class: "w-4 h-4 text-indigo-200 mr-2 cursor-pointer",
                        onClick: ($event: any) => (_ctx.changeVisibility(doc.documentId || index, !doc.isVisible))
                      }, null, 8, ["onClick"]))
                    : (_openBlock(), _createBlock(_component_EyeIcon, {
                        key: 1,
                        class: "w-4 h-4 text-indigo-500 mr-2 cursor-pointer",
                        onClick: ($event: any) => (_ctx.changeVisibility(doc.documentId || index, !doc.isVisible))
                      }, null, 8, ["onClick"])),
                  _createElementVNode("span", _hoisted_7, [
                    _createTextVNode(_toDisplayString(key) + " ", 1),
                    (group.length > 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, "| Attachment #" + _toDisplayString(index+1), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("a", {
                    href: doc.value,
                    download: "",
                    class: "block text-sm text-sky"
                  }, [
                    _createVNode(_component_DownloadIcon, { class: "w-4 h-4" })
                  ], 8, _hoisted_10),
                  (doc.documentId)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: "#remove-file",
                        class: "block text-sm",
                        onClick: _withModifiers(($event: any) => (_ctx.removeDocument(doc.documentId)), ["prevent"])
                      }, [
                        _createVNode(_component_TrashIcon, { class: "w-4 h-4 text-red-700" })
                      ], 8, _hoisted_11))
                    : _createCommentVNode("", true)
                ])
              ], 2))
            }), 128))
          ])
        ])
      ]))
    }), 128))
  ]))
}
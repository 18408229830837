
  import { defineComponent, computed, ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { OriginatorsPublicApi } from "@/core/services/apis"
  import { BaseSpinner } from '@/components/Base'

  import {
    OriginatorName,
    OriginatorBio,
    OriginatorCtaWithOptions,
    OriginatorPreferences,
    OriginatorActivity,
    OriginatorLenderCta
  } from './components'

  import { LightBulbIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    name: 'OriginatorPublicProfile',

    components: {
      OriginatorName,
      OriginatorBio,
      OriginatorCtaWithOptions,
      OriginatorPreferences,
      OriginatorActivity,
      OriginatorLenderCta,
      BaseSpinner,
      LightBulbIcon
    },

    setup () {
      const originatorId = '11220000-4800-acde-fb52-08dab6bf5765'
      const route = useRoute()

      const originator = ref<any>(null)
      const lender = ref<any>(null)
      const softQuotes = ref<any>([])
      const deals = ref<any>([])

      onMounted(async () => {
        const { data } = await OriginatorsPublicApi.getOriginator(originatorId)
        originator.value = data.originator
        lender.value = data.lender
        softQuotes.value = data.softQuotes
        deals.value = data.deals
      })

      return {
        originator,
        lender,
        softQuotes,
        deals
      }
    }
  })

export enum LenderActions {
    GET_LENDERS = "getLenders",
    GET_PAGED_LENDERS = "getPagedLenders",
    GET_LENDERS_FILTER = "getLendersFilter",
    GET_LENDER = "getLender",
    CREATE_LENDER = "createLender",
    UPDATE_LENDER = "updateLender",
    UPDATE_LENDERS_FILTER = "updateLendersFilter",
    CLEAR_LENDERS_FILTER = "clearLendersFilter",
    UPDATE_LENDERS_SORTING = "updateLendersSorting"
}
export enum LenderMutations {
    SET_LENDERS_MUTATION = "setLenders",
    SET_LENDER_FILTER_METADATA = "setLenderFilterMetadata",
    SET_LENDER_MUTATION = "setLender",
    SET_LENDER_PAGING = "setLendersPaging",
    SET_LENDER_FILTER_VALUE = "setLendersFilterValue"
}

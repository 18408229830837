import ApiClient from "@/core/services/ApiClient";
import ApiResponse from "../helpers/ApiResponse";

const basePath = "originator";

class OriginatorsPublicApi {
  public static async getOriginator(
    id: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getFromNode<any>(`${basePath}/${id}`);
  }

  public static async getConversation(
    id: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getFromNode<any>(`conversation/${id}`)
  }
}

export default OriginatorsPublicApi;

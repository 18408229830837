
  import { defineComponent } from 'vue'

  import moment from 'moment'

  import store from "@/store"
  import { Actions, Mutations } from "@/store/enums/StoreEnums"

  export default defineComponent({
    name: 'Feedback',

    props: ['feedback'],

    methods: {
      moment (date) {
        return moment(date).fromNow();
      },

      async showProfile (originatorId) {
        const originator = await store.dispatch(Actions.GET_ORIGINATOR, { id: originatorId })
        store.commit(Mutations.SHOW_MODAL, { modal: 'ModalOriginator', data: { originator } })
      }
    }
  })

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-start gap-4" }
const _hoisted_2 = { class: "rounded-full" }
const _hoisted_3 = { class: "h-16 w-16 rounded-full bg-gray-100 overflow-hidden flex items-center justify-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-3xl font-bold tracking-tight text-gray-900" }
const _hoisted_6 = { class: "text-md leading-6 text-gray-600 flex gap-1 items-center font-medium" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserIcon = _resolveComponent("UserIcon")!
  const _component_OfficeBuildingIcon = _resolveComponent("OfficeBuildingIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_UserIcon, { class: "h-8 w-8 text-gray-300" }),
        _createElementVNode("img", {
          src: _ctx.originator.Bio
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.originator.Name), 1),
      _createElementVNode("p", _hoisted_6, [
        _createVNode(_component_OfficeBuildingIcon, { class: "inline-block h-4 w-4 text-gray-500 mr-1" }),
        _createElementVNode("a", {
          href: _ctx.lender.LenderUri,
          target: "_blank"
        }, _toDisplayString(_ctx.lender.Name), 9, _hoisted_7)
      ])
    ])
  ]))
}
import { computed, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute } from "vue-router"

import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { convertToShortCurrency } from "@/core/helpers/convertToCurrency"

import { useModals } from "@/composables"

export default function useDealData () {
  const route = useRoute()
  const store = useStore()

  const { showNotification, closeModal } = useModals()
    
  const loading = ref(true)
  const id = route.params.id
      
  const deal = computed(() => store.getters.deal)
  const om = computed(() => store.getters.om)
  const questions = computed(() => store.getters.questions)

  const dealExists = computed(() => {
    return !!Object.values(deal.value).length
  })

  const questionsAsObject = computed(() => {
    return Object.fromEntries(questions.value.filter((item: any) => {
      return !item.isDuplicate
    }).map((item: any) => {
      return [item.name, item]
    }))
  })

  const getDealData = async () => {
    try {
      store.commit(Mutations.SET_DEAL_MUTATION, {})
      store.commit(Mutations.SET_OM_MUTATION, {})
      store.commit(Mutations.SET_DISTRIBUTIONS, [])
      await store.dispatch(Actions.GET_FIELDS)
      await store.dispatch(Actions.GET_DEAL, { id })
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const getOM = async () => {
    if (deal.value && deal.value.offeringMemorandumId) return await store.dispatch(Actions.GET_OM)
    return
  }

  const createOM = async (payload) => {
    return await store.dispatch(Actions.CREATE_OM, payload)
  }

  const updateOM = async (payload) => {
    return await store.dispatch(Actions.UPDATE_OM, payload)
  }

  const name = computed(() => {
    if (!dealExists.value) return "Loading..."
    if (om.value.headline) return om.value.headline

    let name = '' // {loan amount} {property type} {loan purpose} in {city, state}

    if (deal.value.data['Loan amount']) name += `$${convertToShortCurrency(deal.value.data['Loan amount'])}`

    if (deal.value.data['Property type']) {
      if (name.length) name += ' '
      name += deal.value.data['Property type']
    }

    if (deal.value.data['Loan purpose']) {
      if (name.length) name += ' '
      name += deal.value.data['Loan purpose']
    }

    if (deal.value.data['Primary Loan Purpose']) {
      if (name.length) name += ' '
      name += deal.value.data['Primary Loan Purpose']
    }

    if (deal.value.data['Property address'] && deal.value.data['Property address']['locality']) {
      if (name.length) name += ' '
      name += `in ${deal.value.data['Property address']['locality']}, ${deal.value.data['Property address']['administrative_area_level_1']}`
    }
    
    return name
  })

  const customerName = computed(() => {
    let firstName = '', lastName = ''
    
    if (dealExists.value) {
      const fullName = deal.value?.customerData.fullName.split(' ')
      
      firstName = fullName.splice(0, fullName.length - 1).join(' ')
      lastName = fullName[fullName.length - 1]
    }

    return { firstName, lastName }
  })

  const distributions = computed(() => {
    return store.getters.distributions
  })

  const updatePicture = async (id, file) => {
    const formData = new FormData()
              
    formData.append('file', file)
    formData.append('name', 'om-image')
    formData.append('Name', file.name)
    formData.append('displayName', file.name)
    formData.append('DisplayName', file.name)
    formData.append('fileName', file.name)
    formData.append('tags', '["om-image"]')
    formData.append('description', 'om-image')
    formData.append('metadata', '{}')

    return await store.dispatch(Actions.UPLOAD_PICTURE, { id, formData })
  }

  const uploadDocument = async (id, name, file, isVisible) => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('name', name)
    formData.append('fileName', file.name)
    formData.append('displayName', name)
    formData.append('tags', '["om-document"]')
    formData.append('description', name)
    formData.append('metadata', '{}')
    formData.append('isVisible', isVisible)

    return await store.dispatch(Actions.UPLOAD_DOCUMENT, { id, formData })
  }

  const deleteDocument = async (offeringMemorandumId, documentId) => {
    return await store.dispatch(Actions.DELETE_DOCUMENT, {
      offeringMemorandumId,
      documentId
    })
  }

  const removePicture = async (offeringMemorandumId, pictureId) => {
    return await store.dispatch(Actions.REMOVE_PICTURE, {
      offeringMemorandumId,
      pictureId
    })
  }

  const clearOriginatorsList = async () => {
    try {
      await store.dispatch(Actions.UPDATE_DISTRIBUTIONS, {
        offeringMemorandaId: om.value.id,
        originatorIds: []
      })
      showNotification('Short list was cleared')
    } catch (error: any) {
      console.log(error)
      showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
    }
  }

  const updateOriginatorsList = async (id, name) => {
    const originatorIds = new Set(distributions.value.map((item: any) => item.originator.id))

    let message = ''

    if (originatorIds.has(id)) {
      originatorIds.delete(id)
      message = `${name} removed from shortlist`
    } else {
      originatorIds.add(id)
      message = `${name} added to shortlist`
    }

    try {
      if (!distributions.value.length) {
        await store.dispatch(Actions.CREATE_DISTRIBUTIONS, {
          offeringMemorandaId: om.value.id,
          originatorIds: Array.from(originatorIds)
        })
      } else {
        await store.dispatch(Actions.UPDATE_DISTRIBUTIONS, {
          offeringMemorandaId: om.value.id,
          originatorIds: Array.from(originatorIds)
        })
      }

      showNotification(message)
    } catch (error: any) {
      console.log(error)
      showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
    }
  }

  const bulkUpdateOriginatorsList = async (template: any, merge: boolean, modal = '') => {
    const existingIds: any = distributions.value.map((item: any) => item.originator.id)
    const templateIds: any = template.originators.map((item:any) => item.id)

    const originatorIds = merge ? Array.from(
      new Set([...existingIds, ...templateIds])
    ) : templateIds

    try {
      if (!distributions.value.length) {
        await store.dispatch(Actions.CREATE_DISTRIBUTIONS, {
          offeringMemorandaId: om.value.id,
          originatorIds
        })
      } else {
        await store.dispatch(Actions.UPDATE_DISTRIBUTIONS, {
          offeringMemorandaId: om.value.id,
          originatorIds
        })
      }

      showNotification(`Originators were imported from "${template.name}"`)

      if (modal) {
        closeModal(modal)
      }
    } catch (error: any) {
      console.log(error)
      showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
    }
  }

  const updateMany = async (originatorIds) => {
    await store.dispatch(Actions.UPDATE_DISTRIBUTIONS, {
      offeringMemorandaId: om.value.id,
      originatorIds
    })
  }

  return {
    loading,
    questions,
    questionsAsObject,
    deal,
    om,
    getDealData,
    name,
    customerName,
    createOM,
    updateOM,
    distributions,
    updatePicture,
    uploadDocument,
    deleteDocument,
    removePicture,
    getOM,
    clearOriginatorsList,
    updateOriginatorsList,
    bulkUpdateOriginatorsList,
    updateMany
  }
}

  import { defineComponent, computed } from "vue"

  import store from "@/store"

  import Notification from "./Notification.vue"

  export default defineComponent({
    name: 'Notifications',

    components: {
      Notification
    },

    setup () {
      const notifications = computed(() => {
        return store.getters.notifications
      })

      return {
        notifications
      }
    }
  })

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "px-4 py-5 sm:px-4 flex md:justify-between items-center" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lenders_filter = _resolveComponent("lenders-filter")!
  const _component_base_tag = _resolveComponent("base-tag")!
  const _component_data_table = _resolveComponent("data-table")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_base_pagination = _resolveComponent("base-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_lenders_filter, {
            key: 0,
            scope: "lenders"
          }))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_data_table, {
          key: 0,
          tableData: _ctx.lenders,
          tableHeader: _ctx.tableHeader,
          minHeight: true,
          class: "rounded-tl-lg rounded-tr-lg md:rounded-none"
        }, {
          "cell-name": _withCtx(({ row: lender }) => [
            _createElementVNode("div", _hoisted_2, [
              (lender.logoUri)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: lender.logoUri,
                    class: "max-h-4 max-w-4 mr-2"
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                class: "font-medium text-gray-900 cursor-pointer",
                onClick: ($event: any) => (_ctx.showModal('ModalLender', { lender }))
              }, _toDisplayString(lender.name), 9, _hoisted_4)
            ])
          ]),
          "cell-min": _withCtx(({ row: lender }) => [
            _createElementVNode("span", {
              onClick: ($event: any) => (_ctx.showModal('ModalLender', { lender })),
              class: "text-gray-500"
            }, " $" + _toDisplayString(_ctx.convertToShortCurrency(lender.creditBox?.minimumAmount) || ' –'), 9, _hoisted_5)
          ]),
          "cell-max": _withCtx(({ row: lender }) => [
            _createElementVNode("span", {
              onClick: ($event: any) => (_ctx.showModal('ModalLender', { lender })),
              class: "text-gray-500"
            }, " $" + _toDisplayString(_ctx.convertToShortCurrency(lender.creditBox?.maximumAmount) || ' –'), 9, _hoisted_6)
          ]),
          "cell-property-type": _withCtx(({ row: lender }) => [
            _createElementVNode("div", {
              class: "overflow-hidden",
              onClick: ($event: any) => (_ctx.showModal('ModalLender', { lender }))
            }, [
              _createVNode(_component_base_tag, {
                muted: !lender.creditBox.propertyTypes?.length
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(lender.creditBox.propertyTypes?.length ? _ctx.typeNamesMap[lender.creditBox.propertyTypes[0]] : 'No data'), 1)
                ]),
                _: 2
              }, 1032, ["muted"]),
              (lender.creditBox.propertyTypes?.length > 1)
                ? (_openBlock(), _createBlock(_component_base_tag, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(" + " + _toDisplayString(lender.creditBox.propertyTypes?.length - 1), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 8, _hoisted_7)
          ]),
          "cell-firm-type": _withCtx(({ row: lender }) => [
            _createElementVNode("div", {
              class: "overflow-hidden",
              onClick: ($event: any) => (_ctx.showModal('ModalLender', { lender }))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTags(lender.firmTypes, 2), (item, index) => {
                return (_openBlock(), _createBlock(_component_base_tag, {
                  key: index,
                  muted: item == 'No data'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item), 1)
                  ]),
                  _: 2
                }, 1032, ["muted"]))
              }), 128))
            ], 8, _hoisted_8)
          ]),
          "cell-execution-type": _withCtx(({ row: lender }) => [
            _createElementVNode("div", {
              class: "overflow-hidden",
              onClick: ($event: any) => (_ctx.showModal('ModalLender', { lender }))
            }, [
              _createVNode(_component_base_tag, {
                muted: !lender.creditBox.executionTypes?.length
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(lender.creditBox.executionTypes?.length ? _ctx.typeNamesMap[lender.creditBox.executionTypes[0]] : 'No data'), 1)
                ]),
                _: 2
              }, 1032, ["muted"]),
              (lender.creditBox.executionTypes?.length > 1)
                ? (_openBlock(), _createBlock(_component_base_tag, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(" + " + _toDisplayString(lender.creditBox.executionTypes?.length - 1), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 8, _hoisted_9)
          ]),
          "cell-working-area": _withCtx(({ row: lender }) => [
            _createElementVNode("div", {
              class: "overflow-hidden",
              onClick: ($event: any) => (_ctx.showModal('ModalLender', { lender }))
            }, [
              _createVNode(_component_base_tag, {
                muted: !lender.creditBox.footprints?.length
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(lender.creditBox.footprints?.length ? _ctx.typeNamesMap[lender.creditBox.footprints[0]] : 'No data'), 1)
                ]),
                _: 2
              }, 1032, ["muted"]),
              (lender.creditBox.footprints?.length > 1)
                ? (_openBlock(), _createBlock(_component_base_tag, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(" + " + _toDisplayString(lender.creditBox.footprints?.length - 1), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 8, _hoisted_10)
          ]),
          _: 1
        }, 8, ["tableData", "tableHeader"]))
      : (_openBlock(), _createBlock(_component_base_spinner, {
          key: 1,
          class: "h-80"
        })),
    _createVNode(_component_base_pagination, {
      pagingData: _ctx.paging,
      onCurrentChange: _ctx.onPageChange
    }, null, 8, ["pagingData", "onCurrentChange"])
  ]))
}

  import { defineComponent, ref, toRefs, computed, onMounted } from "vue"

  import { useModals } from "@/composables"

  import { XIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'ModalPreviewDocument',

    components: {
      XIcon
    },

    emits: ['closed'],

    props: ['file'],

    setup(props, { emit }) {
      const { closeModal } = useModals()

      const iframeLoaded = ref<boolean>(false)
      const timeouted = ref<boolean>(false)

      const iframeLoad = (e) => {
        iframeLoaded.value = true
      }

      onMounted(() => {
        setTimeout(() => {
          timeouted.value = true
        }, 10000)
      })

      return {
        closeModal,
        iframeLoad,
        iframeLoaded,
        timeouted
      }
    }
  })


  import { defineComponent, computed } from 'vue';

  import { ArchiveIcon } from '@heroicons/vue/outline'
  import { useModals } from '@/composables'
  import OriginatorCreditBox from './OriginatorCreditBox.vue'

  export default defineComponent({
    name: 'OriginatorPreferences',

    components: {
      ArchiveIcon,
      OriginatorCreditBox
    },

    props: ['originator'],

    setup () {
      const { showModal } = useModals()

      return {
        showModal
      }
    }
  })

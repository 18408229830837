import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import store from "@/store"
import { Actions } from "@/store/enums/StoreEnums"
import { authGuard } from "@auth0/auth0-vue"

import { userHasRole } from "@/core/auth/userHelper"
import { RoleType } from "@/core/auth/permissionEnums"

import { SegmentTracker, HubspotTracker } from "@/core/services/trackers"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/components/Layout/index.vue"),
    beforeEnter: authGuard,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { hideHeader: true },
        component: () => import("@/views/Dashboard/index.vue"),
      },
      {
        path: "/deals",
        name: "deals",
        component: () => import("@/views/DealListing/index.vue"),
      },
      {
        path: "/deal/:id",
        meta: { hideHeader: true },
        name: "deal",
        component: () => import("@/views/Deal/index.vue"),
        children: [
          {
            path: "overview",
            name: "deal-overview",
            component: () => import("@/views/Deal/Overview.vue")
          },
          {
            path: "editor",
            name: "deal-editor",
            component: () => import("@/views/Deal/Editor.vue")
          },
          {
            path: "shortlist",
            name: "deal-shortlist",
            component: () => import("@/views/Deal/ShortList.vue")
          },
          {
            path: "tracker",
            name: "deal-om-tracker",
            component: () => import("@/views/Deal/Tracker.vue")
          },
          {
            path: "send-om/:distributionId",
            name: "deal-om-send",
            component: () => import("@/views/Deal/SendOM.vue")
          },
          {
            path: "quotes",
            name: "deal-quotes",
            component: () => import("@/views/Deal/Quotes.vue")
          }
        ]
      },
      {
        path: "/originator/:id",
        meta: { hideHeader: true },
        name: "originator",
        component: () => import("@/views/Originator/index.vue"),
      },
      {
        path: "/account",
        name: "account",
        meta: {
          hideHeader: true
        },
        component: () => import("@/views/Account/index.vue")
      },
      {
        path: "/lenders",
        name: "lenders",
        meta: {
          label: "Lender database"
        },
        component: () => import("@/views/LendersListing/index.vue"),
      },
      {
        path: "/originators",
        name: "originators",
        meta: {
          label: "Originator database"
        },
        component: () => import("@/views/OriginatorsListing/index.vue"),
      },
      {
        path: "/offering-memorandum/:id",
        name: "om-preview",
        meta: { hideHeader: true },
        component: () => import("@/views/OM/index.vue")
      },
      {
        path: "/offering-memorandum/:id/originator/:originatorId",
        name: "om-originator",
        meta: { hideHeader: true },
        component: () => import("@/views/OM/index.vue")
      },
      {
        path: "/borrower-dashboard",
        name: "borrower-dashboard",
        meta: { hideHeader: true },
        component: () => import("@/views/Borrower/Dashboard/index.vue")
      },
      {
        path: "/borrower-deal",
        name: 'borrower-deal',
        meta: { hideHeader: true },
        component: () => import("@/views/Borrower/Deal/index.vue")
      },
      {
        path: "/originator-settings",
        name: "originator-settings",
        meta: { hideHeader: true },
        component: () => import("@/views/Originator/Settings/index.vue")
      },
      {
        path: "/originator-dashboard",
        name: "originator-dashboard",
        meta: { hideHeader: true },
        component: () => import("@/views/Originator/Dashboard/index.vue")
      },
      {
        path: "/originator-public/:id",
        name: "originator-public",
        meta: { hideHeader: true },
        component: () => import("@/views/Originator/Public/index.vue")
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/LayoutEmpty/index.vue"),
    children: [
      {
        path: "/callback",
        name: "callback",
        component: () => import("@/views/Callback.vue"),
      },
      {
        path: '/geltfinancial/chat',
        name: 'GeltFinancialsChat',
        component: () => import('@/views/GeltFinancials/Chat/index.vue')
      },
      {
        path: '/groundbreaker/chat',
        name: 'GroundbreakerChat',
        component: () => import('@/views/Groundbreaker/Chat/index.vue')
      },
      {
        path: '/lendingone/chat',
        name: 'LendingOneChat',
        component: () => import('@/views/LendingOne/Chat/index.vue')
      },
      {
        path: '/jpro/chat',
        name: 'JProChat',
        component: () => import('@/views/JPro/Chat/index.vue')
      },
      {
        path: '/ilal/chat',
        name: 'IlalChat',
        component: () => import('@/views/Ilal/Chat/index.vue')
      }
    ],
  },
  {
    path: "/",
    component: () => import("@/components/Layout/index.vue"),
    children: [
      {
        path: "/offering-memorandum/:omId/distribution/:distributionId/share-code/:shareCode",
        name: "om",
        meta: { hideHeader: true },
        component: () => import("@/views/OM/index.vue")
      },
      {
        path: "/geltfinancial",
        name: "gelt",
        meta: { hideHeader: true },
        component: () => import("@/views/GeltFinancials/index.vue")
      }
    ]
  },
  {
    path: "/originator-signup",
    name: "originator-signup",
    component: () => import("@/views/Originator/Signup/index.vue")
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/Originator/Signup/index.vue")
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Errors/404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to: any, from) => {
  if (to.path.includes('/geltfinancial') || to.path.includes('/chat')) return

  if (!store.getters.getCurrentUserProfile.email) {
    try {
      const origin = await store.dispatch(Actions.INIT_APP)

      if (origin) return origin
    } catch(e) {
      console.log(e)
    }
  }

  if (['lenders', 'originators', 'originator'].includes(to.name)) {
    if (!userHasRole(RoleType.BROKER) && !userHasRole(RoleType.ADVISOR)) return '/'
  }

  if (userHasRole(RoleType.ORIGINATOR)) {
    if (['originator-signup', 'signup'].includes(to.name)) return '/'
  }

  if (!userHasRole(RoleType.ADVISOR)) {
    if (['om-preview', 'deals'].includes(to.name)) return '/'
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100)
})

router.afterEach(async (to: any, from: any) => {
  if (to.fullPath !== from.fullPath) {
    HubspotTracker.push('setPath', to.fullPath)
    await SegmentTracker.page(to)
  }
})

export default router


  import { defineComponent, ref, toRefs, onMounted, computed, watch } from "vue"
  
  import store from "@/store"
  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals } from "@/composables"

  import { XIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'ModalSameLender',

    emits: ['closed'],

    components: {
      XIcon
    },

    props: ['originator', 'distributions', 'om'],

    setup(props, { emit }) {
      const { closeModal, showNotification } = useModals()

      const updateOriginatorsList = async () => {
        const originatorIds = new Set(props.distributions)

        let message = ''
      
        originatorIds.add(props.originator.id)
        message = `${props.originator.name} added to shortlist`

        try {
          await store.dispatch(Actions.UPDATE_DISTRIBUTIONS, {
            offeringMemorandaId: props.om.id,
            originatorIds: Array.from(originatorIds)
          })

          showNotification(message)
          closeModal('ModalSameLender')
        } catch (error: any) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
        }
      }

      return {
        closeModal,
        updateOriginatorsList
      }
    }
  })


  import { defineComponent, onMounted, computed, ref } from "vue"
  import { useRouter, useRoute } from "vue-router"

  import { BaseSpinner } from "@/components/Base"

  import { DealLenderStepsHorisontal } from "@/components/Deal"

  import useDealData from "@/composables/useDealData.ts"

  export default defineComponent({
    name: "Deal",

    components: {
      DealLenderStepsHorisontal,
      BaseSpinner
    },

    setup () {
      const router = useRouter()
      const route = useRoute()
      
      const { loading, deal, getDealData, name, distributions } = useDealData()
      
      onMounted(async () => {
        await getDealData()

        if (route.name == 'deal') {
          if (distributions.value.length) {
            router.push({ name: 'deal-om-tracker' })
          } else if (deal.value.offeringMemorandumId) {
            router.push({ name: 'deal-shortlist' })
          } else {
            router.push({ name: 'deal-overview' })
          }
        }
      })

      return {
        loading,
        name
      }
    }
  })

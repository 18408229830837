
  import { defineComponent } from "vue"

  import { StarIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'BaseRating',

    components: {
      StarIcon
    },

    props: {
      rating: {
        type: [Number, String],
        default () {
          return null
        }
      }
    }
  })

import ApiClient from "@/core/services/ApiClient";
import ApiResponse from "../helpers/ApiResponse";
import { UserFilterProps } from "@/core/services/apis/UsersApi";

const basePath = "account";

class PortalUserApi {

    public static async loggedIn() : Promise<ApiResponse<any>> {
        return await ApiClient.post<any>(`${basePath}/loggedin`, {});
    }

    public static async getMetadata(): Promise<ApiResponse<any>> {
        return await ApiClient.get<any>(`${basePath}/filter-metadata`);
    }

    public static async getProfileInfo(): Promise<ApiResponse<any>> {
        return await ApiClient.get<any>(`${basePath}/profile`);
    }

    public static async updateUserFull(updateModel): Promise<ApiResponse<any>> {
        return await ApiClient.put<any>(`${basePath}`, updateModel);
    }

    public static async updateUserProfile(payload: any): Promise<ApiResponse<any>> {
        return await ApiClient.put<any>(`${basePath}`, payload)
    }

    public static async updateUserPartial(updateObj): Promise<ApiResponse<any>> {
        console.log(updateObj)
        return await ApiClient.patch<any>(`${basePath}`, updateObj);
    }

    public static async getPagedUsers(
      count: number,
      page: number,
      sortField: string,
      sortDirection: string,
      filter: UserFilterProps
    ): Promise<ApiResponse<any>> {
        return await ApiClient.getPaged<any>(
          `${basePath}/paged`,
          true,
          count,
          page,
          sortField,
          sortDirection,
          PortalUserApi.buildQuerySlug(filter)
        );
    }

    public static async getUser(id: string): Promise<ApiResponse<any>> {
        return await ApiClient.get<any>(`${basePath}/id/${id}`);
    }

    private static buildQuerySlug(filter: UserFilterProps): string[] {
        if (filter == null) return [];

        const slugParts: string[] = [];

        if (filter.FirstName) {
            slugParts.push(`Type=${filter.FirstName}`);
        }
        if (filter.LastName) {
            slugParts.push(`Status=${filter.LastName}`);
        }

        return slugParts;
    }
}

export default PortalUserApi;

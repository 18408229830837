import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "account-details sm:flex xl:block items-center justify-start" }
const _hoisted_2 = {
  key: 0,
  class: "text-white text-lg xl:text-3xl"
}
const _hoisted_3 = { class: "mt-4 sm:mt-0 xl:mt-4" }
const _hoisted_4 = { class: "text-lg font-medium text-gray-900 leading-none mt-0 mb-2" }
const _hoisted_5 = { class: "text-sm text-gray-600" }
const _hoisted_6 = { class: "d-flex mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_tag = _resolveComponent("base-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "rounded-md w-16 h-16 sm:w-24 sm:h-24 xl:w-40 xl:h-40 sm:mr-6 flex flex-shrink-0 items-center justify-center bg-gray-200 bg-cover bg-center",
      style: _normalizeStyle({
        'background-image': `url(${_ctx.profile.picture})`
      })
    }, [
      (!_ctx.profile.picture)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString('–')))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.profile.fullName || 'Unknown'), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.profile.email || 'example@site.com'), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_base_tag, { class: "cursor-default" }, {
          default: _withCtx(() => [
            _createTextVNode(" Advisor ")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
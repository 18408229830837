
  import { defineComponent, toRefs, computed } from "vue"

  import { getAdvisorImage } from "@/core/helpers/getAdvisorImage"

  import { PhoneIcon, MailIcon, CalendarIcon } from "@heroicons/vue/solid"

  import store from "@/store"

  export default defineComponent({
    name: 'OmAdvisorBox',

    props: ['advisor'],

    components: {
      PhoneIcon,
      MailIcon,
      CalendarIcon
    },

    methods: {
      getAdvisorImage
    },

    setup (props) {
      const { advisor } = toRefs(props)

      const advisorsInfo = computed(() => store.getters.advisorsInfo)

      const contactInfo: any = computed(() => {
        return Object.values(advisorsInfo.value).filter(
          (item: any) => item.email == advisor.value.email || item.alternativeEmail == advisor.value.email
        )[0] || {}
      })

      return {
        advisorsInfo,
        contactInfo
      }
    }
  })

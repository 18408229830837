
  import { defineComponent, computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import store from '@/store'
  import { Actions } from '@/store/enums/StoreEnums'
  import { BaseSpinner } from '@/components/Base'

  import {
    OriginatorName,
    OriginatorHighlight,
    OriginatorCtaWithOptions,
    JanoverCta,
    OriginatorPreferences,
    OriginatorActivity,
  } from './components'

  export default defineComponent({
    name: 'OriginatorPublicProfile',

    components: {
      OriginatorName,
      // OriginatorHighlight,
      // OriginatorCtaWithOptions,
      JanoverCta,
      OriginatorPreferences,
      OriginatorActivity,
      BaseSpinner
    },

    setup () {
      const route = useRoute()

      const originator = computed(() => store.getters.publicOriginator)

      onMounted(async () => {
        await store.dispatch(Actions.GET_PUBLIC_ORIGINATOR, {
          id: route.params.id
        })
      })

      return {
        originator
      }
    }
  })

export enum OriginatorUserActions {
    GET_ORIGINATOR_USER = "getOriginatorUser",
    GET_ORIGINATOR_USER_DISTRIBUTIONS_PAGED = "getOriginatorUserDistributionsPaged",
    GET_ORIGINATOR_USER_DISTRIBUTIONS_BY_ID = "getOriginatorUserDistributionsById",
    GET_ORIGINATOR_USER_DISTRIBUTIONS_FILTER = "getOriginatorFilter",
    SUBMIT_QUOTE = "submitQuote",
    UPDATE_QUOTE = "updateQuote",
    REJECT_QUOTE = "rejectQuote",
    GET_ORIGINATOR_SETTINGS = "getOriginatorSettings",
    UPDATE_ORIGINATOR_SETTINGS = "updateOriginatorSettings",
    INQUIRE = "inquire",
    UPDATE_INQUIRIES = "updateInquiries",
    GET_ORIGINATOR_USER_OM_DISTRIBUTIONS = "getOriginatorUserOmDistributions"
}
export enum OriginatorUserMutations {
    SET_ORIGINATOR_USER_MUTATION = "setOriginatorUser",
    SET_ORIGINATOR_USER_DISTRIBUTIONS_FILTER_METADATA = "setOriginatorUserDistributionsFilterMetadata",
    SET_ORIGINATOR_USER_DISTRIBUTIONS_MUTATION = "setOriginatorUserDistributions",
    SET_ORIGINATOR_USER_DISTRIBUTIONS_PAGING = "setOriginatorUserDistributionsPaging",
    SET_ORIGINATOR_USER_DISTRIBUTIONS_FILTER_VALUE = "setOriginatorUserDistributionsFilterValue",
    SET_INQUIRIES = "setInquiries",
    SET_ORIGINATOR_USER_OM_DISTRIBUTIONS = "setOriginatorUserOmDistributions"
}


  import { defineComponent, ref, toRefs, onMounted, computed, watch } from "vue"
  
  import store from "@/store"

  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals, useDealData } from "@/composables"

  import { XIcon } from "@heroicons/vue/solid"

  import { useRoute } from "vue-router"

  export default defineComponent({
    name: 'ModalBulkEmailSend',

    emits: ['closed'],

    components: {
      XIcon
    },

    props: ['bulkSendList', 'deal'],

    setup(props, { emit }) {
      const { closeModal, showModal, showNotification } = useModals()

      const loading = ref<boolean>(false)

      const list = ref<any>(props.bulkSendList)
      const deal = ref<any>(props.deal)

      const { distributions, om } = useDealData()

      const recipients = computed(() => {
        return distributions.value.filter((item: any) => list.value.includes(item.id))
      })

      const sendEmails = async () => {
        try {
          loading.value = true

          await store.dispatch(Actions.BULK_SEND_EMAIL, {
            offeringMemorandumId: om.value.id,
            distributionIds: list.value
          })

          await store.dispatch(Actions.SET_STAGE, { deal: deal.value })

          closeModal('ModalBulkEmailSend')
        } catch (error: any) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
        }
      }

      return {
        closeModal,
        recipients,
        loading,
        showModal,
        sendEmails
      }
    }
  })

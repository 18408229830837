export default function (collection, displayedAmount, field = 'description') {
  if (!collection || !collection.length) return ['No data']

  const displayedTags: any[] = []
  
  let counter = 0

  collection.forEach((parent: any) => {
    if (!parent.types) {
      if (displayedTags.length < displayedAmount) displayedTags.push(parent[field])
      else counter++
    } else {
      parent.types.forEach(child => {
        if (displayedTags.length < displayedAmount) displayedTags.push(child[field])
        else counter++
      })
    }
  })

  if (counter) displayedTags.push(`+${counter}`)

  return displayedTags
}
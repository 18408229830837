
  import { defineComponent, computed } from "vue"

  import useUserProfile from '@/composables/useUserProfile'

  import { BaseTag } from "@/components/Base"

  import store from "@/store"

  export default defineComponent({
    name: "AccountDetails",

    components: {
      BaseTag
    },

    setup () {
      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      return {
        profile
      }
    }
  })

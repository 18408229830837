export enum PortalUserActions {
    GET_PORTAL_USERS = "getPagedPortalUsers",
    GET_PORTAL_USERS_FILTER = "getPortalUserFilter",
    GET_PORTAL_USER = "getPortalUser",
    UPDATE_PORTAL_USER_FULL = "updatePortalUserFull",
    UPDATE_PORTAL_USER_PARTIAL = "updatePortalUserPartial",
    INIT_APP = "initApp"
}
export enum PortalUserMutations {
    SET_PORTAL_USERS_MUTATION = "setPortalUsers",
    SET_PORTAL_USERS_FILTER_METADATA = "setPortalUsersFilterMetadata",
    SET_PORTAL_USER_MUTATION = "setPortalUser",
    SET_PORTAL_USER_PROFILE_MUTATION = "setPortalUserProfile"
}

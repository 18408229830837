import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full lg:w-1/3 pr-10" }
const _hoisted_2 = { class: "text-lg leading-6 font-medium" }
const _hoisted_3 = { class: "text-sm leading-5 font-normal text-gray-500 mt-1" }
const _hoisted_4 = { class: "w-full mt-3 lg:mt-0 lg:w-2/3 lg:pl-10" }
const _hoisted_5 = {
  key: 0,
  class: "font-semibold text-sky"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "mt-2 lg:mt-0 px-5 py-4 flex lg:justify-end"
}
const _hoisted_8 = {
  key: 0,
  type: "button",
  class: "bg-red-600 text-white text-sm rounded-md py-2 px-4 mr-2"
}
const _hoisted_9 = {
  key: 1,
  class: "pb-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_item = _resolveComponent("list-item")!
  const _component_counties_preview = _resolveComponent("counties-preview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, ({ title, subtitle }, model) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "lg:flex p-6",
        key: model
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h4", _hoisted_2, _toDisplayString(title), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(subtitle), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-wrap", { 'pt-2': !['name', 'notest'].includes(model) }])
          }, [
            (model == 'name')
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.name), 1))
              : (model == 'notes')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.data.notes && !_ctx.userHasRole(_ctx.RoleType.BROKER))
                      ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.data.notes), 1))
                      : (_openBlock(), _createBlock(_component_list_item, {
                          key: 1,
                          muted: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("No data")
                          ]),
                          _: 1
                        }))
                  ], 64))
                : (model == 'amountRange')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      (!_ctx.amountRange.minimumAmount && !_ctx.amountRange.maximumAmount)
                        ? (_openBlock(), _createBlock(_component_list_item, {
                            key: 0,
                            muted: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" No data ")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.amountRange.minimumAmount)
                        ? (_openBlock(), _createBlock(_component_list_item, { key: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(" From $" + _toDisplayString(_ctx.amountRange.minimumAmount), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.amountRange.maximumAmount)
                        ? (_openBlock(), _createBlock(_component_list_item, { key: 2 }, {
                            default: _withCtx(() => [
                              _createTextVNode(" To $" + _toDisplayString(_ctx.amountRange.maximumAmount), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ], 64))
                  : (model == 'footprints' && _ctx.data[model].length)
                    ? (_openBlock(), _createBlock(_component_counties_preview, {
                        key: 3,
                        footprints: _ctx.data[model]
                      }, null, 8, ["footprints"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data[model], (item, index) => {
                          return (_openBlock(), _createBlock(_component_list_item, { key: index }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.typeNamesMap[item] || item), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128)),
                        (!_ctx.data[model]?.length)
                          ? (_openBlock(), _createBlock(_component_list_item, {
                              key: 0,
                              muted: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" No data ")
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
          ], 2)
        ])
      ]))
    }), 128)),
    (!_ctx.userHasRole(_ctx.RoleType.BROKER))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.canDelete)
            ? (_openBlock(), _createElementBlock("button", _hoisted_8, " Delete "))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            type: "button",
            class: "bg-sky text-white text-sm rounded-md py-2 px-4",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showEditor && _ctx.showEditor(...args)), ["prevent"]))
          }, " Edit & Update ")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9))
  ], 64))
}
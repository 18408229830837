
  import { defineComponent } from 'vue';

  import { NewspaperIcon } from '@heroicons/vue/outline'

  import { useModals } from '@/composables'

  export default defineComponent({
    name: 'OriginatorCtaWithOptions',

    props: ['originator'],

    components: {
      NewspaperIcon
    },

    setup () {
      const { showModal } = useModals()

      return {
        showModal
      }
    }
  })


  import { defineComponent, toRefs, computed } from 'vue'

  import numeral from 'numeral'

  export default defineComponent({
    name: 'OriginatorHighlight',

    props: ['originator', 'softQuotes'],

    setup(props) {
      const { softQuotes } = toRefs(props)

      const loanAmount = computed(() => {
        if (!softQuotes.value?.length) return 0

        const total = softQuotes.value.reduce((sum, quote) => sum + quote.LoanAmount, 0);
        return numeral(total / softQuotes.value.length).format('$0.0a');
      })

      const LTV = computed<any>(() => {
        if (!softQuotes.value?.length) return 0

        const total = softQuotes.value.reduce((sum, quote) => sum + quote.MaxLTV, 0);
        return (total / softQuotes.value.length).toFixed(0);
      })

      return {
        loanAmount,
        LTV
      }
    }
  })

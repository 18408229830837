
  import { defineComponent, computed, onMounted } from "vue"
  import { useStore } from "vuex"
  import { useRoute } from "vue-router"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"
  
  import { useAuth0 } from "@auth0/auth0-vue"

  import LayoutNav from "./Nav.vue"
  
  export default defineComponent({
    name: "Layout",
    
    components: {
      LayoutNav
    },
    
    setup() {
      const route = useRoute()
      const store = useStore()
      
      const { isAuthenticated, checkSession } = useAuth0()

      const currentRouteName = computed(() => {
        return route.meta?.label || route.name
      })

      const hideHeader = computed(() => {
        return !!route.meta?.hideHeader
      })

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      onMounted(async () => {
        if (profile.value?.originatorId && !userHasRole(RoleType.ORIGINATOR)) {
          try {
            await checkSession({
              ignoreCache: true
            });
          } catch (error) {
            console.error(error);
          }
        }
      })

      return {
        isAuthenticated,
        currentRouteName,
        hideHeader,
        privacyPolicy: process.env.VUE_APP_PRIVACY_POLICY,
        termsAndConditions: process.env.VUE_APP_TERMS_AND_CONDITIONS
      }
    }
  })

export default {
  name: {
    title: 'Product Name',
    subtitle: 'What do you want to call this product? You can call it anything you want from George, to Bridge, to SBA7a Construction. Be as creative as you’d like.',
  },
  executionTypes: {
    title: 'Execution types',
    subtitle: 'What type of execution is this product? It can be SBA7a, Balance Sheet, Fannie Mae Small, pretty much anything!',
  },
  propertyTypes: {
    title: 'Property Types',
    subtitle: 'What property types are eligible for this fancy loan product of yours?'
  },
  amountRange: {
    title: 'Loan amounts',
    subtitle: 'Enter your minimum and maximum loan amounts so we can ensure you only get deals in your strike zone.'
  },
  footprints: {
    title: 'Footprint',
    subtitle: 'Where do you lend on this loan product. Drill down as deep as you’d like or go as wide as you’d like.'
  },
  notes: {
    title: 'Notes',
    subtitle: 'Please share any other relevant information about this loan product.'
  }
}
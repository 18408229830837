export enum RoleType {
  ADMIN = "admin",
  ADVISOR = "advisor",
  LENDER = "lender",
  LENDER_ADMIN = "lender-admin",
  ORIGINATOR = "originator",
  BROKER = "broker"
}

export enum PermissionType {

}

  import axios from "axios"
  import { defineComponent, onMounted, ref, computed, watch } from "vue"
  import { onClickOutside } from '@vueuse/core'
  import { ArrowNarrowRightIcon, SelectorIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "CompanyStep",

    emits: ['nextStep'],

    components: {
      ArrowNarrowRightIcon,
      SelectorIcon
    },

    setup (props, { emit }) {
      const collectedData = ref<any>({
        name: '',
        id: null,
        lenderUri: ''
      })

      const isValidWebsite = computed(() => {
        if (!collectedData.value.lenderUri) return true

        const regex = /^(https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
        return regex.test(collectedData.value.lenderUri)
      })

      const isValid = computed(() => {
        if (!collectedData.value.name) return false
        else if (!collectedData.value.id) {
          return collectedData.value.lenderUri && isValidWebsite.value
        }
        return true
      })

      const company = ref<string>('')

      const lenders = ref<any>([])

      onMounted(async () => {
        const { data } = await axios.get(`${process.env.VUE_APP_NODE_API_URL}/public/lenders`)

        lenders.value = data.lenders
      })

      const handleSelect = ({ name, id }) => {
        company.value = name
        collectedData.value.name = name
        collectedData.value.id = id
      }

      const isOpened = ref<boolean>(false)
      const target = ref<any>(null)

      onClickOutside(target, () => {
        isOpened.value = false
      })

      const toggle = () => {
        if (isOpened.value) {
          isOpened.value = false
        } else {
          isOpened.value = true
        }
      }

      const filteredOptions = computed(() => {
        let query = JSON.parse(JSON.stringify(company.value)).toLowerCase()
        
        return lenders.value.filter((item: any) => {
          return item.name.toString().toLowerCase().includes(query)
        })
      })

      const handleFormSubmit = async () => {
        const { name, id, lenderUri } = collectedData.value

        if (name && id) {
          emit("nextStep", { name, lenderId: id })
        } else if (name && lenderUri) {
          const { data: { lenderId } } = await axios.post(
            `${process.env.VUE_APP_NODE_API_URL}/lender`,
            {
              name,
              lenderUri: lenderUri.includes('http') ? lenderUri : `https://${lenderUri}`
            }
          )

          if (lenderId) emit("nextStep", { name, lenderId })
        }
      }

      return {
        handleFormSubmit,
        lenders,
        handleSelect,
        company,
        toggle,
        isOpened,
        filteredOptions,
        target,
        collectedData,
        isValid,
        isValidWebsite
      }
    }
  })


  import { defineComponent, ref, computed } from "vue"

  import { onClickOutside } from '@vueuse/core'
  import { SelectorIcon } from "@heroicons/vue/solid"

  import DealDocumentUpload from "./DocumentUpload.vue"

  export default defineComponent({
    name: "AddDocument",

    props: [
      'questions',
      'data'
    ],

    emits: ['add-file-to-queue'],

    components: {
      SelectorIcon,
      DealDocumentUpload
    },

    setup (props, { emit }) {
      const isOpened = ref<boolean>(false)
      const target = ref<any>(null)
      const search = ref<string>('')

      const questions = ref(props.questions)
      const data = ref(props.data)

      const collectedData = ref<any>({})

      const availableDocuments = computed(() => {
        const obj = {}

        if (questions.value && Object.values(questions.value)) {
          for (let key in questions.value) {
            if (questions.value[key]?.type === 'document' &&
              !questions.value[key].isDuplicate &&
              questions.value[key].flow !== 'lender-onboarding-flow') {
              obj[key] = questions.value[key]
            }
          }
        }

        return Object.values(obj).reverse()
      })

      const activeQuestion = ref<any>(null)

      onClickOutside(target, () => {
        isOpened.value = false
        if (!activeQuestion.value) search.value = ''
      })

      const toggle = () => {
        if (isOpened.value) {
          isOpened.value = false
          if (!activeQuestion.value) search.value = ''
        } else {
          isOpened.value = true
        }
      }

      const options = computed(() => {
        let query = JSON.parse(JSON.stringify(search.value)).toLowerCase()
        
        return availableDocuments.value.filter((item: any) => {
          return item.name.toLowerCase().includes(query)
        })
      })

      const select = (value) => {
        activeQuestion.value = value
        search.value = value.name
      }

      const save = () => {
        const { isVisible, name, file } = collectedData.value
        
        emit('add-file-to-queue', { isVisible, file, name, $type: 'document' })
        
        collectedData.value = {}
        activeQuestion.value = null
        search.value = ''
      }

      const onFieldChanged = ({ isVisible, name, file }) => {
        collectedData.value = { isVisible, name, file }
      }

      const cancel = () => {
        collectedData.value = {}
        activeQuestion.value = null
        search.value = ''
      }

      return {
        isOpened,
        target,
        search,
        options,
        toggle,
        select,
        activeQuestion,
        collectedData,
        save,
        onFieldChanged,
        cancel
      }
    }
  })

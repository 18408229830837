export enum FieldActions {
    GET_FIELDS = "getFields",
    GET_FIELD_FILTER = "getFieldFilter",
    GET_FIELD = "getField",
}
export enum FieldMutations {
    SET_FIELDS_MUTATION = "setFields",
    SET_FIELDS_FILTER_METADATA = "setFieldFilterMetadata",
    SET_FIELD_MUTATION = "setField",
}


  import { defineComponent, onMounted } from "vue"
  import store from "@/store"

  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals } from "@/composables"

  import { convertToShortCurrency } from "@/core/helpers/convertToCurrency.ts"

  export default defineComponent({
    name: 'ModalUpdateCreditBox',

    emits: ['closed'],

    setup(props, { emit }) {
      const { closeModal } = useModals()

      return {
        closeModal
      }
    }
  })

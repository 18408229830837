
  import { defineComponent } from "vue"

  export default defineComponent({
    name: 'CreditBoxListItem',

    props: {
      muted: {
        type: Boolean,
        default () {
          return false
        }
      }
    }
  })


  import { defineComponent, ref, onMounted, computed } from "vue"
  import { useStore } from "vuex"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"
  import { Actions, Mutations } from "@/store/enums/StoreEnums"
  import { convertToCurrency } from "@/core/helpers/convertToCurrency"
  import DealGridConfig from "@/core/config/DealGridConfig"

  import DataTable from "@/components/DataTable/index.vue"
  import { BaseSpinner, BasePagination } from "@/components/Base"
  import { EyeIcon } from '@heroicons/vue/solid'

  import { useModals } from "@/composables"

  import moment from 'moment'

  export default defineComponent({
    name: "AdvisorDashboard",

    computed: {
      stats () {
        return [
          { name: 'New requests today', stat: '45' },
          { name: 'Deals in progress', stat: '65' },
          { name: 'Average time to first contact', stat: '3.5 hours' },
          { name: 'LOIs sent this month', stat: '35' }
        ]
      }
    },

    components: {
      BaseSpinner,
      DataTable,
      EyeIcon,
      BasePagination
    },

    methods: {
      moment (date) {
        return moment(date).fromNow();
      },

      convertToCurrency
    },

    setup () {
      const store = useStore()

      const loading = ref<boolean>(true)

      const deals = computed(() => {
        return store.getters.deals
      })

      const paging = computed(() => {
        return store.getters.paging
      })

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      const filtersData = computed(() => {
        return store.getters.filter
      })

      const onPageChange = async (pageNumber) => {
        await store.dispatch(Actions.GET_PAGED_DEALS, pageNumber)
      }

      onMounted(async () => {
        try {
          await store.dispatch(Actions.GET_FIELDS)
          store.commit(Mutations.RESET_DEALS)
          
          const distributionsMap = {
            "brad@janover.ventures": "Brad Beattie",
            "brandon@janover.ventures": "Brandon",
            "brian@janover.ventures": "Brian Hamada",
            "bryan@janover.ventures": "Bryan Rippe",
            "jalexander@janover.ventures": "Joe",
            "joey@janover.ventures": "Joe",
            "joe.nolan@janover.ventures": "Joe Nolan",
            "tyler@janover.ventures": "Tyler Pepper",
            "sean@janover.co": "Sean Snyder"
          }
          const distributeTo = distributionsMap[profile.value.email] || 'Brandon'
          await store.dispatch(Actions.UPDATE_DEALS_FILTER, { distributeTo, status: 'Contacted' })
          loading.value = false
        } catch (error) {
          console.log(error)
        }
      })

      const setFilter = async (status) => {
        await store.dispatch(Actions.UPDATE_DEALS_FILTER, { status })
      }

      const { showModal } = useModals()

      const tabs = ref<any>([
        {
          label: 'All deals',
          filter: ''
        },
        {
          label: 'Contacted',
          filter: 'Contacted'
        },
        {
          label: 'Sourcing terms',
          filter: 'Sourcing Terms'
        },
        {
          label: 'Terms received',
          filter: 'Terms Received'
        },
        {
          label: 'LOI sent',
          filter: 'LOI Sent'
        },
        {
          label: 'LOI executed',
          filter: 'LOI Executed'
        }
      ])

      return {
        loading,
        deals,
        tableHeader: DealGridConfig,
        paging,
        showModal,
        tabs,
        filtersData,
        onPageChange,
        setFilter
      }
    }
  })

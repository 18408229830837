
  import { defineComponent, computed, ref } from "vue"

  import { Swiper, SwiperSlide } from 'swiper/vue'

  import 'swiper/swiper-bundle.min.css'

  export default defineComponent({
    name: 'OmPictures',

    props: ['pictures', 'propertyType'],

    components: {
      Swiper,
      SwiperSlide,
    },

    setup (props) {
      const swiper = ref<any>(null)

      const activeSlide = ref<any>(0)

      const defaultImage = computed(() => {
        const map = {
          'Hospitality': '/media/photos/hospitality.jpeg',
          'Multifamily 5+ Units': '/media/photos/hospitality.jpeg',
          'Office': '/media/photos/office.jpeg',
          'Retail': '/media/photos/retail.jpeg',
          'Industrial': '/media/photos/industrial.png',
          '1-4 Unit Residential': '/media/photos/residental.webp',
          'Self Storage': '/media/photos/storage.jpeg',
          'Mobile Home Park': '/media/photos/park.jpeg',
          'Business': '/media/photos/sba.jpeg'
        }

        return map[props.propertyType] || map['Business']
      })

      const onSwiper = ($swiper) => {
        swiper.value = $swiper
      }

      const onSlideChange = ({ activeIndex }) => {
        activeSlide.value = activeIndex
      }

      const slideTo = (index) => {
        swiper.value.slideTo(index)
      }

      return {
        defaultImage,
        onSwiper,
        slideTo,
        activeSlide,
        onSlideChange
      }
    }
  })

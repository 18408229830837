
  declare global {
    interface Window {
      SimpleMDE: any
    }
  }
  
  import { defineComponent, onMounted, onBeforeUnmount, watch, ref } from "vue"
  
  export default defineComponent({
    name: 'Wysiwig',

    props: {
      modelValue: {
        type: String,
        default: '',
      },
    },

    emits: ['update:modelValue'],

    setup (props, { emit }) {
      const value = ref<string>(props.modelValue)
      const editor = ref<any>(null)

      onMounted(() => {
        if (!editor.value) {
          editor.value = new window.SimpleMDE({
            element: document.querySelector('#wysiwig-editor'),
            status: false
          })

          editor.value.codemirror.on("change", () => {
            emit('update:modelValue', editor.value.value())
          })
        }
      })

      onBeforeUnmount(() => {
        editor.value.toTextArea()
        editor.value = null
      })

      return {
        value
      }
    }
  })

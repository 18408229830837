export enum ShortListsActions {
    GET_SHORTLISTS = "getShortLists",
    CREATE_SHORTLIST = "createShortList",
    UPDATE_SHORTLIST = "updateShortList",
    DELETE_SHORTLIST = "deleteShortList",
}

export enum ShortListsMutations {
    SET_SHORTLISTS_MUTATION = "setShortLists"
}

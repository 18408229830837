
  import { defineComponent } from "vue"

  import { scrollTo } from "@/core/helpers/scrollTo"

  export default defineComponent({
    name: 'OmCtaBox',

    data () {
      return {
        isVisible: true
      }
    },

    methods: {
      scrollTo,

      onClick(id) {
        this.scrollTo('#soft-quote')
        document.querySelector(id).click()
        setTimeout(() => {
          this.isVisible = false
        }, 1000)
      }
    }
  })

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full px-4 flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatComponent = _resolveComponent("ChatComponent")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.originator)
      ? (_openBlock(), _createBlock(_component_ChatComponent, {
          key: 0,
          originator: _ctx.originator,
          lender: _ctx.lender,
          botName: "Steven"
        }, null, 8, ["originator", "lender"]))
      : (_openBlock(), _createBlock(_component_BaseSpinner, {
          key: 1,
          class: "h-80"
        }))
  ]))
}
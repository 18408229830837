import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "gallery max-h-96 overflow-hidden flex items-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-wrap items-start justify-start px-4 md:px-6 mt-4 md:mt-6" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (!_ctx.pictures || _ctx.pictures.length < 2)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.pictures || !_ctx.pictures[0])
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.defaultImage,
              class: "w-full h-full object-cover"
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.pictures[0]['remoteUrl'],
              class: "w-full h-full object-cover"
            }, null, 8, _hoisted_3))
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_swiper, {
          "slides-per-view": 1,
          "auto-height": true,
          onSwiper: _ctx.onSwiper,
          onSlideChange: _ctx.onSlideChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pictures, (picture) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                class: "gallery overflow-hidden flex items-center",
                key: picture.id
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: picture.remoteUrl,
                    class: "w-full h-full object-cover"
                  }, null, 8, _hoisted_4)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["onSwiper", "onSlideChange"]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pictures, (picture, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["overflow-hidden rounded-md mr-2 mb-2 w-20 h-20 cursor-pointer", { 'opacity-50 hover:opacity-100': index !== _ctx.activeSlide, 'ring-2 ring-blue-500': index == _ctx.activeSlide }]),
              key: picture.id,
              onClick: ($event: any) => (_ctx.slideTo(index))
            }, [
              _createElementVNode("img", {
                src: picture.remoteUrl,
                class: "w-full h-full object-cover"
              }, null, 8, _hoisted_7)
            ], 10, _hoisted_6))
          }), 128))
        ])
      ], 64))
}
import { StatesMap, PropertyTypesMap, ExecutionTypesMap, FirmTypesMap } from "@/core/constants"

const dispayMap = Object.assign({}, PropertyTypesMap, ExecutionTypesMap, FirmTypesMap)

export default function (collection, displayedAmount) {
  if (!collection || !collection.length) return ['None']

  const displayedTags: any[] = []
  
  let counter = 0

  collection.forEach((item: any) => {
    if (displayedTags.length < displayedAmount) displayedTags.push(dispayMap[item] || item)
    else counter++
  })

  if (counter) displayedTags.push(`+${counter}`)

  return displayedTags
}
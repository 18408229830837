
  import { defineComponent, computed, toRefs } from "vue"

  import { EyeOffIcon, EyeIcon, DownloadIcon, TrashIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "DealGroupedDocuments",

    props: ['documents', 'removedDocuments'],

    components: {
      EyeOffIcon,
      EyeIcon,
      DownloadIcon,
      TrashIcon
    },

    emits: ['updated', 'remove-document'],

    setup (props, { emit }) {
      const { documents } = toRefs(props)

      const groups = computed(() => {
        const obj = {}

        documents.value.forEach((item: any) => {
          if (!obj[item.name]) obj[item.name] = []
          obj[item.name].push(item)
        })

        return obj
      })

      const changeVisibility = (documentId, isVisible) => {
        emit('updated', {
          documentId,
          isVisible
        })
      }

      const removeDocument = (documentId) => {
        emit('remove-document', {
          documentId
        })
      }

      return {
        groups,
        changeVisibility,
        removeDocument
      }
    }
  })

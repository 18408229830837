
  import { defineComponent } from "vue"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"
  
  import { AdvisorAccount, OriginatorAccount, EmptyAccount } from "@/components/Accounts"

  export default defineComponent({
    name: "Account",

    computed: {
      account () {
        if (userHasRole(RoleType.ADVISOR)) return 'AdvisorAccount'
        if (userHasRole(RoleType.ORIGINATOR)) return 'OriginatorAccount'
        return 'EmptyAccount'
      }
    },

    components: {
      AdvisorAccount,
      OriginatorAccount,
      EmptyAccount
    }
  })


  import { defineComponent, ref, toRefs, onMounted, computed, watch } from "vue"
  
  import store from "@/store"

  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals, useDealData } from "@/composables"

  import { XIcon, StarIcon } from "@heroicons/vue/solid"
  import { Select } from "@/components/Base"

  import { useRoute } from "vue-router"

  import { convertToCurrency, converToNumber } from "@/core/helpers/convertToCurrency"
  import { HubspotTracker, PostHogTracker } from "@/core/services/trackers"

  export default defineComponent({
    name: 'ModalAddQuote',

    emits: ['closed'],

    components: {
      XIcon,
      StarIcon,
      BaseSelect: Select,
    },

    setup(props, { emit }) {
      const { closeModal, showModal, showNotification } = useModals()

      const loading = ref<boolean>(false)

      const selectedOriginator = ref<any>(null)

      const { distributions, om, getDealData } = useDealData()

      const searchQuery = ref<any>('')
      const debounce = ref<any>(null)

      const originators = computed(() => {
        return store.getters.originators
      })

      watch(searchQuery, () => {
        clearTimeout(debounce.value)

        debounce.value = setTimeout(async () => {      
          await store.dispatch(Actions.UPDATE_ORIGINATOR_FILTER, {
            name: searchQuery.value
          })
        }, 1000)
      })

      const disabledOriginators = computed(() => {
        return distributions.value.filter(
          (item: any) => ['Quoted', 'NotInterested'].includes(item?.status)
        ).map(
          (item: any) => item?.originator?.id
        )
      })

      const collectedData = ref<any>({
        loanAmount: '', // Number
        maxLTV: '', // Number
        maxLTC: '', // Number
        termYears: '', // Number
        rateType: '', // Fixed, Floating
        amortization: '', // Number
        index: '', // Object
        startInterestRate: '', // Number
        interestOnly: '', // Boolean
        interestOnlyPeriod: '', // Number
        interestOnlyDetails: '', // String
        recourse: '', // String
        recourseDetails: '', // String
        prepaymentPenalties: '', // Boolean
        prepaymentDetails: '', // String
        originationFee: '', // Number
        additionalDetails: '', // String
        dscr: ''
      })

      onMounted(() => {
        om.value.dataPoints.forEach((item:any) => {
          if (item.name == 'Loan amount') collectedData.value.loanAmount = item.value
        })
      })

      watch(() => collectedData.value.loanAmount, (newValue) => {
        collectedData.value.loanAmount = convertToCurrency(newValue)
      })

      const handleFormSubmit = async () => {
        const payload = {
          "offeringMemorandumId": om.value.id,
          "originatorId": selectedOriginator.value.id,
          "distributionId": null,
          "dataPoints": null,
          "isVisibleToBorrower": false
        }

        for (let key in collectedData.value) {
          if (key == 'loanAmount') {
            payload[key] = converToNumber(collectedData.value[key])
          } else if (['maxLTC', 'maxLTV', 'termYears', 'amortization', 'startInterestRate', 'interestOnlyPeriod', 'originationFee', 'dscr'].includes[key]) {
            if (!collectedData.value[key]) payload[key] = null
            else payload[key] = Number(collectedData.value[key].replace('%', '').replace(',', '.'))
          } else {
            payload[key] = collectedData.value[key] || null
          }
        }

        try {
          const response = await store.dispatch(Actions.SUBMIT_QUOTE, payload)

          await getDealData()

          PostHogTracker.capture('OM – Quote Added', {
            ...payload,
            added_by: 'Advisor'
          })
          HubspotTracker.push('trackEvent', {
            id: 'IQ3 – Quote Added',
          })

          closeModal('ModalAddQuote')
        } catch (error) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
        }
      }

      return {
        closeModal,
        showModal,
        loading,
        om,
        handleFormSubmit,
        searchQuery,
        originators,
        distributions,
        disabledOriginators,
        selectedOriginator,
        collectedData
      }
    }
  })

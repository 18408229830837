
  import { defineComponent, computed, ref, onMounted } from "vue"

  import { useStore } from "vuex"

  import { useModals } from "@/composables"

  import DataTable from "@/components/DataTable/index.vue"
  import { BasePagination, BaseSpinner, BaseRating } from "@/components/Base"
  import LendersFilter from "@/components/Lenders/LendersFilter.vue"

  import { Actions, Mutations } from "@/store/enums/StoreEnums"
  import { OriginatorGridConfig } from "@/core/config"
  import { convertToShortCurrency } from "@/core/helpers/convertToCurrency"
  import getTags from "@/core/helpers/getTags"

  import { BanIcon, ShieldCheckIcon } from "@heroicons/vue/solid"

  import moment from "moment"
  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: "OriginatorsList",

    components: {
      BasePagination,
      BaseSpinner,
      DataTable,
      LendersFilter,
      BanIcon,
      ShieldCheckIcon,
      BaseRating
    },

    setup () {
      const loading = ref(true)

      const store = useStore()

      const paging = computed(() => {
        return store.getters.matchingPaging
      })

      const originators = computed(() => {
        return store.getters.matching
      })

      onMounted(async () => {
        await store.dispatch(Actions.GET_MATCHING_FILTER_METADATA, false)
        await store.dispatch(Actions.CLEAR_MATCHING_FILTER, false)
        await store.dispatch(Actions.GET_PAGED_MATCHING)

        loading.value = false
      })

      const { showModal } = useModals()

      const onPageChange = async (pageNumber) => {
        await store.dispatch(Actions.GET_PAGED_MATCHING, pageNumber)
      }

      const showProfile = async (originatorId, product = '') => {
        const originator = await store.dispatch(Actions.GET_ORIGINATOR, { id: originatorId })
        showModal('ModalOriginator', { originator, product })
      }

      const showLender = async (originatorId) => {
        const lender = await store.dispatch(Actions.GET_LENDER, { id: originatorId })
        showModal('ModalLender', { lender })
      }

      const tableHeader = computed(() => {
        return OriginatorGridConfig.filter(item => item.key !== 'location' || !userHasRole(RoleType.BROKER))
      })

      return {
        loading,
        paging,
        originators,
        tableHeader,
        convertToShortCurrency,
        getTags,
        showModal,
        onPageChange,
        showProfile,
        showLender,
        moment,
        userHasRole,
        RoleType
      }
    }
  })

import ApiClient from "@/core/services/ApiClient"
import ApiResponse from "../helpers/ApiResponse"

const basePath = "advisors"

class AdvisorsApi {
  public static async getPagedAdvisors(
    count: number,
    page: number,
    sortField: string,
    sortDirection: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getPaged<any>(
      `${basePath}/paged`,
      true,
      count,
      page,
      sortField,
      sortDirection,
      ['']
    )
  }
}

export default AdvisorsApi


  import { defineComponent, computed, toRefs } from "vue"

  export default defineComponent({
    name: 'OmNavigation',

    props: ['om', 'map', 'showQuote'],

    setup (props) {
      const { om, map, showQuote } = toRefs(props)

      const steps = computed(() => {
        const obj: any = {
          '#summary': 'Summary'
        }

        if (map.value) obj['#location'] = 'Location'
        if (om.value?.dataPoints.length) obj['#data-points'] = 'Data points'
        if (om.value?.documents.length) obj['#documents'] = 'Documents'
        if (showQuote.value) obj['#soft-quote'] = 'Soft Quote'
        
        return obj
      })

      const goTo = (key) => {
        document.querySelector(key).scrollIntoView({
          behavior: "smooth"
        })
      }

      return {
        steps,
        goTo
      }
    }
  })

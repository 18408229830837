
import { defineComponent, ref, onBeforeMount } from 'vue';
import ChatComponent from '@/components/Chat/index.vue';
// import { BaseSpinner } from '@/components/Base';
// import { OriginatorsPublicApi } from "@/core/services/apis";
import { PostHogTracker } from '@/core/services/trackers';

export default defineComponent({
  components: {
    ChatComponent,
    // BaseSpinner
  },
  setup () {
    // const originatorId = 'ac020000-a9fe-0a58-7191-08dbfcb1b4e4'
    // const originator = ref<any>(null)
    // const lender = ref<any>(null)

    onBeforeMount(async () => {
      PostHogTracker.init(undefined)
      // const { data } = await OriginatorsPublicApi.getOriginator(originatorId)
      // // originator.value = data.originator
      // lender.value = data.lender

      if (window.self !== window.top) {
        // The window is in an iframe
        PostHogTracker.capture('$pageview', {
          $current_url: window.location.href,
          parent_url: document.referrer,
          // originatorId: originatorId,
          // lenderId: data.lender.id
        });
        
      }
    })

    return {
      // originator,
      // lender
    }
  }
});


  import { defineComponent, computed, toRefs } from 'vue'
  import { useRoute } from 'vue-router'

  import { convertToCurrency } from "@/core/helpers/convertToCurrency"

  import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid"

  import store from "@/store"
  import { Actions, Mutations } from "@/store/enums/StoreEnums"

  import moment from 'moment'

  export default defineComponent({
    name: 'SoftQuote',

    props: ['quote', 'distribution', 'visibilityControl'],

    components: {
      EyeIcon,
      EyeOffIcon
    },

    setup (props) {
      const route = useRoute()
      const { quote, distribution, visibilityControl } = toRefs(props)

      const showControls = computed(() => {
        return visibilityControl.value && process.env.VUE_APP_FEATURE_SOFT_QUOTE_VISIBILITY_CONTROLS === 'true'
      })

      const updateQuote = async (isVisibleToBorrower: any) => {
        try {
          const payload = Object.assign({}, quote.value)

          payload.isVisibleToBorrower = isVisibleToBorrower

          await store.dispatch(Actions.UPDATE_QUOTE, payload)
          await store.dispatch(Actions.GET_DEAL, { id: route.params.id })
        } catch (error: any) {
          console.log(error)
          store.commit(Mutations.SHOW_NOTIFICATION, {
            text: error?.response?.data?.errors || error.message || 'Something went wrong',
            type: 'error'
          })
        }
      }

      const showProfile = async (originatorId: any) => {
        if (showControls.value) {
          const originator = await store.dispatch(Actions.GET_ORIGINATOR, { id: originatorId })
          store.commit(Mutations.SHOW_MODAL, { modal: 'ModalOriginator', data: { originator } })
        }
      }

      return {
        showControls,
        moment,
        convertToCurrency,
        showProfile,
        updateQuote
      }
    }
  })

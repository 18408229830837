import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { LendersMatchingApi } from "@/core/services/apis"

export default {
  state: {
    matching: [],
    filterMetadata: {},
    filter: {},
    paging: {
      currentPage: 1,
      pageSize: 25,
      pageCount: 1,
      sortField: "Rating",
      sortDirection: "DESC",
      totalResults: 0
    },
    distributions: [],
    lendersList: null
  },

  getters: {
    matching (state) {
      return state.matching
    },

    matchingFilterMetadata (state) {
      return state.filterMetadata
    },

    matchingPaging (state) {
      return state.paging
    },

    matchingFilter (state) {
      return state.filter
    },

    lendersList (state) {
      return state.lendersList
    }
  },

  mutations: {
    [Mutations.SET_MATCHING_MUTATION] (state, matching) {
      state.matching = matching
    },

    [Mutations.SET_MATCHING_FILTER_METADATA] (state, filterMetadata) {
      state.filterMetadata = filterMetadata

      for (const key in filterMetadata.defaultSearchParams) {
        if (!['page', 'count', 'sortField', 'sortDirection', 'balanceSheetRange'].includes(key)) {
          if (Array.isArray(filterMetadata.defaultSearchParams[key])) {
            state.filter[key] = new Set(filterMetadata.defaultSearchParams[key])
          } else {
            state.filter[key] = filterMetadata.defaultSearchParams[key]
          }
        }
      }
    },

    [Mutations.SET_MATCHING_PAGING] (state, paging) {
      for (const key in paging) {
        state.paging[key] = paging[key]
      }
    },

    [Mutations.SET_MATCHING_FILTER_VALUE] (state, payload) {
      console.log('SET_MATCHING_FILTER_VALUE', payload)
      if (!Object.entries(payload).length) {
        state.filter = {}
      } else {
        for (const key in payload) {
          state.filter[key] = payload[key]
        }
      }
    },

    setLendersList (state, payload) {
      state.lendersList = payload
    }
  },

  actions: {
    async [Actions.GET_MATCHING] ({ commit, getters }) {
      const { pageSize, currentPage, sortField, sortDirection } = getters.matchingPaging
      const filters = getters.matchingFilter

      const { data } = await LendersMatchingApi.getPagedMatching(
        pageSize,
        currentPage,
        sortField,
        sortDirection,
        filters
      )

      commit(Mutations.SET_MATCHING_MUTATION, data.data)
      commit(Mutations.SET_MATCHING_PAGING, {
        currentPage: data.page,
        pageCount: data.pageCount,
        pageSize: data.pageSize,
        totalResults: data.totalCount
      })

      return data.data
    },

    async [Actions.GET_PAGED_MATCHING] ({ commit, dispatch }, page = 1) {
      commit(Mutations.SET_MATCHING_PAGING, {
        currentPage: page
      })

      await dispatch(Actions.GET_MATCHING)
    },

    async [Actions.GET_MATCHING_FILTER_METADATA] ({ commit }, offeringMemorandaId) {
      const { data } = await LendersMatchingApi.getMatchingFilterMetadata(offeringMemorandaId)

      commit(Mutations.SET_MATCHING_FILTER_METADATA, data)

      return data
    },

    async [Actions.UPDATE_MATCHING_FILTER] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_MATCHING_PAGING, {
        currentPage: 1,
        pageCount: 0,
        pageSize: 25,
        totalResults: 0
      })
      commit(Mutations.SET_MATCHING_FILTER_VALUE, payload)
      await dispatch(Actions.GET_PAGED_MATCHING)
    },

    async [Actions.CLEAR_MATCHING_FILTER] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_MATCHING_PAGING, {
        currentPage: 1,
        pageCount: 0,
        pageSize: 25,
        totalResults: 0
      })

      commit(Mutations.SET_MATCHING_FILTER_VALUE, {})

      await dispatch(Actions.GET_PAGED_MATCHING)
    },

    async [Actions.UPDATE_MATCHING_SORTING] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_MATCHING_PAGING, payload)
      await dispatch(Actions.GET_PAGED_MATCHING)
    },

    async getLendersList ({ commit }) {
      const { data: { lenders } } = await LendersMatchingApi.getLenderList()

      commit('setLendersList', lenders)
    }
  }
}

  import { computed, defineComponent, onMounted, ref } from "vue"

  import { OriginatorsList } from "@/components/Lenders"

  export default defineComponent({
    name: "LendersDashboard",

    components: {
      OriginatorsList
    }
  })

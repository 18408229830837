import { Mutations } from "@/store/enums/StoreEnums"

export default {
  state: {
    modals: new Map(),
    notifications: []
  },

  getters: {
    modals (state) {
      return Object.fromEntries(state.modals)
    },

    notifications (state) {
      return state.notifications
    }
  },

  mutations: {
    [Mutations.SHOW_MODAL] (state, { modal, data }) {
      state.modals.set(modal, data)
    },

    [Mutations.CLOSE_MODAL] (state, { modal }) {
      state.modals.delete(modal)
    },

    [Mutations.SHOW_NOTIFICATION] (state, { text, type }) {
      state.notifications.push({ text, type })
    }
  }
}


  import { defineComponent, ref, toRefs, computed, watch } from "vue"

  import { EyeOffIcon, EyeIcon, XIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'DealDocumentsQueue',

    components: {
      EyeOffIcon,
      EyeIcon,
      XIcon
    },

    props: [
      "queue"
    ],

    emits: [
      'update-documents-queue',
      'remove-document-from-queue'
    ],

    setup (props, { emit }) {
      const changeVisibility = ({ file, name, value }, isVisible) => {
        emit('update-documents-queue', { isVisible, file, name, value })
      }

      const removeFromQueue = (name) => {
        emit('remove-document-from-queue', name)
      }

      return {
        changeVisibility,
        removeFromQueue
      }
    }
  })

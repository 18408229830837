import ApiResponse from "@/core/services/helpers/ApiResponse";
import axios, { AxiosRequestConfig, Method } from "axios";
import PaginatedApiResponse from "@/core/services/helpers/PaginatedApiResponse";
import { App, unref } from "vue";
import VueAxios from "vue-axios";
import { client } from "@/core/plugins/expose-auth0-plugin";

const defaults = {
  contentType: "application/json",
};

const getToken = async() => {
  return await unref(client)?.getAccessTokenSilently();
};

class ApiClient {
  public static vueInstance: App;
  
  public static init(app: App<Element>) {
    ApiClient.vueInstance = app;
    ApiClient.vueInstance.use(VueAxios, axios);
    ApiClient.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  private static async buildConfig(
    method: Method,
    data: any,
    useAuth: boolean,
    contentTypeOverride?: string
  ): Promise<AxiosRequestConfig> {
    const headers = {};
    if (useAuth) {
      const token = await getToken();
      headers["Authorization"] = `Bearer ${token}`;
    }

    if(contentTypeOverride === undefined || contentTypeOverride.length == 0) {
      headers["Content-Type"] = defaults.contentType;
    } else {
      headers["Content-Type"] = contentTypeOverride;
    }

    return {
      method: method,
      headers: headers,
      data: data,
    };
  }

  private static addSlug(url: string, slugParts: string[]): string {
    if(!slugParts || slugParts.length == 0){
      return url;
    }
    const appendChar = url.indexOf('?') >= 0 ? '&' : '?';
    return url + appendChar + slugParts.join('&');
  }

  private static buildUrl(path: string): string {
    return `${ApiClient.vueInstance.axios.defaults.baseURL}/${path}`;
  }

  private static buildNodeUrl(path: string): string {
    return `${process.env.VUE_APP_NODE_API_URL}/${path}`;
  }

  private static buildSortString(
    sortField: string,
    sortDirection: string
  ): string {
    let result = "";
    if (sortField !== undefined && sortField != "") {
      result += `&sortField=${sortField}`;
    }

    if (result != "") {
      result += "&sortDirection=";

      switch (sortDirection?.toLocaleLowerCase()) {
        case undefined:
        case "0":
        case "ascending":
        case "asc":
          result += "ASC";
          break;
        default:
          result += "DESC";
          break;
      }
    }

    return result;
  }

  public static async get<T>(
    path: string,
    useAuth = true,
    contentTypeOverride?: string
  ): Promise<ApiResponse<T>> {
    const url = this.buildUrl(path);
    const config = await this.buildConfig(
      "GET",
      {},
      useAuth,
      contentTypeOverride
    );
    return await axios.get<T, ApiResponse<T>>(url, config);
  }

  public static async getPaged<T>(
    path: string,
    useAuth = true,
    count: number,
    page: number,
    sortField: string,
    sortDirection: string,
    slugParts: string[],
    contentTypeOverride?: string
  ): Promise<PaginatedApiResponse<T>> {
    let url = `${this.buildUrl(path)}?page=${page}&count=${count}`;
    url = this.addSlug(url, slugParts);
    const sortString = this.buildSortString(sortField, sortDirection);
    url += sortString;
    const config = await this.buildConfig(
      "GET",
      {},
      useAuth,
      contentTypeOverride
    );
    return await axios.get<T, PaginatedApiResponse<T>>(url, config);
  }

  public static async post<T>(
    path: string,
    data = {},
    useAuth = true,
    contentTypeOverride?: string
  ): Promise<ApiResponse<T>> {
    const url = this.buildUrl(path);
    const config = await this.buildConfig(
      "POST",
      data,
      useAuth,
      contentTypeOverride
    );
    return await axios.post<T, ApiResponse<T>>(url, data, config);
  }

  public static async postImage<T>(
    path: string,
    formData,
    useAuth = true,
    contentTypeOverride = 'multipart/form-data'
  ): Promise<ApiResponse<T>> {
    const url = this.buildUrl(path);
    const config = await this.buildConfig(
      "POST",
      {},
      useAuth,
      contentTypeOverride
    );

    return await axios.post<T, ApiResponse<T>>(url, formData, config);
  }

  public static async postFile<T>(
    path: string,
    formData,
    useAuth = true,
    contentTypeOverride = 'multipart/form-data'
  ): Promise<ApiResponse<T>> {
    const url = this.buildUrl(path);
    const config = await this.buildConfig(
      "POST",
      {},
      useAuth,
      contentTypeOverride
    );

    return await axios.post<T, ApiResponse<T>>(url, formData, config);
  }

  public static async put<T>(
    path: string,
    data = {},
    useAuth = true,
    contentTypeOverride?: string
  ): Promise<ApiResponse<T>> {
    const url = this.buildUrl(path);
    const config = await this.buildConfig(
      "PUT",
      data,
      useAuth,
      contentTypeOverride
    );
    return await axios.put<T, ApiResponse<T>>(url, data, config);
  }

  public static async patch<T>(
    path: string,
    data = {},
    useAuth = true,
    contentTypeOverride = "application/json-patch+json"
  ): Promise<ApiResponse<T>> {
    const url = this.buildUrl(path);
    const config = await this.buildConfig(
      "PATCH",
      data,
      useAuth,
      contentTypeOverride
    );
    return await axios.patch<T, ApiResponse<T>>(url, data, config);
  }

  public static async delete<T>(
    path: string,
    useAuth = true,
    contentTypeOverride?: string
  ): Promise<ApiResponse<T>> {
    const url = this.buildUrl(path);
    const config = await this.buildConfig(
      "DELETE",
      {},
      useAuth,
      contentTypeOverride
    );
    return await axios.delete<T, ApiResponse<T>>(url, config);
  }

  public static async getFromNode<T>(
    path: string,
    useAuth = false,
    contentTypeOverride?: string
  ): Promise<ApiResponse<T>> {
    const url = this.buildNodeUrl(path);
    const config = await this.buildConfig(
      "GET",
      {},
      useAuth,
      contentTypeOverride
    );
    config.baseURL = '';
    return await axios.get<T, ApiResponse<T>>(url, config);
  }

  public static async postToNode<T>(
    path: string,
    data = {},
    useAuth = true,
    contentTypeOverride?: string
  ): Promise<ApiResponse<T>> {
    const url = this.buildNodeUrl(path);
    const config = await this.buildConfig(
      "POST",
      data,
      useAuth,
      contentTypeOverride
    );
    return await axios.post<T, ApiResponse<T>>(url, data, config);
  }
}

export default ApiClient;

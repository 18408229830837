
  import { defineComponent } from "vue"

  import { CheckIcon } from "@heroicons/vue/solid"

  import WelcomeStep from "./components/Welcome.vue"
  import SignupStep from "./components/Signup.vue"
  import CompanyStep from "./components/CompanyStep.vue"
  import VerificationStep from "./components/VerificationStep.vue"

  export default defineComponent({
    name: "Signup",

    components: {
      WelcomeStep,
      SignupStep,
      CompanyStep,
      VerificationStep,
      CheckIcon
    },

    data () {
      return {
        step: 'WelcomeStep',
        formConfig: {} as any,
        company: null,
        nav: [
          {
            title: 'Read about our process',
            subtitle: 'Get comfortable about the way we work with our lenders',
            key: 'process'
          },
          {
            title: 'Company',
            subtitle: 'Select a company from our list or create a new one',
            key: 'company'
          },
          {
            title: 'Contact information',
            subtitle: 'How can we get back to you?',
            key: 'contacts'
          },
          {
            title: 'Verification',
            subtitle: 'Once verified you will recieve vetted deals based on your credit box from our world class advisory team',
            key: 'approval'
          }
        ]
      }
    },

    methods: {
      handleNextStep (e: any) {
        const steps = {
          'WelcomeStep': 'CompanyStep',
          'CompanyStep': 'SignupStep',
          'SignupStep': 'VerificationStep'
        }

        if (e && e.lenderId) this.company = e

        this.step = steps[this.step]
      },

      stepCompleted (key: string) {
        if (this.step == 'VerificationStep') return ['process', 'company', 'contacts'].includes(key)
        if (this.step == 'SignupStep') return ['process', 'company'].includes(key)
        if (this.step == 'CompanyStep') return key == 'process'

        return false
      },

      stepActive (key: string) {
        const map = {
          'WelcomeStep': 'process',
          'CompanyStep': 'company',
          'SignupStep': 'contacts',
          'VerificationStep': 'approval'
        }
        
        return map[this.step] == key
      }
    }
  })


  import { defineComponent, computed } from "vue"

  import PublicOM from "./public.vue"
  import PrivateOM from "./private.vue"

  import store from "@/store"

  export default defineComponent({
    name: "OfferingMemorandumWrapper",

    components: {
      PrivateOM,
      PublicOM
    },

    setup () {
      const profile = computed(() => store.getters.getCurrentUserProfile)
      
      const display = computed(() => {
        if (!profile.value?.email) return 'PublicOM'
        return 'PrivateOM'
      })

      return {
        profile,
        display
      }
    }
  })

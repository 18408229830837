import { computed, ref, onMounted } from "vue"
import { useStore } from "vuex"

import { Mutations } from "@/store/enums/StoreEnums"

export default function usModals () {
  const store = useStore()
    
  const showModal = (modal: string, data: any = {}) => {
    store.commit(Mutations.SHOW_MODAL, { modal, data })
  }

  const closeModal = (modal: string) => {
    store.commit(Mutations.CLOSE_MODAL, { modal })
  }

  const showNotification = (text: string, type = 'default') => {
    store.commit(Mutations.SHOW_NOTIFICATION, { text, type })
  }

  return {
    showModal,
    closeModal,
    showNotification
  }
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-yellow-50 p-4 md:px-6"
}
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex-shrink-0" }
const _hoisted_5 = { class: "ml-3" }
const _hoisted_6 = { class: "text-sm font-medium text-yellow-800" }
const _hoisted_7 = { class: "mt-1 text-sm text-yellow-700" }
const _hoisted_8 = { class: "flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationIcon = _resolveComponent("ExclamationIcon")!
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ExclamationIcon, { class: "h-5 w-5 text-yellow-400" })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.title), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_XIcon, {
              class: "h-5 w-5 cursor-pointer text-gray-500",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isVisible = false))
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
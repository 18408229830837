import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { DealsApi, OfferingMemorandaApi, AdvisorsApi } from "@/core/services/apis"

import { userHasRole } from "@/core/auth/userHelper"
import { RoleType } from "@/core/auth/permissionEnums"
import moment from "moment"

import axios from "axios"

export default {
  state: {
    om: {},
    deal: {},
    deals: [],
    filterMetadata: {},
    filter: {},
    paging: {
      currentPage: 1,
      pageSize: 25,
      pageCount: 1,
      sortField: "created",
      sortDirection: "ASC",
      totalResults: 0
    },
    distributions: []
  },

  getters: {
    om (state) {
      return state.om
    },

    deal (state) {
      return state.deal
    },

    deals (state) {
      return state.deals
    },

    dealFilterMetadata (state) {
      return state.filterMetadata
    },

    paging (state) {
      return state.paging
    },

    filter (state) {
      return state.filter
    },

    distributions (state) {
      return state.distributions
    }
  },

  mutations: {
    [Mutations.SET_OM_MUTATION] (state, om: []) {
      console.log('Mutations.SET_OM_MUTATION')
      state.om = om
    },

    [Mutations.SET_DEAL_MUTATION] (state, deal: []) {
      console.log('Mutations.SET_DEAL_MUTATION')
      state.deal = deal
    },

    [Mutations.SET_DEALS_MUTATION] (state, deals: []) {
      state.deals = deals
    },

    [Mutations.SET_DEALS_FILTER_METADATA] (state, filterMetadata) {
      state.filterMetadata = filterMetadata

      for (const key in filterMetadata) {
        if (key !== 'sortFields') state.filter[key] = ''
      }
    },

    [Mutations.SET_DEAL_PAGING] (state, paging) {
      for (const key in paging) {
        state.paging[key] = paging[key]
      }
    },

    [Mutations.SET_DEALS_FILTER_VALUE] (state, payload) {
      console.log('SET_DEALS_FILTER_VALUE', payload)
      if (!Object.entries(payload).length) state.filter = {}
      else {
        for (const key in payload) {
          state.filter[key] = payload[key]
        }
      }
    },

    [Mutations.SET_DISTRIBUTIONS] (state, payload) {
      console.log('Mutations.SET_DISTRIBUTIONS')
      state.distributions = payload
    },

    [Mutations.RESET_DEALS] (state) {
      state.filter = {}
      state.paging.currentPage = 1
      state.paging.pageSize = 25
      state.paging.pageCount = 1
      state.paging.sortField = "created"
      state.paging.sortDirection = "DESC"
      state.paging.totalResults = 0
    }
  },

  actions: {
    async [Actions.GET_DEAL] ({ commit, dispatch }, payload) {
      console.log('Actions.GET_DEAL')

      const { id } = payload
      
      const { data } = await DealsApi.getDeal(id)

      commit(Mutations.SET_DEAL_MUTATION, data)

      await dispatch(Actions.GET_OM)
      
      return data
    },

    async [Actions.UPDATE_DEAL] ({ commit }, payload) {
      const { id, summary } = payload
      const { data } = await DealsApi.updateDeal(id, summary)
      
      commit(Mutations.SET_DEAL_MUTATION, data)

      return data
    },

    async [Actions.GET_DEALS] ({ commit, getters }) {
      const { pageSize, currentPage, sortField, sortDirection } = getters.paging
      const filter = getters.filter

      if (getters.getCurrentUserProfile.originatorId) {
        filter.status = ['Sourcing Terms', 'Terms Received', 'LOI Sent']
        filter.CreatedDateMin = moment().subtract(6, 'months').toISOString()
      }

      const { data } = await DealsApi.getPagedDeals(
        pageSize,
        currentPage,
        sortField,
        sortDirection,
        filter
      )

      console.log(data)

      commit(Mutations.SET_DEALS_MUTATION, data.data)
      commit(Mutations.SET_DEAL_PAGING, {
        currentPage: data.page,
        pageCount: data.pageCount,
        pageSize: data.pageSize,
        totalResults: data.totalCount
      })

      return data.data
    },

    async [Actions.GET_PAGED_DEALS] ({ commit, dispatch }, page = 1) {
      commit(Mutations.SET_DEAL_PAGING, {
        currentPage: page
      })

      await dispatch(Actions.GET_DEALS)
    },

    async [Actions.GET_PAGED_RECOMMENDATIONS] ({ commit, dispatch }, page = 1) {
      commit(Mutations.SET_DEAL_PAGING, {
        currentPage: page
      })

      await dispatch(Actions.GET_RECOMMENDATIONS)
    },

    async [Actions.GET_RECOMMENDATIONS] ({ commit, getters }) {
      const { pageSize, currentPage, sortField, sortDirection } = getters.paging

      const { data } = await DealsApi.getPagedRecommendations(
        10,
        currentPage,
        sortField,
        sortDirection,
        {},
        getters.getCurrentUserProfile.originatorId
      )

      commit(Mutations.SET_DEALS_MUTATION, data.data)
      commit(Mutations.SET_DEAL_PAGING, {
        currentPage: data.page,
        pageCount: data.pageCount,
        pageSize: data.pageSize,
        totalResults: data.totalCount
      })

      return data.data
    },

    async [Actions.GET_DEAL_FILTER] ({ commit }, payload) {
      const { data } = await DealsApi.getDealFilterMetadata()

      commit(Mutations.SET_DEALS_FILTER_METADATA, data)
      
      return data
    },

    async [Actions.UPDATE_DEALS_FILTER] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_DEALS_FILTER_VALUE, payload)
      await dispatch(Actions.GET_PAGED_DEALS)
    },

    async [Actions.UPDATE_DEALS_SORTING] ({ commit, dispatch }, payload) {
      commit(Mutations.SET_DEAL_PAGING, payload)
      await dispatch(Actions.GET_PAGED_DEALS)
    },

    async [Actions.CREATE_OM] ({ commit }, payload) {
      const { data } = await OfferingMemorandaApi.createOM(payload)

      commit(Mutations.SET_OM_MUTATION, data)

      return data
    },

    async [Actions.GET_OM] ({ commit, getters, dispatch }, id) {
      console.log('Actions.GET_OM')

      if (!id) id = getters.deal.offeringMemorandumId

      if (id) {
        const { data } = await OfferingMemorandaApi.getOM(id)

        commit(Mutations.SET_OM_MUTATION, data)

        await dispatch(Actions.GET_DISTRIBUTIONS, data.id)
      } else {
        commit(Mutations.SET_OM_MUTATION, {})
      }
    },

    async [Actions.GET_UNDISTRIBUTED_OM] ({ commit, getters, dispatch }, id) {
      console.log('Actions.GET_UNDISTRIBUTED_OM')

      const { data } = await OfferingMemorandaApi.getOM(id)

      commit(Mutations.SET_OM_MUTATION, data)
    },

    async [Actions.VIEW_OM] ({ commit, getters, dispatch }, { omId, distributionId, shareCode }) {
      console.log('Actions.VIEW_OM')

      const { data } = await OfferingMemorandaApi.viewOM(omId, distributionId, shareCode)

      commit(Mutations.SET_OM_MUTATION, data.offeringMemorandum)

      if (userHasRole(RoleType.ADVISOR)) await dispatch(Actions.GET_DISTRIBUTIONS, data.offeringMemorandum.id)
      if (userHasRole(RoleType.ORIGINATOR)) await dispatch(Actions.GET_ORIGINATOR_USER_OM_DISTRIBUTIONS, omId)
    },

    async [Actions.UPDATE_OM] ({ commit, dispatch }, payload) {
      const { data } = await OfferingMemorandaApi.updateOM(payload)

      commit(Mutations.SET_OM_MUTATION, data)

      return data
    },

    async [Actions.GET_DISTRIBUTIONS] ({ commit }, offeringMemorandaId) {
      console.log('Actions.GET_DISTRIBUTIONS')

      const { data } = await OfferingMemorandaApi.getDistributions(offeringMemorandaId)

      commit(Mutations.SET_DISTRIBUTIONS, data)
    },

    async [Actions.CREATE_DISTRIBUTIONS] ({ commit }, { offeringMemorandaId, originatorIds }) {
      const { data } = await OfferingMemorandaApi.createDistributions(offeringMemorandaId, originatorIds)
      
      commit(Mutations.SET_DISTRIBUTIONS, data)
    },

    async [Actions.UPDATE_DISTRIBUTIONS] ({ commit }, { offeringMemorandaId, originatorIds }) {
      const { data } = await OfferingMemorandaApi.updateDistributions(offeringMemorandaId, originatorIds)
      
      commit(Mutations.SET_DISTRIBUTIONS, data)
    },

    async [Actions.GET_DISTRIBUTION_EMAIL] (ctx, { offeringMemorandaId, distributionId }) {
      const { data } = await OfferingMemorandaApi.getEmail(offeringMemorandaId, distributionId)

      return data
    },

    async [Actions.SEND_DISTRIBUTION_EMAIL] ({ dispatch }, { offeringMemorandumId, distributionId, payload }) {
      const { data } = await OfferingMemorandaApi.sendEmail(offeringMemorandumId, distributionId, payload)

      await dispatch(Actions.GET_DISTRIBUTIONS, offeringMemorandumId)

      return data
    },

    async [Actions.UPLOAD_PICTURE] (ctx, { id, formData }) {
      const { data } = await OfferingMemorandaApi.uploadImage(id, formData)

      return data
    },

    async [Actions.UPLOAD_DOCUMENT] (ctx, { id, formData }) {
      const { data } = await OfferingMemorandaApi.uploadDocument(id, formData)

      return data
    },

    async [Actions.DELETE_DOCUMENT] (ctx, { offeringMemorandumId, documentId }) {
      const { data } = await OfferingMemorandaApi.deleteDocument(offeringMemorandumId, documentId)

      return data
    },

    async [Actions.REMOVE_PICTURE] (ctx, { offeringMemorandumId, pictureId }) {
      const { data } = await OfferingMemorandaApi.removeImage(offeringMemorandumId, pictureId)

      return data
    },

    async [Actions.CREATE_DEAL] (ctx, payload) {
      const { data } = await DealsApi.createDeal(payload)

      return data
    },

    async [Actions.BULK_SEND_EMAIL] ({ dispatch }, { offeringMemorandumId, distributionIds }) {
      const { data } = await OfferingMemorandaApi.bulkSendEmail(offeringMemorandumId, distributionIds)

      await dispatch(Actions.GET_DISTRIBUTIONS, offeringMemorandumId)
      
      return true
    },

    async [Actions.GET_ADVISORS] (ctx) {
      const { data } = await AdvisorsApi.getPagedAdvisors(50, 1, 'Name', 'ASC')

      return data.data
    },

    async [Actions.PREVIEW_OM] (ctx, { offeringMemorandumId, shareCode }) {
      console.log('Actions.PREVIEW_OM')
      
      const { data } = await OfferingMemorandaApi.previewOM(offeringMemorandumId, shareCode)

      return data
    },

    async [Actions.SET_STAGE] (ctx, { deal }) {
      if (["New", "Contacted", "Contact Attempted"].includes(deal.status)) {
        const { data } = await OfferingMemorandaApi.setStage(deal.hubspotID)
      }
      return true
    }
  }
}


  import { defineComponent, ref, computed } from "vue"
  import { getRoles, userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: "LayoutUserMenu",

    setup() {
      const nav = {
        advisor: [
          {
            path: '/dashboard',
            label: 'Dashboard',
            key: 'dashboard'
          },
          {
            path: '/deals',
            label: 'Deals',
            key: 'deals'
          },
          {
            path: '/lenders',
            label: 'Lenders',
            key: 'lenders'
          },
          {
            path: '/originators',
            label: 'Originators',
            key: 'originators'
          }
        ],
        originator: [
          {
            path: '/dashboard',
            label: 'Dashboard',
            key: 'dashboard'
          },
          // {
          //   path: '/deals',
          //   label: 'Deals',
          //   key: 'deals'
          // }
        ],
        broker: [
          {
            path: '/dashboard',
            label: 'Dashboard',
            key: 'dashboard'
          },
          {
            path: '/lenders',
            label: 'Lenders',
            key: 'lenders'
          },
          {
            path: '/originators',
            label: 'Originators',
            key: 'originators'
          }
        ]
      }

      const displayedNav = computed(() => {
        if (userHasRole(RoleType.ADVISOR)) return nav.advisor
        if (userHasRole(RoleType.BROKER)) return nav.broker
        return nav.originator
      })

      return {
        nav: displayedNav
      }
    },
  })

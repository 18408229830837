
  import { defineComponent, ref, computed } from "vue"
  import { useStore } from "vuex"

  import moment from 'moment'

  export default defineComponent({
    name: "BrokerDashboard",

    methods: {
      moment (date) {
        return moment(date).fromNow();
      }
    },

    setup () {
      const store = useStore()

      const loading = ref<boolean>(true)

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      return {
        loading,
        profile
      }
    }
  })

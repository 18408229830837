
  import { computed, defineComponent, onMounted, ref } from "vue"

  import { LendersList } from "@/components/Lenders"

  import { useModals } from "@/composables"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: "LendersDashboard",

    components: {
      LendersList
    },

    setup () {
      const { showModal } = useModals()

      return {
        showModal,
        userHasRole,
        RoleType
      }
    }
  })

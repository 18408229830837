import ApiClient from "@/core/services/ApiClient";
import ApiResponse from "../helpers/ApiResponse";

const basePath = "advisors/shortlists";

class ShortListsApi {
  public static async getPaged(
    count: number,
    page: number,
    sortField: string,
    sortDirection: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getPaged(
      `${basePath}/paged`,
      true,
      count,
      page,
      sortField,
      sortDirection,
      [],
    )
  }

  public static async createShortList(payload: any): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}`, payload);
  }

  public static async updateShortList(payload: any): Promise<ApiResponse<any>> {
    return await ApiClient.put<any>(`${basePath}`, payload);
  }

  public static async deleteShortList (shortListId: string): Promise<ApiResponse<any>> {
    return await ApiClient.delete<any>(
      `${basePath}/${shortListId}`
    )
  }
}

export default ShortListsApi;

export default {
  'SBA7a': 'Sba 7(A)',
  'SBA504': 'Sba 504',
  'FannieSmall': 'Fannie Small',
  'FannieDUS': 'Fannie Dus',
  'Optigo': 'Optigo (Freddie Small)',
  'FreddieConventional': 'Freddie Conventional',
  'HUD232': 'Hud 232',
  'HUD221d4': 'Hud 221(D)(4)',
  'HUD223f': 'Hud 223F',
  'HUD223a7': 'HUD223a7',
  'HUD241a': 'HUD241a',
  'BalanceSheet': 'Balance Sheet',
  'HardMoney': 'Hard Money',
  'CMBS': 'Cmbs',
  'NonBankBalanceSheet': 'Non Bank Balance Sheet',
  'Bridge': 'Bridge/Transitional',
  'MezzanineAndPrefEquity': 'Mezzanine And Pref Equity',
  'LPGPEquity': 'Lp/Gp Equity',
  'Construction': 'Construction',
  'LinesOfCredit': 'Lines Of Credit',
  'LowIncomeHousingTaxCredits': 'Low Income Housing Tax Credits',
  'SaleOrLeaseback': 'Sale/Leaseback Financing',
  'BNote': 'B-Note'
}
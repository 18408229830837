import ApiClient from "@/core/services/ApiClient";
import ApiResponse from "../helpers/ApiResponse";

const basePath = "originators";

class OriginatorDistributionsApi {
  public static async getPagedOriginatorDistributions(
    count: number,
    page: number,
    sortField: string,
    sortDirection: string,
    originatorId: string,
    offeringMemorandumId: string | null
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getPaged<any>(
      `${basePath}/${originatorId}/distributions/paged`,
      true,
      count,
      page,
      sortField,
      sortDirection,
      OriginatorDistributionsApi.buildQuerySlug(
        {
          distributionStatus: ['SentMessage', 'ReceivedMessage', 'OpenedMessage', 'Viewed', 'Interested', 'Quoted', 'NotInterested', 'UndeliverableMessage', 'Accepted','InformationRequested'],
          offeringMemorandumId
        }
      )
    );
  }

  private static buildQuerySlug(filters: any): string[] {
    if (filters == null) return []

    const slugParts: string[] = []

    if (Object.entries(filters).length) {
      Object.entries(filters).forEach((item: any) => {
        if (!item[1]) return
        else if (item[0] === 'loanAmountRange') {
          if (item[1]?.minimumAmount) slugParts.push(`LoanAmountRange.MinimumAmount=${item[1]?.minimumAmount}`)
          if (item[1]?.maximumAmount) slugParts.push(`LoanAmountRange.MaximumAmount=${item[1]?.maximumAmount}`)
        } else if (Array.isArray(item[1])) {
          slugParts.push(`${item[0]}=${item[1].join(`&${item[0]}=`)}`)
        } else if (item[1] instanceof Set) {
          if (Array.from(item[1]).length) slugParts.push(`${item[0]}=${Array.from(item[1]).join(`&${item[0]}=`)}`)
        } else if (item[1]) slugParts.push(`${item[0]}=${item[1]}`)
      })
    }

    return slugParts
  }

  public static async getOriginatorDistributionsFilterMetadata(
    originatorId: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/${originatorId}/distributions/filter-metadata`);
  }

  public static async submitQuote(
    originatorId: string,
    payload: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}/${originatorId}/quote`, payload)
  }

  public static async updateQuote(
    originatorId: string,
    payload: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.put<any>(`${basePath}/${originatorId}/quote`, payload)
  }

  public static async submitQuoteReject(
    originatorId: string,
    distributionId: string,
    payload: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}/${originatorId}/distributions/${distributionId}/feedback`, payload)
  }

  public static async getOriginatorSettings(
    originatorId: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(`${basePath}/settings`)
  }

  public static async updateOriginatorSettings(
    originatorId: string,
    payload: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}/settings`, payload)
  }

  public static async inquire(
    originatorId: string,
    payload: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.post<any>(`${basePath}/${originatorId}/inquire`, payload)
  }
}

export default OriginatorDistributionsApi;


  import { defineComponent, toRefs, ref, onMounted, onBeforeUnmount, computed, watch } from "vue"

  import { useTypes } from "@/composables"

  import { ChevronDownIcon, ChevronUpIcon, CheckCircleIcon } from "@heroicons/vue/solid"
  
  import { SvgMapStates, SvgMapBorders } from "@/core/config"

  export default defineComponent({
    name: "CountiesSelector",

    emits: ['updated'],

    props: ['data'],

    components: {
      ChevronDownIcon,
      ChevronUpIcon,
      CheckCircleIcon
    },

    setup (props, { emit }) {
      const { data } = toRefs(props)

      const nationwide = ref<boolean>(false)

      const states = ref(SvgMapStates)
      const borders = ref(SvgMapBorders)
      const isMounted = ref(false)
      const containerWidth = ref(959)
      const container = ref<any>(null)

      const searchQuery = ref<string>("")

      const scale = computed(() => {
        if (!isMounted.value) return 0
        else return containerWidth.value / 959
      })

      const updateWidth = () => {
        containerWidth.value = container.value?.offsetWidth
      }

      onMounted(() => {
        containerWidth.value = container.value?.offsetWidth

        isMounted.value = true

        window.addEventListener('resize', updateWidth)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('resize', updateWidth)
      })

      const { footprintsNested } = useTypes()

      const collectedData = ref<any>({})

      footprintsNested.value.forEach(({ stateCode, types }) => {
        collectedData.value[stateCode] = {
          isVisible: false,
          selected: new Set(types.filter((item: any) => data.value.includes(item.id)).map((item:any) => item.id)),
          countiesAmount: types ? types.length : 1
        }
      })

      const filteredFootprints = computed(() => {
        if (searchQuery.value === "") {
          return footprintsNested.value
        }

        return footprintsNested.value.filter(({ stateCode, description, types }) => {
          const searchTerm = searchQuery.value.toLowerCase()
          const stateMatch = stateCode.toLowerCase().includes(searchTerm) || description.toLowerCase().includes(searchTerm)

          if (stateMatch) {
            return true
          }

          if (types) {
            return types.some(({ id, description: county }) => {
              return id.toLowerCase().includes(searchTerm) || county.toLowerCase().includes(searchTerm)
            })
          }

          return false
        })
      })

      const updateValue = (stateCode, id) => {
        if (collectedData.value[stateCode].selected.has(id)) {
          collectedData.value[stateCode].selected.delete(id)
        } else {
          collectedData.value[stateCode].selected.add(id)
        }
      }

      const selectState = (stateCode, types) => {
        if (types) {
          collectedData.value[stateCode].selected = collectedData.value[stateCode].selected.size == types.length ? new Set([]) : new Set(types.map(item => item.id))
        } else {
          const stateFootprint = footprintsNested.value.find(fp => fp.stateCode === stateCode)
          if (stateFootprint && stateFootprint.types) {
            const allCountyIds = stateFootprint.types.map(item => item.id)
            collectedData.value[stateCode].selected = collectedData.value[stateCode].selected.size === allCountyIds.length ? new Set([]) : new Set(allCountyIds)
          } else {
            collectedData.value[stateCode].selected = collectedData.value[stateCode].selected.size ? new Set([]) : new Set([stateCode])
          }
        }
      }

      const handleNationwide = () => {
        nationwide.value = !nationwide.value

        if (nationwide.value) {
          footprintsNested.value.forEach(({ stateCode, types }) => {
            collectedData.value[stateCode].selected = new Set(types ? types.map(item => item.id) : [stateCode])
          })
        } else {
          footprintsNested.value.forEach(({ stateCode }) => {
            collectedData.value[stateCode].selected = new Set([])
          })
        }
      }

      const shouldHighlight = (id: string, description: string): boolean => {
        if (!searchQuery.value.trim()) {
          return false
        }

        const searchTerm = searchQuery.value.trim().toLowerCase()
        
        return id.toLowerCase().includes(searchTerm) || description.toLowerCase().includes(searchTerm)
      }

      watch(collectedData, () => {
        const selectedCodes:any[] = []

        for (const stateCode in collectedData.value) {
          collectedData.value[stateCode].selected.forEach((code: any) => {
            selectedCodes.push(code)
          })
        }

        emit('updated', selectedCodes)
      }, { deep: true })

      return {
        nationwide,
        collectedData,
        footprintsNested,
        updateValue,
        selectState,
        states,
        borders,
        containerWidth,
        scale,
        container,
        handleNationwide,
        searchQuery,
        filteredFootprints,
        shouldHighlight
      }
    }
  })

import ApiClient from "@/core/services/ApiClient";
import ApiResponse from "../helpers/ApiResponse";

const basePath = "offeringmemoranda/match";

class LendersMatchingApi {

  public static async getMatchingFilterMetadata (
    offeringMemorandaId: string
  ): Promise<ApiResponse<any>> {
    return await ApiClient.get<any>(
      `${basePath}/filter-metadata${offeringMemorandaId ? '?offeringMemorandaId=' + offeringMemorandaId : ''}`
    )
  }

  public static async getPagedMatching(
    count: number,
    page: number,
    sortField: string,
    sortDirection: string,
    filters: any
  ): Promise<ApiResponse<any>> {
    return await ApiClient.getFromNode<any>(
      `${basePath}?pageSize=${count}&page=${page}&${LendersMatchingApi.buildQuerySlug(filters).join('&')}`,
      true
    );
  }

  public static async getLenderList(): Promise<ApiResponse<any>> {
    return await ApiClient.getFromNode<any>(`public/lenders`)
  }

  // public static async getPagedMatching(
  //   count: number,
  //   page: number,
  //   sortField: string,
  //   sortDirection: string,
  //   filters: any
  // ): Promise<ApiResponse<any>> {
  //   return await ApiClient.getPaged<any>(
  //     `${basePath}`,
  //     true,
  //     count,
  //     page,
  //     sortField,
  //     sortDirection,
  //     LendersMatchingApi.buildQuerySlug(filters)
  //   )
  // }

  private static buildQuerySlug(filters: any): string[] {
    if (filters == null) return []

    const slugParts: string[] = ['showDeleted=true']

    if (Object.entries(filters).length) {
      Object.entries(filters).forEach((item: any) => {
        if (item[0] === 'loanAmountRange') {
          if (item[1]?.minimumAmount) slugParts.push(`LoanAmountRange.MinimumAmount=${item[1]?.minimumAmount}`)
          if (item[1]?.maximumAmount) slugParts.push(`LoanAmountRange.MaximumAmount=${item[1]?.maximumAmount}`)
        } else if (item[0] === 'balanceSheetRange') {
          if (item[1]?.minimumBalance) slugParts.push(`BalanceSheetRange.MinimumBalance=${item[1]?.minimumBalance}`)
          if (item[1]?.maximumBalance) slugParts.push(`BalanceSheetRange.MaximumBalance=${item[1]?.maximumBalance}`)
        } else if (item[0] === 'nearby') {
          if (item[1]?.distance) {
            slugParts.push(`Nearby.DistanceMiles=${item[1]?.distance}`)
            slugParts.push(`Nearby.Longitude=${item[1]?.longitude}`)
            slugParts.push(`Nearby.Latitude=${item[1]?.latitude}`)
          }
        } else if (Array.isArray(item[1])) {
          slugParts.push(`${item[0]}=${item[1].join(`&${item[0]}=`)}`)
        } else if (item[1] instanceof Set) {
          if (Array.from(item[1]).length) slugParts.push(`${item[0]}=${Array.from(item[1]).join(`&${item[0]}=`)}`)
        } else if (item[1] || item[1] === false) slugParts.push(`${item[0]}=${item[1]}`)
      })
    }

    return slugParts
  }

}

export default LendersMatchingApi


  import { defineComponent, ref, toRefs, watch, computed, onMounted } from "vue"
  import { useStore } from "vuex"
  import { useRoute } from "vue-router"
  
  import {
    XIcon, EyeIcon,
    PencilIcon, StarIcon, BanIcon,
    ShieldCheckIcon
  } from "@heroicons/vue/solid"
  
  import getTags from "@/core/helpers/getTags"
  import { convertToCurrency, convertToShortCurrency } from "@/core/helpers/convertToCurrency"

  import { useModals } from "@/composables"

  import { Actions } from "@/store/enums/StoreEnums"

  import { CreditBox, CreditBoxNavigation } from "@/components/CreditBox"
  import { OriginatorOverview } from "@/components/Originator"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"

  export default defineComponent({
    name: 'ModalOriginator',

    emits: ['closed', 'updated'],

    props: {
      originator: {
        type: Object,
        default () {
          return {}
        }
      },
      product: {
        type: String,
        default () {
          return ''
        }
      }
    },

    components: {
      XIcon,
      EyeIcon,
      PencilIcon,
      StarIcon,
      BanIcon,
      CreditBox,
      CreditBoxNavigation,
      OriginatorOverview,
      ShieldCheckIcon
    },

    setup(props, { emit }) {
      const route = useRoute()
      const loading = ref(true)

      const { showModal, closeModal, showNotification } = useModals()

      const { originator } = toRefs<any>(props)

      const store = useStore()

      const localOriginator = ref<any>(originator.value)
      const storeOriginator = computed(() => store.getters.originator)

      const lender = ref<any>({})

      watch(storeOriginator, () => {
        if (storeOriginator.value.id == localOriginator.value.id) {
          localOriginator.value = storeOriginator.value
        }
      }, { deep: true })

      onMounted(async () => {
        lender.value = await store.dispatch(Actions.GET_LENDER, {
          id: originator.value.lenderId
        })
      })

      const updateRating = async (rating) => {
        const payload = {
          id: localOriginator.value.id,
          rating
        }
        
        try {
          if (localOriginator.value.rating) localOriginator.value = await store.dispatch(Actions.UPDATE_RATING, payload)
          else localOriginator.value = await store.dispatch(Actions.SET_RATING, payload)
          showNotification(`${localOriginator.value.name}'s rating was updated`)
        } catch (error: any) {
          console.log(error)
          showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
        }
      }

      const activeTabIndex = ref<number>(0)

      const creditBoxes = computed(() => {
        return Object.entries(localOriginator.value.creditBoxes).map(([key, value]) => {
          return {
            name: key,
            value
          }
        })
      })

      return {
        closeModal,
        showModal,
        getTags,
        loading,
        convertToCurrency,
        localOriginator,
        lender,
        convertToShortCurrency,
        updateRating,
        creditBoxes,
        activeTabIndex,
        userHasRole,
        RoleType
      }
    }
  })

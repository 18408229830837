import { Actions, Mutations } from "@/store/enums/StoreEnums"

import { OriginatorsPublicApi } from "@/core/services/apis"


export default {
  state: {
    originator: null,
    lender: null,
    closedDeals: [],
    softQuotes: []
  },

  getters: {
    publicOriginator (state) {
      return state.originator
    },
    publicLender (state) {
      return state.lender
    },
    publicClosedDeals (state) {
      return state.closedDeals
    },
    publicSoftQuotes (state) {
      return state.softQuotes
    }
  },

  mutations: {
    [Mutations.SET_PUBLIC_ORIGINATOR] (state, data: any) {
      console.log(data)
      
      const { originator, lender, deals, softQuotes } = data
      
      state.originator = originator
      state.lender = lender
      state.closedDeals = deals
      state.softQuotes = softQuotes
    }
  },

  actions: {
    async [Actions.GET_PUBLIC_ORIGINATOR] ({ commit }, payload) {
      const { id } = payload
      const { data } = await OriginatorsPublicApi.getOriginator(id)

      commit(Mutations.SET_PUBLIC_ORIGINATOR, data)
      
      return data
    }
  }
}

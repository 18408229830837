
  import { defineComponent, computed, onMounted } from "vue"

  import store from "@/store"

  import * as modals from "@/components/Modals"

  import { useModals } from "@/composables"

  export default defineComponent({
    name: "ModalsBar",

    components: {...modals},

    setup () {
      const { showModal } = useModals()

      const modals = computed(() => {
        return store.getters.modals
      })

      return {
        modals
      }
    }
  })

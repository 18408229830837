export enum UserActions {
    GET_USERS = "getPagedUsers",
    GET_USER_FILTER = "getUserFilter",
    GET_USER = "getUser",
    GET_USER_PROFILE = "getUserProfile",
    UPDATE_USER_FULL = "updateUserFull",
    UPDATE_USER_PARTIAL = "updateUserPartial",
    UPDATE_USER = "updateUser",

    GET_ACCESS_TOKEN = "getAccessToken",
    LOGGED_IN = "loggedIn",
    LOGOUT = "logout",
    GET_TYPES = "getTypes",
    UPDATE_ADVISOR = "updateAdvisor"
}
export enum UserMutations {
    SET_USERS_MUTATION = "setUsers",
    SET_USERS_FILTER_METADATA = "setUsersFilterMetadata",
    SET_USER_MUTATION = "setUser",
    SET_USER_PROFILE_MUTATION = "setUserProfile",
    SET_TYPES = "setTypes"
}

import posthog, { PostHog } from 'posthog-js'

const PostHogTracker = {
	init (userId) {
        const apiKey = (window.self !== window.top) ? 'phc_rdmY3FOg0pJvUsz5QCCQaWLZIJMg0LxVymzElGdQwWh' : process.env.VUE_APP_POSTHOG_KEY; // todo: fix this lender hack
		posthog.init(apiKey, {
            // api_host: process.env.VUE_APP_POSTHOG_HOST,
            api_host: "https://revonaj.janover.co",
            ui_host: "https://app.posthog.com",
            autocapture: true,
            capture_pageview: false,
            debug: process.env.VUE_APP_POSTHOG_DEBUG == 'true',
            loaded: (posthog: PostHog) => {
                userId && posthog.identify(userId);
            },
        })
	},

	capture (event, payload) {
		posthog.capture(event, payload)
	}	
}
export default PostHogTracker

    import { defineComponent, ref, toRefs, computed, onMounted } from "vue"
  
    import { useModals } from "@/composables"
  
    import { XIcon } from "@heroicons/vue/solid"

    import ChatComponent from '@/components/Chat/index.vue'
  
    export default defineComponent({
      name: 'ModalGetQuotePublic',
  
      components: {
        XIcon,
        ChatComponent
      },

      props: ['originator'],
  
      setup() {
        const { closeModal } = useModals()
  
        return {
          closeModal
        }
      }
    })
  
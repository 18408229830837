
  import { defineComponent, ref, toRefs, onMounted, computed, watch } from "vue"
  import store from "@/store"
  import { PostHogTracker } from "@/core/services/trackers"

  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals } from "@/composables"

  export default defineComponent({
    name: 'ModalTermsAndConditions',

    emits: ['closed'],

    setup(props, { emit }) {
      const { closeModal, showModal } = useModals()

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      const updateTerms = async () => {
        const payload = {
          termsAccepted: new Date(),
          termsVersion: '0'
        }

        const keys = [
          "id", "lenderID", "borrowerId",
          "originatorId", "advisorId",
          "termsAccepted", "termsVersion",
          "locale", "userName", "nickName",
          "firstName", "fullName", "lastName", "picture"
        ]

        for (let key of keys) {
          if (profile.value[key]) payload[key] = profile.value[key]
        }

        try {
          const response = await store.dispatch(Actions.UPDATE_USER, payload)

          console.log(response)
          
          PostHogTracker.capture('Terms Accepted',{})

          if (response.termsAccepted) closeModal('ModalTermsAndConditions')
        } catch (error) {
          console.log(error)
        }
      }

      return {
        closeModal,
        updateTerms,
        privacyPolicy: process.env.VUE_APP_PRIVACY_POLICY,
        termsAndConditions: process.env.VUE_APP_TERMS_AND_CONDITIONS
      }
    }
  })

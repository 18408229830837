
  import { defineComponent, computed, ref, toRefs } from "vue"

  import { DealEditorField } from "@/components/Deal"

  import { onClickOutside } from '@vueuse/core'
  import { SelectorIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: "AddDataPoint",

    props: [
      'questions',
      'data'
    ],

    emits: ['updated'],

    components: {
      DealEditorField,
      SelectorIcon
    },

    setup (props, { emit }) {
      const isOpened = ref<boolean>(false)
      const target = ref<any>(null)
      const search = ref<string>('')

      const questions = ref(props.questions)
      const data = ref(props.data)

      const collectedData = ref<any>(null)

      const availableDataPoints = computed(() => {
        const obj = {}

        if (questions.value && Object.values(questions.value)) {
          for (let key in questions.value) {
            if (!['document', 'start', 'end'].includes(questions.value[key]?.type) &&
              questions.value[key]?.subtitle !== 'FINAL' &&
              !questions.value[key].isDuplicate &&
              questions.value[key].flow !== 'lender-onboarding-flow') {
              obj[key] = questions.value[key]
            }
          }
        }

        return Object.values(obj).filter((question: any) => {
          return !data.value.filter((item: any) => item.name === question.name).length
        }).reverse()
      })

      const activeQuestion = ref<any>(null)

      onClickOutside(target, () => {
        isOpened.value = false
        if (!activeQuestion.value) search.value = ''
      })

      const toggle = () => {
        if (isOpened.value) {
          isOpened.value = false
          if (!activeQuestion.value) search.value = ''
        } else {
          isOpened.value = true
        }
      }

      const options = computed(() => {
        let query = JSON.parse(JSON.stringify(search.value)).toLowerCase()
        
        return availableDataPoints.value.filter((item: any) => {
          return item.name.toLowerCase().includes(query)
        })
      })

      const select = (value) => {
        activeQuestion.value = value
        search.value = value.name

        if (value.type === 'selector') onFieldChanged({ isVisible: false, name: value.name, value: value.options[0] })
      }

      const onFieldChanged = ({ isVisible, name, value }) => {
        collectedData.value = { isVisible, name, value }
      }

      const save = () => {
        emit('updated', collectedData.value)
        collectedData.value = null
        activeQuestion.value = null
        search.value = ''
      }

      const cancel = () => {
        collectedData.value = {}
        activeQuestion.value = null
        search.value = ''
      }

      return {
        isOpened,
        target,
        search,
        options,
        toggle,
        select,
        activeQuestion,
        onFieldChanged,
        collectedData,
        save,
        cancel
      }
    }
  })

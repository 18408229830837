import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-10" }
const _hoisted_2 = { class: "max-w-7xl mx-auto" }
const _hoisted_3 = { class: "text-lg md:text-3xl font-bold text-white capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_deal_lender_steps_horisontal = _resolveComponent("deal-lender-steps-horisontal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.name), 1)
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_base_spinner, {
          key: 0,
          class: "h-80 rounded-lg shadow-sm"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_deal_lender_steps_horisontal, { class: "mb-4 md:mb-6" }),
          _createVNode(_component_router_view)
        ], 64))
  ], 64))
}
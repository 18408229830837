
  import axios from "axios"
  import { defineComponent, ref, computed, watch } from "vue"
  import { useModals } from "@/composables"
  import { PostHogTracker } from "@/core/services/trackers"

  export default defineComponent({
    name: "SignupFormStep",

    props: ['company'],

    emits: ['nextStep'],

    setup (props, { emit }) {
      const isLoading = ref<boolean>(false)

      const { showNotification } = useModals()

      const formConfig = ref<any>({
        title: 'Please fill out your contact information',
        collectedData: {
          name: '',
          email: '',
          phone: '',
          password: '',
          lenderId: props.company.lenderId,
          creditBoxes: {}
        }
      })

      const termsAccepted = ref<boolean>(false)

      const isValidName = computed(() => {
        if (!formConfig.value.collectedData.name) return true
        return formConfig.value.collectedData.name.split(' ').length > 1
      })

      const isValidPassword = computed(() => {
        if (!formConfig.value.collectedData.password) return true
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        return regex.test(formConfig.value.collectedData.password)
      })

      const isValidEmail = computed(() => {
        if (!formConfig.value.collectedData.email) return true
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return regex.test(formConfig.value.collectedData.email)
      });


      const formIsValid = computed(() => {
        let isValid = true

        if (!termsAccepted.value) return false

        if (!isValidName.value) return false

        if (!isValidPassword.value) return false

        if (!isValidEmail.value) return false

        for (let key in formConfig.value.collectedData) {
          if (!formConfig.value.collectedData[key]) isValid = false
        }

        return isValid
      })

      const handleFormSubmit = async () => {
        if (!isLoading.value) {
          if (formIsValid.value) {
            isLoading.value = true;

            try {
              const { data } = await axios.post(
                `${process.env.VUE_APP_NODE_API_URL}/signup`,
                formConfig.value.collectedData
              )

              PostHogTracker.init(undefined)

              if (data.success) {
                PostHogTracker.capture('Originator Signup', {
                  name: formConfig.value.collectedData.name,
                  email: formConfig.value.collectedData.email,
                  lenderId: formConfig.value.collectedData.lenderId,
                  phone: formConfig.value.collectedData.phone
                })

                emit('nextStep')
              } else if (data.message) {
                PostHogTracker.capture('Originator Signup Failed', {
                  name: formConfig.value.collectedData.name,
                  email: formConfig.value.collectedData.email,
                  lenderId: formConfig.value.collectedData.lenderId,
                  phone: formConfig.value.collectedData.phone
                })

                showNotification(data.message, 'error')
              }
            } catch (error: any) {
              console.log(error)

              PostHogTracker.capture('Originator Signup Failed', {
                name: formConfig.value.collectedData.name,
                email: formConfig.value.collectedData.email,
                lenderId: formConfig.value.collectedData.lenderId,
                phone: formConfig.value.collectedData.phone
              })

              if (error.response && error.response.data && error.response.data.message) {
                showNotification(error.response.data.message, 'error')
              } else {
                showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
              }
            } finally {
              isLoading.value = false;
            }
          }
        }
      }

      return {
        isLoading,
        formConfig,
        handleFormSubmit,
        formIsValid,
        isValidPassword,
        isValidName,
        isValidEmail,
        termsAccepted
      }
    }
  })

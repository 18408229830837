
  import { defineComponent } from "vue"

  export default defineComponent({
    name: "BaseAvatar",
    
    props: {
      image: String,
      placeholder: String,
      size: {
        type: String,
        default: '8'
      },
      font: {
        type: String,
        default: 'sm'
      },
      circle: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'gray-500'
      }
    }
  })

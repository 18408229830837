const DealGridConfig = [
  {
    name: "Customer",
    key: "customer",
    sortable: true,
    width: "min-w-150px"
  },
  {
    name: "Loan Amount",
    key: "amount",
    sortable: false,
  },
  {
    name: "Loan Purpose",
    key: "purpose",
    sortable: false,
  },
  {
    name: "State",
    key: "state",
    sortable: false,
  },
  {
    name: "Type",
    key: "type",
    sortingField: "type",
    sortable: true,
  },
  {
    name: "Status",
    key: "status",
    sortingField: "status",
    sortable: true,
  },
  {
    name: "Created",
    key: "created",
    sortingField: "created",
    sortable: true,
  },
  {
    name: "Updated",
    key: "updated",
    sortingField: "updated",
    sortable: true,
  },
  {
    name: "",
    key: "details",
    sortable: false,
  }
]

export default DealGridConfig

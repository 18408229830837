
  import { defineComponent } from "vue"

  import { userHasRole } from "@/core/auth/userHelper"
  import { RoleType } from "@/core/auth/permissionEnums"
  
  import { AdvisorDashboard, OriginatorDashboard, EmptyDashboard, BrokerDashboard } from "@/components/Dashboard"

  export default defineComponent({
    name: "Dashboard",

    computed: {
      dashboard () {
        if (userHasRole(RoleType.ADVISOR)) return 'AdvisorDashboard'
        if (userHasRole(RoleType.ORIGINATOR)) return 'OriginatorDashboard'
        if (userHasRole(RoleType.BROKER)) return 'BrokerDashboard'
        return 'EmptyDashboard'
      }
    },

    components: {
      AdvisorDashboard,
      OriginatorDashboard,
      EmptyDashboard,
      BrokerDashboard
    },

    mounted () {
      this.$router.push(this.$route.path)
    }
  })

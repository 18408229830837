import { DealActions, DealMutations } from "@/store/enums/DealEnums";
import { LenderActions, LenderMutations } from "@/store/enums/InstitutionEnums";
import { LenderMatchingActions, LenderMatchingMutations } from "@/store/enums/LendersMatchingEnums";
import { OriginatorActions, OriginatorMutations } from "@/store/enums/OriginatorEnums";
import { UserActions, UserMutations } from "@/store/enums/UserEnums";
import { FieldActions, FieldMutations } from "@/store/enums/FieldEnums";
import { NoteActions, NoteMutations } from "@/store/enums/NoteEnums";
import { ModalMutations } from "@/store/enums/ModalEnums";
import { OriginatorUserActions, OriginatorUserMutations } from "@/store/enums/OriginatorUserEnums";
import { ShortListsActions, ShortListsMutations } from "@/store/enums/ShortListsEnums";
import { OriginatorPublicActions, OriginatorPublicMutations } from "@/store/enums/OriginatorPublicEnums";

import Deal from "@/core/models/Deal";
import { PortalUserActions, PortalUserMutations } from "@/store/enums/PortalUserEnums";

enum baseActions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",

    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    GET_ADVISORS_INFO = "getAdvisorsInfo"
}

const Actions = {
    ...baseActions,
    ...DealActions,
    ...LenderActions,
    ...OriginatorActions,
    ...UserActions,
    ...FieldActions,
    ...NoteActions,
    ...PortalUserActions,
    ...LenderMatchingActions,
    ...OriginatorUserActions,
    ...ShortListsActions,
    ...OriginatorPublicActions
};

enum baseMutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",

    PURGE_AUTH = "logout",
    SET_PASSWORD = "setPassword",
    SET_ERROR = "setError",

    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",

    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
    SET_ADVISORS_INFO = "setAdvisorsInfo"
}

const Mutations = {
    ...baseMutations,
    ...DealMutations,
    ...LenderMutations,
    ...OriginatorMutations,
    ...UserMutations,
    ...FieldMutations,
    ...NoteActions,
    ...PortalUserMutations,
    ...LenderMatchingMutations,
    ...ModalMutations,
    ...OriginatorUserMutations,
    ...ShortListsMutations,
    ...OriginatorPublicMutations
};

export {Actions, Mutations};

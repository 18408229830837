
  import { defineComponent, ref, toRefs, onMounted, computed, watch } from "vue"
  import store from "@/store"

  import { Actions } from "@/store/enums/StoreEnums"

  import { useModals } from "@/composables"

  import { convertToShortCurrency } from "@/core/helpers/convertToCurrency.ts"

  import { XIcon } from "@heroicons/vue/solid"

  export default defineComponent({
    name: 'ModalInquireDealDetails',

    emits: ['closed'],

    components: {
      XIcon
    },

    props: ['deal'],

    setup(props, { emit }) {
      const { closeModal, showNotification } = useModals()

      const { deal } = toRefs(props)

      const profile = computed(() => {
        return store.getters.getCurrentUserProfile
      })

      const inquiries = computed(() => {
        return store.getters.inquiries
      })

      const name = computed(() => {
        let name = '' // {loan amount} {property type} {loan purpose} in {city, state}

        if (deal.value.loanAmount) name += `$${convertToShortCurrency(deal.value.loanAmount)}`

        if (deal.value.propertyType) {
          if (name.length) name += ' '
          name += deal.value.propertyType
        }

        if (deal.value.loanPurpose) {
          if (name.length) name += ' '
          name += deal.value.loanPurpose
        }

        if (deal.value.type == 'Business') {
          if (name.length) name += ' '
          name += 'Business'
        }
        // if (deal.value.data['Primary Loan Purpose']) {
        //   if (name.length) name += ' '
        //   name += deal.value.data['Primary Loan Purpose']
        // }

        return name
      })

      const comments = ref<string>("")

      const loading = ref<boolean>(false)

      const inquire = async () => {
        if (!loading.value) {
          loading.value = true

          const payload = {
            "userId": profile.value.id,
            "subject": `Inquire about providing a quote for ${name.value}`,
            "distributedTo": deal.value.type == 'Business' ? 'Joe Nolan' : deal.value?.distributedTo?.[0],
            "originatorId": profile.value.originatorId,
            "dealId": deal.value.id,
            "comments": comments.value
          }

          try {            
            const response = await store.dispatch(Actions.INQUIRE, {
              originatorId: profile.value.originatorId,
              payload
            })

            const saved = window.localStorage.getItem('inquired')

            if (saved) {
              const updatedInquiries = JSON.parse(saved)
              updatedInquiries.push(response.dealId)
              window.localStorage.setItem('inquired', JSON.stringify(updatedInquiries))
            } else {
              window.localStorage.setItem('inquired', JSON.stringify([response.dealId]))
            }

            await store.dispatch(Actions.UPDATE_INQUIRIES)

            closeModal('ModalInquireDealDetails')
          } catch (error) {
            console.log(error)
            showNotification(error?.response?.data?.errors || error.message || 'Something went wrong', 'error')
          }
        }
      }

      return {
        closeModal,
        inquire,
        name,
        comments,
        loading
      }
    }
  })

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "-ml-4 -mr-4" }
const _hoisted_2 = { class: "border-t pt-3 px-3 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_logout_button = _resolveComponent("logout-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, ({ key, label, path }) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: key,
        to: path,
        class: "px-3 block py-1 text-sm text-gray-700 hover:text-indigo-700",
        "active-class": "bg-gray-100"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(label), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_logout_button)
    ])
  ]))
}
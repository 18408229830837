
  import { defineComponent, ref } from "vue"

  import { onClickOutside } from '@vueuse/core'

  export default defineComponent({
    name: 'BaseMenuToggle',

    props: {
      menuStyles: {
        type: String,
        default () {
          return 'top left'
        }
      }
    },

    setup () {
      const toggle = ref(null)

      const isToggled = ref(false)

      onClickOutside(toggle, () => {
            if (isToggled.value) isToggled.value = false
          })

      return {
        toggle,
        isToggled
      }
    }
  })

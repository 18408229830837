
  import { defineComponent, ref, computed } from "vue"

  import { StatesMap, PropertyTypesList, ExecutionTypesList, FirmTypesMap } from "@/core/constants"
  import { convertToCurrency, convertToShortCurrency, converToNumber } from "@/core/helpers/convertToCurrency"

  export default defineComponent({
    name: "OriginatorSettings",

    setup () {
      const payload = window.localStorage.getItem('originator')
      const contacts = payload ? JSON.parse(payload) : {}

      const PropertyTypes = computed(() => {
        const obj = {}

        PropertyTypesList.forEach(({ Category, DisplayName }) => {
          if (Category) {
            if (!obj[Category]) obj[Category] = []
            obj[Category].push(DisplayName)
          } else {
            if (!obj['Other']) obj['Other'] = []
            obj['Other'].push(DisplayName)
          }
        })

        return obj
      })

      const ExecutionTypes = computed(() => {
        const obj = {}

        ExecutionTypesList.forEach(({ Category, DisplayName }) => {
          if (Category) {
            if (!obj[Category]) obj[Category] = []
            obj[Category].push(DisplayName)
          } else {
            if (!obj['Other']) obj['Other'] = []
            obj['Other'].push(DisplayName)
          }
        })

        return obj
      })

      const categories = ref<any>({
        propertyType: {
          title: 'Property types',
          subtitle: 'What type of property do you lend on?',
          options: PropertyTypes
        },
        executionType: {
          title: 'Loan types',
          subtitle: 'What are your minimum and maximum loan amounts?',
          options: ExecutionTypes
        },
        loanAmount: {
          title: 'Loan amounts',
          subtitle: 'What type of property do you lend on?'
        },
        contacts: {
          title: 'Personal information',
          subtitle: 'Use a permanent address where you can receive mail.'
        },
        nda: {
          title: 'Sign an NDA',
          subtitle: 'We need that to ensure you’re polite with our borrowers.'
        }
      })

      const collectedData = ref<any>({
        propertyType: new Set(),
        executionType: new Set(),
        loanAmount: {
          minimumAmount: '1,000',
          maximumAmount: '10,000'
        },
        contacts: {
          firstName: contacts?.firstName || '',
          lastName: contacts?.lastName || '',
          email: contacts?.email || '',
          phone: contacts?.phone || ''
        },
        nda: {
          signed: false
        }
      })

      const option = ref<any>({
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      })

      const signature = ref<any>(null)

      const clear = () => {
        signature.value[0].clear()
      }

      const undo = () => {
        signature.value[0].undo();
      }

      return {
        categories,
        collectedData,
        option,
        clear,
        undo,
        signature
      }
    }
  })
